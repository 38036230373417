import React from 'react';

export const LensSliderSVG = (): JSX.Element => {
    return (
        <svg
            width="1372"
            height="24"
            viewBox="0 0 1372 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2734_2987)">
                <path
                    d="M1.99709 0H0V24H1.99709V0Z"
                    fill="#686868"
                />
                <path
                    d="M8.98683 8H6.98975V20H8.98683V8Z"
                    fill="#424242"
                />
                <path
                    d="M15.9768 8H13.9797V20H15.9768V8Z"
                    fill="#424242"
                />
                <path
                    d="M22.9666 8H20.9695V20H22.9666V8Z"
                    fill="#424242"
                />
                <path
                    d="M29.9563 8H27.9592V20H29.9563V8Z"
                    fill="#424242"
                />
                <path
                    d="M36.9461 8H34.949V20H36.9461V8Z"
                    fill="#424242"
                />
                <path
                    d="M43.9361 8H41.939V20H43.9361V8Z"
                    fill="#424242"
                />
                <path
                    d="M50.9258 8H48.9287V20H50.9258V8Z"
                    fill="#424242"
                />
                <path
                    d="M57.9155 8H55.9185V20H57.9155V8Z"
                    fill="#424242"
                />
                <path
                    d="M64.9053 8H62.9082V20H64.9053V8Z"
                    fill="#424242"
                />
                <path
                    d="M71.8953 0H69.8982V24H71.8953V0Z"
                    fill="#686868"
                />
                <path
                    d="M78.885 8H76.8879V20H78.885V8Z"
                    fill="#424242"
                />
                <path
                    d="M85.8748 8H83.8777V20H85.8748V8Z"
                    fill="#424242"
                />
                <path
                    d="M92.8645 8H90.8674V20H92.8645V8Z"
                    fill="#424242"
                />
                <path
                    d="M99.8545 8H97.8574V20H99.8545V8Z"
                    fill="#424242"
                />
                <path
                    d="M106.844 8H104.847V20H106.844V8Z"
                    fill="#424242"
                />
                <path
                    d="M113.834 8H111.837V20H113.834V8Z"
                    fill="#424242"
                />
                <path
                    d="M120.824 8H118.827V20H120.824V8Z"
                    fill="#424242"
                />
                <path
                    d="M127.814 8H125.817V20H127.814V8Z"
                    fill="#424242"
                />
                <path
                    d="M134.803 8H132.806V20H134.803V8Z"
                    fill="#424242"
                />
                <path
                    d="M141.793 0H139.796V24H141.793V0Z"
                    fill="#686868"
                />
                <path
                    d="M148.783 8H146.786V20H148.783V8Z"
                    fill="#424242"
                />
                <path
                    d="M155.773 8H153.776V20H155.773V8Z"
                    fill="#424242"
                />
                <path
                    d="M162.763 8H160.766V20H162.763V8Z"
                    fill="#424242"
                />
                <path
                    d="M169.752 8H167.755V20H169.752V8Z"
                    fill="#424242"
                />
                <path
                    d="M176.742 8H174.745V20H176.742V8Z"
                    fill="#424242"
                />
                <path
                    d="M183.732 8H181.735V20H183.732V8Z"
                    fill="#424242"
                />
                <path
                    d="M190.722 8H188.725V20H190.722V8Z"
                    fill="#424242"
                />
                <path
                    d="M197.712 8H195.715V20H197.712V8Z"
                    fill="#424242"
                />
                <path
                    d="M204.702 8H202.705V20H204.702V8Z"
                    fill="#424242"
                />
                <path
                    d="M211.691 8H209.694V20H211.691V8Z"
                    fill="#424242"
                />
                <path
                    d="M218.681 0H216.684V24H218.681V0Z"
                    fill="#686868"
                />
                <path
                    d="M225.671 8H223.674V20H225.671V8Z"
                    fill="#424242"
                />
                <path
                    d="M232.661 8H230.664V20H232.661V8Z"
                    fill="#424242"
                />
                <path
                    d="M239.651 8H237.654V20H239.651V8Z"
                    fill="#424242"
                />
                <path
                    d="M246.64 8H244.643V20H246.64V8Z"
                    fill="#424242"
                />
                <path
                    d="M253.63 8H251.633V20H253.63V8Z"
                    fill="#424242"
                />
                <path
                    d="M260.62 8H258.623V20H260.62V8Z"
                    fill="#424242"
                />
                <path
                    d="M267.61 8H265.613V20H267.61V8Z"
                    fill="#424242"
                />
                <path
                    d="M274.6 8H272.603V20H274.6V8Z"
                    fill="#424242"
                />
                <path
                    d="M281.59 8H279.593V20H281.59V8Z"
                    fill="#424242"
                />
                <path
                    d="M288.579 8H286.582V20H288.579V8Z"
                    fill="#424242"
                />
                <path
                    d="M295.569 8H293.572V20H295.569V8Z"
                    fill="#424242"
                />
                <path
                    d="M302.559 8H300.562V20H302.559V8Z"
                    fill="#424242"
                />
                <path
                    d="M309.549 8H307.552V20H309.549V8Z"
                    fill="#424242"
                />
                <path
                    d="M316.539 8H314.542V20H316.539V8Z"
                    fill="#424242"
                />
                <path
                    d="M323.528 0H321.531V24H323.528V0Z"
                    fill="#686868"
                />
                <path
                    d="M330.518 8H328.521V20H330.518V8Z"
                    fill="#424242"
                />
                <path
                    d="M337.508 8H335.511V20H337.508V8Z"
                    fill="#424242"
                />
                <path
                    d="M344.498 8H342.501V20H344.498V8Z"
                    fill="#424242"
                />
                <path
                    d="M351.488 8H349.49V20H351.488V8Z"
                    fill="#424242"
                />
                <path
                    d="M358.478 8H356.48V20H358.478V8Z"
                    fill="#424242"
                />
                <path
                    d="M365.467 8H363.47V20H365.467V8Z"
                    fill="#424242"
                />
                <path
                    d="M372.457 8H370.46V20H372.457V8Z"
                    fill="#424242"
                />
                <path
                    d="M379.447 8H377.45V20H379.447V8Z"
                    fill="#424242"
                />
                <path
                    d="M386.437 8H384.44V20H386.437V8Z"
                    fill="#424242"
                />
                <path
                    d="M393.427 8H391.429V20H393.427V8Z"
                    fill="#424242"
                />
                <path
                    d="M400.416 8H398.419V20H400.416V8Z"
                    fill="#424242"
                />
                <path
                    d="M407.406 8H405.409V20H407.406V8Z"
                    fill="#424242"
                />
                <path
                    d="M414.396 8H412.399V20H414.396V8Z"
                    fill="#424242"
                />
                <path
                    d="M421.386 8H419.389V20H421.386V8Z"
                    fill="#424242"
                />
                <path
                    d="M428.376 8H426.378V20H428.376V8Z"
                    fill="#424242"
                />
                <path
                    d="M435.365 8H433.368V20H435.365V8Z"
                    fill="#424242"
                />
                <path
                    d="M442.355 8H440.358V20H442.355V8Z"
                    fill="#424242"
                />
                <path
                    d="M449.345 8H447.348V20H449.345V8Z"
                    fill="#424242"
                />
                <path
                    d="M456.335 8H454.338V20H456.335V8Z"
                    fill="#424242"
                />
                <path
                    d="M463.325 8H461.328V20H463.325V8Z"
                    fill="#424242"
                />
                <path
                    d="M470.314 8H468.317V20H470.314V8Z"
                    fill="#424242"
                />
                <path
                    d="M477.304 8H475.307V20H477.304V8Z"
                    fill="#424242"
                />
                <path
                    d="M484.294 8H482.297V20H484.294V8Z"
                    fill="#424242"
                />
                <path
                    d="M491.284 8H489.287V20H491.284V8Z"
                    fill="#424242"
                />
                <path
                    d="M498.274 8H496.277V20H498.274V8Z"
                    fill="#424242"
                />
                <path
                    d="M505.263 8H503.266V20H505.263V8Z"
                    fill="#424242"
                />
                <path
                    d="M512.253 8H510.256V20H512.253V8Z"
                    fill="#424242"
                />
                <path
                    d="M519.243 8H517.246V20H519.243V8Z"
                    fill="#424242"
                />
                <path
                    d="M526.233 8H524.236V20H526.233V8Z"
                    fill="#424242"
                />
                <path
                    d="M533.223 8H531.226V20H533.223V8Z"
                    fill="#424242"
                />
                <path
                    d="M540.212 8H538.215V20H540.212V8Z"
                    fill="#424242"
                />
                <path
                    d="M547.202 8H545.205V20H547.202V8Z"
                    fill="#424242"
                />
                <path
                    d="M554.192 8H552.195V20H554.192V8Z"
                    fill="#424242"
                />
                <path
                    d="M561.182 8H559.185V20H561.182V8Z"
                    fill="#424242"
                />
                <path
                    d="M568.172 0H566.175V24H568.172V0Z"
                    fill="#686868"
                />
                <path
                    d="M575.162 8H573.165V20H575.162V8Z"
                    fill="#424242"
                />
                <path
                    d="M582.151 8H580.154V20H582.151V8Z"
                    fill="#424242"
                />
                <path
                    d="M589.141 8H587.144V20H589.141V8Z"
                    fill="#424242"
                />
                <path
                    d="M596.131 8H594.134V20H596.131V8Z"
                    fill="#424242"
                />
                <path
                    d="M603.121 8H601.124V20H603.121V8Z"
                    fill="#424242"
                />
                <path
                    d="M610.111 8H608.114V20H610.111V8Z"
                    fill="#424242"
                />
                <path
                    d="M617.1 8H615.103V20H617.1V8Z"
                    fill="#424242"
                />
                <path
                    d="M624.09 8H622.093V20H624.09V8Z"
                    fill="#424242"
                />
                <path
                    d="M631.08 8H629.083V20H631.08V8Z"
                    fill="#424242"
                />
                <path
                    d="M638.07 8H636.073V20H638.07V8Z"
                    fill="#424242"
                />
                <path
                    d="M645.06 8H643.062V20H645.06V8Z"
                    fill="#424242"
                />
                <path
                    d="M652.05 8H650.052V20H652.05V8Z"
                    fill="#424242"
                />
                <path
                    d="M659.039 8H657.042V20H659.039V8Z"
                    fill="#424242"
                />
                <path
                    d="M666.029 8H664.032V20H666.029V8Z"
                    fill="#424242"
                />
                <path
                    d="M673.019 0H671.022V24H673.019V0Z"
                    fill="#686868"
                />
                <path
                    d="M680.009 8H678.012V20H680.009V8Z"
                    fill="#424242"
                />
                <path
                    d="M686.999 8H685.001V20H686.999V8Z"
                    fill="#424242"
                />
                <path
                    d="M693.988 8H691.991V20H693.988V8Z"
                    fill="#424242"
                />
                <path
                    d="M700.978 8H698.981V20H700.978V8Z"
                    fill="#424242"
                />
                <path
                    d="M707.968 8H705.971V20H707.968V8Z"
                    fill="#424242"
                />
                <path
                    d="M714.958 8H712.961V20H714.958V8Z"
                    fill="#424242"
                />
                <path
                    d="M721.948 8H719.95V20H721.948V8Z"
                    fill="#424242"
                />
                <path
                    d="M728.938 8H726.94V20H728.938V8Z"
                    fill="#424242"
                />
                <path
                    d="M735.927 8H733.93V20H735.927V8Z"
                    fill="#424242"
                />
                <path
                    d="M742.917 8H740.92V20H742.917V8Z"
                    fill="#424242"
                />
                <path
                    d="M749.907 8H747.91V20H749.907V8Z"
                    fill="#424242"
                />
                <path
                    d="M756.897 8H754.899V20H756.897V8Z"
                    fill="#424242"
                />
                <path
                    d="M763.886 8H761.889V20H763.886V8Z"
                    fill="#424242"
                />
                <path
                    d="M770.876 8H768.879V20H770.876V8Z"
                    fill="#424242"
                />
                <path
                    d="M777.866 8H775.869V20H777.866V8Z"
                    fill="#424242"
                />
                <path
                    d="M784.856 8H782.859V20H784.856V8Z"
                    fill="#424242"
                />
                <path
                    d="M791.846 8H789.849V20H791.846V8Z"
                    fill="#424242"
                />
                <path
                    d="M798.835 8H796.838V20H798.835V8Z"
                    fill="#424242"
                />
                <path
                    d="M805.825 8H803.828V20H805.825V8Z"
                    fill="#424242"
                />
                <path
                    d="M812.815 8H810.818V20H812.815V8Z"
                    fill="#424242"
                />
                <path
                    d="M819.805 8H817.808V20H819.805V8Z"
                    fill="#424242"
                />
                <path
                    d="M826.795 8H824.798V20H826.795V8Z"
                    fill="#424242"
                />
                <path
                    d="M833.785 8H831.788V20H833.785V8Z"
                    fill="#424242"
                />
                <path
                    d="M840.774 8H838.777V20H840.774V8Z"
                    fill="#424242"
                />
                <path
                    d="M847.764 8H845.767V20H847.764V8Z"
                    fill="#424242"
                />
                <path
                    d="M854.754 8H852.757V20H854.754V8Z"
                    fill="#424242"
                />
                <path
                    d="M861.744 8H859.747V20H861.744V8Z"
                    fill="#424242"
                />
                <path
                    d="M868.734 8H866.737V20H868.734V8Z"
                    fill="#424242"
                />
                <path
                    d="M875.723 8H873.726V20H875.723V8Z"
                    fill="#424242"
                />
                <path
                    d="M882.713 8H880.716V20H882.713V8Z"
                    fill="#424242"
                />
                <path
                    d="M889.703 8H887.706V20H889.703V8Z"
                    fill="#424242"
                />
                <path
                    d="M896.693 8H894.696V20H896.693V8Z"
                    fill="#424242"
                />
                <path
                    d="M903.683 8H901.686V20H903.683V8Z"
                    fill="#424242"
                />
                <path
                    d="M910.672 8H908.675V20H910.672V8Z"
                    fill="#424242"
                />
                <path
                    d="M917.662 8H915.665V20H917.662V8Z"
                    fill="#424242"
                />
                <path
                    d="M924.652 8H922.655V20H924.652V8Z"
                    fill="#424242"
                />
                <path
                    d="M931.642 8H929.645V20H931.642V8Z"
                    fill="#424242"
                />
                <path
                    d="M938.632 8H936.635V20H938.632V8Z"
                    fill="#424242"
                />
                <path
                    d="M945.622 8H943.625V20H945.622V8Z"
                    fill="#424242"
                />
                <path
                    d="M952.611 8H950.614V20H952.611V8Z"
                    fill="#424242"
                />
                <path
                    d="M959.601 8H957.604V20H959.601V8Z"
                    fill="#424242"
                />
                <path
                    d="M966.591 8H964.594V20H966.591V8Z"
                    fill="#424242"
                />
                <path
                    d="M973.581 8H971.584V20H973.581V8Z"
                    fill="#424242"
                />
                <path
                    d="M980.571 8H978.573V20H980.571V8Z"
                    fill="#424242"
                />
                <path
                    d="M987.56 8H985.563V20H987.56V8Z"
                    fill="#424242"
                />
                <path
                    d="M994.55 8H992.553V20H994.55V8Z"
                    fill="#424242"
                />
                <path
                    d="M1001.54 8H999.543V20H1001.54V8Z"
                    fill="#424242"
                />
                <path
                    d="M1008.53 8H1006.53V20H1008.53V8Z"
                    fill="#424242"
                />
                <path
                    d="M1015.52 8H1013.52V20H1015.52V8Z"
                    fill="#424242"
                />
                <path
                    d="M1022.51 8H1020.51V20H1022.51V8Z"
                    fill="#424242"
                />
                <path
                    d="M1029.5 8H1027.5V20H1029.5V8Z"
                    fill="#424242"
                />
                <path
                    d="M1036.49 8H1034.49V20H1036.49V8Z"
                    fill="#424242"
                />
                <path
                    d="M1043.48 8H1041.48V20H1043.48V8Z"
                    fill="#424242"
                />
                <path
                    d="M1050.47 8H1048.47V20H1050.47V8Z"
                    fill="#424242"
                />
                <path
                    d="M1057.46 8H1055.46V20H1057.46V8Z"
                    fill="#424242"
                />
                <path
                    d="M1064.45 8H1062.45V20H1064.45V8Z"
                    fill="#424242"
                />
                <path
                    d="M1071.44 8H1069.44V20H1071.44V8Z"
                    fill="#424242"
                />
                <path
                    d="M1078.43 8H1076.43V20H1078.43V8Z"
                    fill="#424242"
                />
                <path
                    d="M1085.42 8H1083.42V20H1085.42V8Z"
                    fill="#424242"
                />
                <path
                    d="M1092.41 8H1090.41V20H1092.41V8Z"
                    fill="#424242"
                />
                <path
                    d="M1099.4 8H1097.4V20H1099.4V8Z"
                    fill="#424242"
                />
                <path
                    d="M1106.39 8H1104.39V20H1106.39V8Z"
                    fill="#424242"
                />
                <path
                    d="M1113.38 8H1111.38V20H1113.38V8Z"
                    fill="#424242"
                />
                <path
                    d="M1120.37 8H1118.37V20H1120.37V8Z"
                    fill="#424242"
                />
                <path
                    d="M1127.36 8H1125.36V20H1127.36V8Z"
                    fill="#424242"
                />
                <path
                    d="M1134.35 8H1132.35V20H1134.35V8Z"
                    fill="#424242"
                />
                <path
                    d="M1141.34 8H1139.34V20H1141.34V8Z"
                    fill="#424242"
                />
                <path
                    d="M1148.33 8H1146.33V20H1148.33V8Z"
                    fill="#424242"
                />
                <path
                    d="M1155.32 8H1153.32V20H1155.32V8Z"
                    fill="#424242"
                />
                <path
                    d="M1162.31 8H1160.31V20H1162.31V8Z"
                    fill="#424242"
                />
                <path
                    d="M1169.3 8H1167.3V20H1169.3V8Z"
                    fill="#424242"
                />
                <path
                    d="M1176.29 8H1174.29V20H1176.29V8Z"
                    fill="#424242"
                />
                <path
                    d="M1183.28 8H1181.28V20H1183.28V8Z"
                    fill="#424242"
                />
                <path
                    d="M1190.26 8H1188.27V20H1190.26V8Z"
                    fill="#424242"
                />
                <path
                    d="M1197.25 8H1195.26V20H1197.25V8Z"
                    fill="#424242"
                />
                <path
                    d="M1204.24 8H1202.25V20H1204.24V8Z"
                    fill="#424242"
                />
                <path
                    d="M1211.23 8H1209.24V20H1211.23V8Z"
                    fill="#424242"
                />
                <path
                    d="M1218.22 8H1216.23V20H1218.22V8Z"
                    fill="#424242"
                />
                <path
                    d="M1225.21 8H1223.22V20H1225.21V8Z"
                    fill="#424242"
                />
                <path
                    d="M1232.2 8H1230.21V20H1232.2V8Z"
                    fill="#424242"
                />
                <path
                    d="M1239.19 8H1237.2V20H1239.19V8Z"
                    fill="#424242"
                />
                <path
                    d="M1246.18 8H1244.19V20H1246.18V8Z"
                    fill="#424242"
                />
                <path
                    d="M1253.17 8H1251.18V20H1253.17V8Z"
                    fill="#424242"
                />
                <path
                    d="M1260.16 8H1258.17V20H1260.16V8Z"
                    fill="#424242"
                />
                <path
                    d="M1267.15 8H1265.16V20H1267.15V8Z"
                    fill="#424242"
                />
                <path
                    d="M1274.14 8H1272.15V20H1274.14V8Z"
                    fill="#424242"
                />
                <path
                    d="M1281.13 8H1279.14V20H1281.13V8Z"
                    fill="#424242"
                />
                <path
                    d="M1288.12 8H1286.13V20H1288.12V8Z"
                    fill="#424242"
                />
                <path
                    d="M1295.11 8H1293.11V20H1295.11V8Z"
                    fill="#424242"
                />
                <path
                    d="M1302.1 8H1300.1V20H1302.1V8Z"
                    fill="#424242"
                />
                <path
                    d="M1309.09 8H1307.09V20H1309.09V8Z"
                    fill="#424242"
                />
                <path
                    d="M1316.08 8H1314.08V20H1316.08V8Z"
                    fill="#424242"
                />
                <path
                    d="M1323.07 8H1321.07V20H1323.07V8Z"
                    fill="#424242"
                />
                <path
                    d="M1330.06 8H1328.06V20H1330.06V8Z"
                    fill="#424242"
                />
                <path
                    d="M1337.05 8H1335.05V20H1337.05V8Z"
                    fill="#424242"
                />
                <path
                    d="M1344.04 8H1342.04V20H1344.04V8Z"
                    fill="#424242"
                />
                <path
                    d="M1351.03 8H1349.03V20H1351.03V8Z"
                    fill="#424242"
                />
                <path
                    d="M1358.02 8H1356.02V20H1358.02V8Z"
                    fill="#424242"
                />
                <path
                    d="M1365.01 8H1363.01V20H1365.01V8Z"
                    fill="#424242"
                />
                <path
                    d="M1372 0H1370V24H1372V0Z"
                    fill="#686868"
                />
            </g>
            <defs>
                <clipPath id="clip0_2734_2987">
                    <rect
                        width="1372"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
