import React from 'react';
import { Tooltip, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { UpscaleStatus } from '@root/utils/constants/enums';
import { CommonConstants } from '@root/utils/constants';
import { ScaleButtonProps } from './ScaleButtonProps.types';

export const ScaleButton = ({ className, upscaleOption, onClick, disabled }: ScaleButtonProps): React.JSX.Element => {
    const originalScale = upscaleOption.value === 'original';
    const isLoaded = upscaleOption.status === UpscaleStatus.Ready;
    const isLoading = upscaleOption.status === UpscaleStatus.Processing || upscaleOption.status === UpscaleStatus.Enqueued;

    return (
        <Tooltip title={!originalScale && disabled ? CommonConstants.UPSCALE_WARNING_MESSAGE : ''}>
            <span>
                <StyledButton
                    className={className}
                    variant={'text'}
                    disabled={!originalScale && disabled}
                    active={upscaleOption.state}
                    loaded={isLoaded ? 1 : 0}
                    onClick={onClick}
                    loading={isLoading}
                    loadingPosition={'end'}
                    endIcon={<></>}
                >
                    {originalScale ? 'Original' : `${upscaleOption.value}x`}
                </StyledButton>
            </span>
        </Tooltip>
    );
};

const StyledButton = styled(LoadingButton)<{ active: boolean; loaded: number; disabled: boolean }>(
    ({ active, loaded, theme, disabled }) => ({
        '--color': active ? theme.palette.primary.contrastText : loaded ? theme.palette.primary.main : theme.palette.primary.inactive,
        height: '20px',
        minWidth: '35px',
        marginRight: '6px',
        padding: '0',
        cursor: disabled ? 'default' : 'pointer',
        pointerEvents: disabled ? 'none' : 'auto',

        textTransform: 'capitalize',
        border: 'none',

        color: 'var(--color)',

        '&:hover': {
            backgroundColor: theme.palette.primary.light1,
        },

        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '16px',
        '@media (max-width:1500px)': {
            fontSize: '12px',
            lineHeight: '14px',
        },

        '& .MuiLoadingButton-loadingIndicator': {
            right: '-5px',
            bottom: '5px',
            height: '14px',
            width: '14px',
        },
    })
);
