import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SceneModeIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={'0 0 32 33'}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7626 8.04172C15.8414 8.03955 15.9206 8.03846 16 8.03846C20.6732 8.03846 24.4615 11.8268 24.4615 16.5C24.4615 16.9248 24.8059 17.2692 25.2308 17.2692C25.6556 17.2692 26 16.9248 26 16.5C26 10.9772 21.5228 6.5 16 6.5C15.2948 6.5 14.6059 6.57311 13.9407 6.71243L12.549 7.00387L16.9945 11.4494C17.2949 11.7498 17.782 11.7498 18.0824 11.4494C18.3828 11.149 18.3828 10.6619 18.0824 10.3615L15.7626 8.04172ZM11.1282 13.4231C11.1282 12.9982 11.4726 12.6538 11.8974 12.6538H14.9744C15.3992 12.6538 15.7436 12.9982 15.7436 13.4231V18.5513C15.7436 18.9761 15.3992 19.3205 14.9744 19.3205H11.8974C11.4726 19.3205 11.1282 18.9761 11.1282 18.5513C11.1282 18.1264 11.4726 17.7821 11.8974 17.7821H14.2051V16.7564H12.9231C12.4982 16.7564 12.1538 16.412 12.1538 15.9872C12.1538 15.5623 12.4982 15.2179 12.9231 15.2179H14.2051V14.1923H11.8974C11.4726 14.1923 11.1282 13.8479 11.1282 13.4231ZM16.2564 13.4231C16.2564 12.9982 16.6008 12.6538 17.0256 12.6538H19.0769C20.6346 12.6538 21.8974 13.9166 21.8974 15.4744V16.5C21.8974 18.0577 20.6346 19.3205 19.0769 19.3205H17.0256C16.6008 19.3205 16.2564 18.9761 16.2564 18.5513V13.4231ZM17.7949 14.1923V17.7821H19.0769C19.785 17.7821 20.359 17.2081 20.359 16.5V15.4744C20.359 14.7663 19.785 14.1923 19.0769 14.1923H17.7949ZM6.76923 15.7308C7.19407 15.7308 7.53846 16.0752 7.53846 16.5C7.53846 21.1732 11.3268 24.9615 16 24.9615C16.0413 24.9615 16.0824 24.9612 16.1235 24.9607L13.8478 22.685C13.5474 22.3846 13.5474 21.8975 13.8478 21.5971C14.1482 21.2967 14.6353 21.2967 14.9357 21.5971L19.3674 26.0288L17.9547 26.3089C17.3219 26.4343 16.6682 26.5 16 26.5C10.4772 26.5 6 22.0228 6 16.5C6 16.0752 6.3444 15.7308 6.76923 15.7308Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
