import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, styled, Stack, Typography } from '@mui/material';

import GeneratePageForm from './components/GeneratePageForm/GeneratePageForm';
import TuneBlock from './components/tuneBlock/TuneBlock';
import FavoriteSection from './components/FavoriteSection';

import TunesContext from '../../../context/TunesContext/TunesContext';
import GenerateContext from '../../../context/GenerateContext/GenerateContext';
import ProjectsContext from '../../../context/ProjectsContext/ProjectsContext';
import LocalStorageContext from '@context/LocalStorageContext/LocalStorageContext';
import { sortByID } from '@utils/sortByID';
import { ProjectActions } from '@pages/Generations/GeneratePage/components/ProjectActions/ProjectActions';
import { useJobs } from '@root/hooks/jobs/UseJobs';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const GeneratePage = () => {
    const { handleSetTunes, createNewTune, tunes, tuneErrorMessage } = useContext(TunesContext);
    const { updateJobStatus } = useJobs();
    const { checkLicenseStatus } = useLicenseValidation();

    const { projects, setActiveProjectById, activeProject, likedImages, updateProjectImagesNumber } = useContext(ProjectsContext);

    const { setStorageProjectName, setStorageProjectId } = useContext(LocalStorageContext);

    const tuneListRef = useRef();
    const [requestId, setRequestId] = useState('');

    const { getNewTuneData, setPrompt, selectedModelType } = useContext(GenerateContext);
    const { id } = useParams();
    const location = useLocation();

    const [tunesList, setTunesList] = useState('');

    const generateImages = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        const newTuneData = await getNewTuneData(requestId);
        await createNewTune(newTuneData, selectedModelType);
        updateJobStatus();
        await handleSetTunes(requestId);

        tuneListRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (!projects.length) {
            return;
        }

        const sortedList = sortByID(projects.concat());
        const activeProjectId = id ?? sortedList[0].Id;
        setActiveProjectById(activeProjectId);
        setRequestId(activeProjectId);
    }, [projects, location]);

    useEffect(() => {
        if (!activeProject) {
            return;
        }
        setStorageProjectId(activeProject.Id);
        setStorageProjectName(activeProject.Name);
    }, [activeProject]);

    useEffect(() => {
        setTunesList([]);

        if (requestId) {
            handleSetTunes(requestId);
            updateProjectImagesNumber(requestId);
        }
    }, [requestId]);

    useEffect(() => {
        setTunesList(tunes);
    }, [tunes]);

    return (
        <Container>
            <Stack
                direction="row"
                width="100%"
                height="100%"
                justifyContent="space-between"
            >
                <GenerateMenu>
                    <GeneratePageForm onSubmit={generateImages} />
                </GenerateMenu>
                <GenerateContent>
                    <Header single={!likedImages?.length}>
                        {tuneErrorMessage && <InfoLabel>{tuneErrorMessage}</InfoLabel>}
                        <ProjectActions />
                    </Header>
                    <GenerateResults $withFavorite={likedImages?.length}>
                        <FavoriteSectionWrapper>
                            <FavoriteSection />
                        </FavoriteSectionWrapper>

                        <TunesList ref={tuneListRef}>
                            {tunesList &&
                                tunesList.map((el) => (
                                    <TuneBlock
                                        setPrompt={setPrompt}
                                        key={el.Id}
                                        id={el.Id}
                                        tuneStatus={el.StatusId}
                                        configurations={el.Configurations}
                                    />
                                ))}
                        </TunesList>
                    </GenerateResults>
                </GenerateContent>
            </Stack>
        </Container>
    );
};

export default GeneratePage;

const Container = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',

    gap: '80px',
    paddingRight: '20px',
}));

const GenerateMenu = styled(Box)(() => ({
    flexGrow: 0,
    height: '100%',
}));

const GenerateContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    maxHeight: '100%',

    paddingTop: '30px',
    paddingRight: '40px',
    gap: '30px',
}));

const GenerateResults = styled(Box)(({ $withFavorite }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: $withFavorite ? 'calc(100vh - 110px)' : 'calc(100vh - 170px)',
}));

const FavoriteSectionWrapper = styled(Box)(() => ({
    width: '100%',
}));

const TunesList = styled(Box)(() => ({
    width: '100%',
    padding: '0 10px',
    overflow: 'auto',
    showScrollbarGutter: 'stable',
}));

const Header = styled(Box)(({ single }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',

    position: single ? 'relative' : 'absolute',
    paddingRight: single ? '30px' : '90px',
    paddingTop: '10px',
    right: '0',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '28px',
    marginRight: '20px',
}));
