export const CHECK_SESSION_STATUS_INTERVAL = 5000;
export const STATUS_CHECK_DELAY = 500;
export const DEFAULT_NUMBER_OF_IMAGE = 1;
export const DEFAULT_BRUSH_SIZE = 1;
export const DEFAULT_BRUSH_COLOR = '#3f3f3f';
export const DEFAULT_BRUSH_POINTER_COLOR = '#686868';
export const DEFAULT_ERASER_COLOR = '#ffffff';
export const DEFAULT_REFERENCE_IMAGE_INFLUENCE = 100;
export const DEFAULT_MIX_IMAGE_INFLUENCE = 50;
export const DEFAULT_DENOISE_WEIGHT = 100;
export const DEFAULT_NEGATIVE_PROMPT = 'lw';
export const DEFAULT_CONTROL_INFLUENCE_SD = 100;
export const DEFAULT_INFLUENCE_SD = 75;
export const DEFAULT_SCENE_SHIFT_PROMPT = 'photo of a car in studio environment, blue glow behind';
