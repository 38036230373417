import React, { ReactNode, RefObject, createContext, useContext, useState } from 'react';
import { QuickActionContextItems } from '@root/types/contextTypes';

interface QuickActionProviderProps {
    children: ReactNode;
}

const QuickActionContext = createContext<QuickActionContextItems>({} as QuickActionContextItems);

export const QuickActionContextProvider = ({ children }: QuickActionProviderProps): React.JSX.Element => {
    const [activeWindow, setActiveWindow] = useState<RefObject<HTMLDivElement> | null>(null);

    const externalCloseAction = (): void => {
        setActiveWindow(null);
    };

    const value = {
        activeWindow,
        setActiveWindow,
        externalCloseAction,
    };

    return <QuickActionContext.Provider value={value}>{children}</QuickActionContext.Provider>;
};

export const useQuickAction = () => {
    const context = useContext(QuickActionContext);
    return context;
};
