import React from 'react';
import { Box, IconButton, Popover, Tooltip, Typography, styled } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { SignOutIcon } from '@root/assets/icons/SignOutIcon';
import { AppInfoModalProps } from './AppInfoModal.types';

const ABOUT_LINK = 'https://www.depix.ai/';

const AppInfoModal = ({ anchorEl, isActive, username, version, handleClose, handleSingOut }: AppInfoModalProps): React.JSX.Element => {
    return (
        <InfoModal
            open={isActive}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <ModalContainer>
                <VersionRow>
                    <Typography>{'Depix Design Lab. Version:'}&nbsp;</Typography>
                    <Typography>{version}</Typography>
                </VersionRow>
                <InfoRow>
                    <Link
                        to={ABOUT_LINK}
                        target="_blank"
                    >
                        <Typography>{'About'}</Typography>
                    </Link>
                </InfoRow>
                <Divider />
                <UserInfoSection>
                    <Tooltip
                        placement="bottom-start"
                        title={username}
                    >
                        <InfoRow>
                            <AccountCircleOutlined />
                            <Typography>{username}</Typography>
                        </InfoRow>
                    </Tooltip>
                    <InfoRow>
                        <IconButton
                            disableRipple
                            onClick={handleSingOut}
                        >
                            <SignOutIcon />
                        </IconButton>
                    </InfoRow>
                </UserInfoSection>
            </ModalContainer>
        </InfoModal>
    );
};

export default AppInfoModal;

const InfoModal = styled(Popover)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiPaper-root': {
        marginTop: '10px',
        borderRadius: '0px',
    },
}));

const ModalContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '186px',
    padding: '2px',
    gap: '2px',

    background: theme.palette.neutrals.light1,

    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '0px',
}));

const VersionRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 10px',

    background: theme.palette.primary.light,
    borderTopLeftRadius: '4px',
    height: '23px',

    p: {
        fontFamily: 'Roboto400',
        fontSize: '10px',
        lineHeight: '14px',
        color: theme.palette.neutrals.inactive1,
    },
}));

const InfoRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    minHeight: '36px',
    height: 'fit-content',
    padding: '8px 10px',
    gap: '10px',

    '&:hover': {
        background: theme.palette.neutrals.inactive,
    },

    p: {
        fontFamily: 'Roboto400',
        fontSize: '12px',
        lineHeight: '16.8px',
        color: theme.palette.primary.contrastText,
        maxWidth: '90px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'clip',
    },

    a: {
        width: '100%',
        textDecoration: 'none',
    },

    '& svg': {
        width: '18px',
        height: '18px',
        color: theme.palette.primary.contrastText,
    },
}));

const Divider = styled(Box)(({ theme }) => ({
    background: theme.palette.neutrals.inactive,
    width: '100%',
    height: '1px',
    margin: '1px 0px',
}));

const UserInfoSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& div:not(:last-child)': {
        flexBasis: '70%',
        flex: '1',
    },

    '& div :last-child': {
        padding: '0px',
    },
}));
