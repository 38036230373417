import React, { useState } from 'react';
import { ModelsListHeaderProps } from './ModelsListHeader.types';
import { Box, IconButton, Tooltip, styled } from '@mui/material';
import { AddNewModelIcon } from '@root/assets/icons/AddNewModelIcon';
import { MergeModelsIcon } from '@root/assets/icons/MergeModelsIcon';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths } from '@root/utils/constants/enums';
import { SearchIcon } from '@root/assets/icons/SearchIcon';
import SearchField from '@root/components/SearchField';
import ImportModel from '../ImportModel';

const ModelsListHeader = ({ searchValue, setSearchValue, isMerge, setIsMerge }: ModelsListHeaderProps): React.JSX.Element => {
    const navigate = useNavigate();
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

    const handleAddNewModel = (): void => {
        navigate(`/${NavigationPaths.Models}`);
    };

    const handleShowMergeMenu = (): void => {
        setIsMerge(!isMerge);
    };

    const handleShowSearchInput = (): void => {
        setShowSearchInput(!showSearchInput);
    };

    return (
        <Wrapper>
            <HeaderSection>
                <Tooltip title="Add new model">
                    <span>
                        <ActionButton disableRipple>
                            <AddNewModelIcon onClick={handleAddNewModel} />
                        </ActionButton>
                    </span>
                </Tooltip>

                <ImportModel />
                <Tooltip title="Merge models">
                    <span>
                        <ActionButton
                            active={isMerge}
                            disableRipple
                        >
                            <MergeModelsIcon onClick={handleShowMergeMenu} />
                        </ActionButton>
                    </span>
                </Tooltip>
            </HeaderSection>

            <HeaderSection>
                {!showSearchInput ? (
                    <Tooltip title="Search">
                        <span>
                            <ActionButton
                                active={isMerge}
                                disableRipple
                            >
                                <SearchIcon onClick={handleShowSearchInput} />
                            </ActionButton>
                        </span>
                    </Tooltip>
                ) : (
                    <SearchField
                        widthCssValue={'400px'}
                        heightCssValue={'32px'}
                        onClose={handleShowSearchInput}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                )}
            </HeaderSection>
        </Wrapper>
    );
};

export default ModelsListHeader;

const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '70px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0px 30px 0px 25px',
    border: `1px solid ${theme.palette.primary.light}`,
}));

const HeaderSection = styled(Box)(() => ({
    display: 'flex',
    gap: '24px',
}));

const ActionButton = styled(IconButton)<{ active?: boolean }>(({ theme, active }) => ({
    width: '32px',
    height: '32px',
    color: active ? theme.palette.primary.main : theme.palette.primary.contrastText,

    '& svg': {
        width: '32px',
        height: '32px',
    },
}));
