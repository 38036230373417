import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ReloadIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9784 8C11.2959 8 7.5 11.7959 7.5 16.4784C7.5 21.1609 11.2959 24.9569 15.9784 24.9569C19.5042 24.9569 22.5291 22.8045 23.8074 19.7392C23.9668 19.3569 24.406 19.1762 24.7883 19.3356C25.1706 19.495 25.3512 19.9342 25.1918 20.3165C23.6887 23.921 20.1306 26.4569 15.9784 26.4569C10.4675 26.4569 6 21.9894 6 16.4784C6 10.9675 10.4675 6.5 15.9784 6.5C20.1306 6.5 23.6887 9.03585 25.1918 12.6404C25.3512 13.0227 25.1706 13.4618 24.7883 13.6213C24.406 13.7807 23.9668 13.6 23.8074 13.2177C22.5291 10.1523 19.5042 8 15.9784 8Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.58 7.94501C25.9943 7.94501 26.33 8.28079 26.33 8.69501V14.4142H20.6109C20.1967 14.4142 19.8609 14.0784 19.8609 13.6642C19.8609 13.25 20.1967 12.9142 20.6109 12.9142H24.83V8.69501C24.83 8.28079 25.1658 7.94501 25.58 7.94501Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
