import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DoneIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13 13"
            {...props}
        >
            <path
                d="M9 4L6 8L4 6"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
