import React, { useEffect, useState } from 'react';
import { Box, styled, Divider } from '@mui/material';
import InputField from '../InputField';
import { ExpandMenuIcon } from '@root/assets/icons/ExpandMenuIcon';
import { PromptSectionProps } from './PromptSection.types';
import { ErrorMessages } from '@root/utils/constants';
import { ErrorTooltip } from '../ErrorTooltip';

const PromptSection = ({
    prompt,
    onPromptInputChange,
    negativePrompt,
    onNegativePromptInputChange,
    seed,
    onSeedInputChange,
    onInputSeed,
    promptTooltipText,
    isPromptRequired = true,
    isNegativePromptRequired = true,
    isNegativePromptDisabled = false,
}: PromptSectionProps) => {
    const [active, setActive] = useState(false);

    const [errorType, setErrorType] = useState<'seed' | 'negativePrompt' | 'prompt' | null>(null);

    useEffect(() => {
        validationHandler();
    }, []);

    useEffect(() => {
        if (errorType === 'prompt') {
            clearErrorInfo();
        }

        if (!errorType) {
            validationHandler();
        }
    }, [prompt]);

    const negativePromptInputChangeHandler = (value: React.ChangeEvent<HTMLInputElement>): void => {
        if (errorType === 'negativePrompt') {
            clearErrorInfo();
        }

        if (onNegativePromptInputChange) {
            onNegativePromptInputChange(value);
        }
    };

    const seedInputChangeHandler = (value: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>): void => {
        if (errorType === 'seed') {
            clearErrorInfo();
        }

        if (value.type === 'change' && onSeedInputChange) {
            onSeedInputChange(value as React.ChangeEvent<HTMLInputElement>);
        } else if (value.type === 'keydown' && onInputSeed) {
            onInputSeed(value as React.KeyboardEvent<HTMLInputElement>);
        }
    };

    const clearErrorInfo = (): void => {
        setErrorType(null);
    };

    const getErrorMessage = (errorType: 'seed' | 'negativePrompt' | 'prompt' | null): string => {
        switch (errorType) {
            case 'seed':
                return ErrorMessages.GENERATION_FORM_ERROR_MESSAGE.SEED_IS_EMPTY;
            case 'negativePrompt':
                return ErrorMessages.GENERATION_FORM_ERROR_MESSAGE.NEGATIVE_PROMPT_IS_EMPTY;
            case 'prompt':
                return ErrorMessages.GENERATION_FORM_ERROR_MESSAGE.PROMPT_IS_EMPTY;
            default:
                return '';
        }
    };

    const validatePromptSection = (): boolean => {
        let isValid = true;

        if (isPromptRequired && !prompt) {
            setErrorType('prompt');
            isValid = false;
        }

        if (sectionPropertyIsDefined(negativePrompt) && isNegativePromptRequired && !negativePrompt) {
            setErrorType('negativePrompt');
            isValid = false;
        }

        if (sectionPropertyIsDefined(seed) && !seed) {
            setErrorType('seed');
            isValid = false;
        }

        return isValid;
    };

    const validationHandler = (): void => {
        const isValid = validatePromptSection();

        if (!isValid) {
            setActive(true);
        } else {
            setErrorType(null);
        }
    };

    const handleSectionActive = (): void => {
        if (active && errorType) {
            validationHandler();
            return;
        }

        setActive(!active);
    };

    function sectionPropertyIsDefined(value: any): value is string | number {
        return typeof value === 'string' || typeof value === 'number';
    }

    return (
        <PromptSectionWrapper>
            <PromptSectionContent>
                <ErrorTooltip
                    className="form-menu-input"
                    title={getErrorMessage(errorType)}
                    placement="bottom"
                    open={errorType === 'prompt'}
                    arrow
                >
                    <span>
                        <InputField
                            id="prompt"
                            name="prompt"
                            isRequired={isPromptRequired}
                            isMultiline={true}
                            isDisabled={false}
                            label="prompt"
                            value={prompt}
                            onBlur={validationHandler}
                            onChange={onPromptInputChange}
                            tooltipLabel={
                                promptTooltipText ||
                                'In this text input field, enter the words that will influence the formation of the car image'
                            }
                        />
                    </span>
                </ErrorTooltip>
                {active && (
                    <>
                        {sectionPropertyIsDefined(negativePrompt) && (
                            <ErrorTooltip
                                className="form-menu-input"
                                title={getErrorMessage(errorType)}
                                placement="bottom"
                                open={errorType === 'negativePrompt'}
                                arrow
                            >
                                <span>
                                    <InputField
                                        id="negativePrompt"
                                        name="negativePrompt"
                                        isRequired={isNegativePromptRequired}
                                        isMultiline={true}
                                        isDisabled={isNegativePromptDisabled}
                                        label="negative prompt"
                                        value={negativePrompt}
                                        onBlur={validationHandler}
                                        onChange={negativePromptInputChangeHandler}
                                        tooltipLabel="The description of what you do not want to see in the image."
                                    />
                                </span>
                            </ErrorTooltip>
                        )}
                        {sectionPropertyIsDefined(seed) && (
                            <ErrorTooltip
                                className="form-menu-input"
                                title={getErrorMessage(errorType)}
                                placement="bottom"
                                open={errorType === 'seed'}
                                arrow
                            >
                                <span>
                                    <InputField
                                        id="seed"
                                        name="seed"
                                        isRequired={true}
                                        isMultiline={true}
                                        isDisabled={false}
                                        label="seed"
                                        value={seed}
                                        onBlur={validationHandler}
                                        onChange={seedInputChangeHandler}
                                        onKeyDown={seedInputChangeHandler}
                                        tooltipLabel='Indicate the seed number of the generated image. Specify "auto" if you want each subsequent result to differ from the previous one.'
                                    />
                                </span>
                            </ErrorTooltip>
                        )}
                    </>
                )}
            </PromptSectionContent>

            {(sectionPropertyIsDefined(negativePrompt) || sectionPropertyIsDefined(seed)) && (
                <ExpandIconWrapper>
                    <ContentDivider />
                    <ExpandIcon
                        active={+active}
                        onClick={handleSectionActive}
                    ></ExpandIcon>
                    <ContentDivider />
                </ExpandIconWrapper>
            )}
        </PromptSectionWrapper>
    );
};

export default PromptSection;

const PromptSectionWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '300px',
}));

const PromptSectionContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
}));

const ExpandIconWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const ContentDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.light1,
    width: '130px',
    height: '1px',
    margin: 'auto',
}));

const ExpandIcon = styled(ExpandMenuIcon)<{ active: number }>(({ active, theme }) => ({
    transform: active ? 'rotate(180deg)' : 'unset',
    transition: 'all 0.5s',
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',

        '& path': {
            stroke: theme.palette.secondary.main,
        },
    },
}));
