export enum Model3dErrorCode {
    FileSize = 1,
    Upload,
    Render,
}

type Model3dErrorInfo = {
    [key in Model3dErrorCode]: Model3dErrorDetails;
};

type Model3dErrorDetails = {
    errorMessage: string;
    proposedAction: string;
};

export const Model3dError: Model3dErrorInfo = {
    [Model3dErrorCode.FileSize]: {
        errorMessage: 'The file is too big.',
        proposedAction: 'Please select file smaller than 8 MB.',
    },
    [Model3dErrorCode.Render]: {
        errorMessage: 'An error occurred while model rendering.',
        proposedAction: 'Please switch to another model.',
    },
    [Model3dErrorCode.Upload]: {
        errorMessage: 'An error occurred while loading the model.',
        proposedAction: 'Please select another file.',
    },
};
