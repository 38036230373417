import { CommonConstants } from '@root/utils/constants';
import * as THREE from 'three';

const DEFAULT_RADIUS = 500;
const DEFAULT_MESH_NAME = 'active_image';
const DEFAULT_CANVAS_MESH_NAME = 'scene_canvas';
const DEFAULT_HELPER_MESH_NAME = 'active_image';
const SMOOTHING_FACTOR = 0.1;
const NORMALIZATION_FACTOR = 0.0003; // Based on canvas size. Equals 0.03% of the selected size.

class PanoramicView {
    canvasElement: HTMLCanvasElement;

    oMouseDownClientX: number | null;
    oMouseDownClientY: number | null;
    onMouseDownLon: number;
    onMouseDownLat: number;

    longitude: number;
    latitude: number;

    eraserCanvas: any;
    eraserCanvasContext: any;
    innerTexture?: THREE.CanvasTexture;
    innerSphere?: THREE.Mesh;
    helperPointer?: THREE.Mesh;

    points: { x: number; y: number }[] = [];
    lastPoint: { x: number; y: number } | null = null;

    eraserSize: number;
    isEraserDrawing: boolean;
    isMultiselect: boolean;
    isDrawing: boolean;

    viewportDimensions: { width: number; height: number };
    textureDimensions: { width: number; height: number };
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;

    constructor(canvasElement: HTMLCanvasElement, viewportWidth: number, viewportHeight: number) {
        this.canvasElement = canvasElement;

        this.oMouseDownClientX = 0;
        this.oMouseDownClientY = 0;

        this.onMouseDownLon = 0;
        this.onMouseDownLat = 0;

        this.isEraserDrawing = false;

        this.longitude = 0;
        this.latitude = 0;

        this.viewportDimensions = {
            width: viewportWidth,
            height: viewportHeight,
        };

        this.eraserSize = CommonConstants.DEFAULT_ERASER_SIZE;

        this.textureDimensions = {
            width: 0,
            height: 0,
        };

        this.isMultiselect = false;
        this.isDrawing = false;

        this.renderer = this.initRenderer(this.canvasElement, this.viewportDimensions);

        this.scene = new THREE.Scene();
        this.camera = this.initCamera();
    }

    initCamera(): THREE.PerspectiveCamera {
        const { width, height } = this.viewportDimensions;
        const fieldOfView = 75;
        const cameraNear = 1;

        const cameraFar = 1000;

        const camera = new THREE.PerspectiveCamera(fieldOfView, width / height, cameraNear, cameraFar);

        return camera;
    }

    initRenderer(canvasElement: HTMLCanvasElement, viewportDimensions: { width: number; height: number }): THREE.WebGLRenderer {
        const { width, height } = viewportDimensions;

        const renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: canvasElement,
        });

        renderer.setSize(width, height);

        return renderer;
    }

    clearScene(): void {
        for (let i = this.scene.children.length - 1; i >= 0; i--) {
            const obj = this.scene.children[i];
            this.scene.remove(obj);
        }

        this.update();
    }

    handleLoadError(error: any, setIsLoading: (value: boolean) => void): void {
        console.error(error);
        setIsLoading(false);
    }

    updatePinterRadius(value: number): void {
        if (this.helperPointer) {
            this.helperPointer.scale.set(value, value, value);
            this.update();
        }
    }

    updatePinterVisibility(value: boolean): void {
        if (this.helperPointer) {
            this.helperPointer.visible = value;
            this.update();
        }
    }

    removeCanvasWithHelper(): void {
        this.points = [];

        const previousLoadedCanvas = this.scene.getObjectByName(DEFAULT_CANVAS_MESH_NAME);

        if (previousLoadedCanvas) {
            this.scene.remove(previousLoadedCanvas);
        }

        const previousLoadedHelper = this.scene.getObjectByName(DEFAULT_HELPER_MESH_NAME);
        if (previousLoadedHelper) {
            this.scene.remove(previousLoadedHelper);
        }

        this.update();
    }

    addCanvas(): void {
        this.removeCanvasWithHelper();

        const innerGeometry = new THREE.SphereGeometry(DEFAULT_RADIUS - 1);
        this.eraserCanvas = document.createElement('canvas');
        this.eraserCanvas.width = this.textureDimensions.width;
        this.eraserCanvas.height = this.textureDimensions.height;

        this.eraserCanvasContext = this.eraserCanvas.getContext('2d');
        if (this.eraserCanvasContext) {
            this.eraserCanvasContext.fillStyle = 'black';
            this.eraserCanvasContext.fillRect(0, 0, this.eraserCanvas.width, this.eraserCanvas.height);

            this.innerTexture = new THREE.CanvasTexture(this.eraserCanvas);

            const innerMaterial = new THREE.MeshBasicMaterial({ map: this.innerTexture, opacity: 0.3, transparent: true });
            innerGeometry.scale(-1, 1, 1);
            this.innerSphere = new THREE.Mesh(innerGeometry, innerMaterial);
            this.innerSphere.name = DEFAULT_CANVAS_MESH_NAME;
            this.scene.add(this.innerSphere);

            const geometry = new THREE.SphereGeometry();
            const material = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.3, transparent: true });

            this.helperPointer = new THREE.Mesh(geometry, material);
            this.helperPointer.visible = false;
            this.helperPointer.name = DEFAULT_HELPER_MESH_NAME;
            this.helperPointer.scale.set(this.eraserSize, this.eraserSize, this.eraserSize);
            this.scene.add(this.helperPointer);
        }
    }

    loadTexture(imagePath: string, setIsLoading: (value: boolean) => void): void {
        const fileLoader = new THREE.TextureLoader();

        const previousLoadedMesh = this.scene.getObjectByName(DEFAULT_MESH_NAME);
        if (previousLoadedMesh) {
            this.scene.remove(previousLoadedMesh);
            this.update();
        }

        fileLoader.load(
            imagePath,
            (texture: THREE.Texture) => {
                try {
                    this.textureDimensions.width = texture.image.width;
                    this.textureDimensions.height = texture.image.height;

                    texture.colorSpace = THREE.SRGBColorSpace;
                    const material = new THREE.MeshBasicMaterial({ map: texture });
                    const geometry = new THREE.SphereGeometry(DEFAULT_RADIUS);

                    // Invert the geometry on the x-axis.
                    // Required to create the effect of being in the center of a panorama (sphere)
                    geometry.scale(-1, 1, 1);

                    const mesh = new THREE.Mesh(geometry, material);
                    mesh.name = DEFAULT_MESH_NAME;

                    this.scene.add(mesh);
                    this.render();

                    setIsLoading(false);
                } catch (error) {
                    this.handleLoadError(error, setIsLoading);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            (error: any) => {
                this.handleLoadError(error, setIsLoading);
            }
        );
    }

    render = (): void => {
        requestAnimationFrame(this.render);

        // Limiting the latitude range between -85 and 85 degrees
        // to avoid possible issues with -90 and 90 degrees
        this.latitude = Math.max(-85, Math.min(85, this.latitude));

        // angle with the vertical axis (latitude)
        const phi = THREE.MathUtils.degToRad(90 - this.latitude);

        // angle with the horizontal axis (longitude)
        const theta = THREE.MathUtils.degToRad(this.longitude);

        const x = DEFAULT_RADIUS * Math.sin(phi) * Math.cos(theta);
        const y = DEFAULT_RADIUS * Math.cos(phi);
        const z = DEFAULT_RADIUS * Math.sin(phi) * Math.sin(theta);

        this.camera.lookAt(x, y, z);

        this.renderer.render(this.scene, this.camera);
    };

    renderCameraViewport(): void {
        this.renderer.render(this.scene, this.camera);
    }

    onWindowResize(width: number, height: number): void {
        this.viewportDimensions.width = width;
        this.viewportDimensions.height = height;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height);
    }

    drawContent = (point: { x: number; y: number }): void => {
        if (!this.lastPoint) {
            this.lastPoint = point;
            return;
        }

        this.eraserCanvasContext.fillStyle = 'white';
        this.eraserCanvasContext.strokeStyle = 'white';

        const angle = Math.atan2(point.x - this.lastPoint.x, point.y - this.lastPoint.y);
        const x = this.lastPoint.x + Math.sin(angle);
        const y = this.lastPoint.y + Math.cos(angle);

        // Calculation of the radius of the arch with additional dependence on the size of the canvas
        // Added to ensure the same line size regardless of the image type (normal or high resolution)
        const radius = this.eraserCanvas.width * NORMALIZATION_FACTOR * this.eraserSize;

        this.eraserCanvasContext.beginPath();
        this.eraserCanvasContext.arc(x, y, radius, 0, Math.PI * 2, false);

        this.eraserCanvasContext.closePath();
        this.eraserCanvasContext.fill();
        this.eraserCanvasContext.stroke();

        if (this.innerTexture) {
            this.innerTexture.needsUpdate = true;
        }

        this.lastPoint = point;
    };

    onMouseDown = (event: MouseEvent | TouchEvent): void => {
        this.oMouseDownClientX = (event as MouseEvent)?.clientX || (event as TouchEvent)?.touches[0]?.clientX;

        this.oMouseDownClientY = (event as MouseEvent)?.clientY || (event as TouchEvent)?.touches[0]?.clientY;

        this.onMouseDownLon = this.longitude;
        this.onMouseDownLat = this.latitude;
    };

    onMouseMove = (event: MouseEvent | TouchEvent): void => {
        if (!this.oMouseDownClientX && !this.oMouseDownClientY && !this.isEraserDrawing) {
            return;
        }

        try {
            const clientX = (event as MouseEvent)?.clientX || (event as TouchEvent)?.touches[0]?.clientX;
            const clientY = (event as MouseEvent)?.clientY || (event as TouchEvent)?.touches[0]?.clientY;
            if (this.isEraserDrawing) {
                if (!this.innerSphere) {
                    return;
                }

                const raycaster = new THREE.Raycaster();

                const sizeOffset = {
                    widthOffset: (window.innerWidth - this.viewportDimensions.width) / 2,
                    heightOffset: (window.innerHeight - this.viewportDimensions.height) / 1.4,
                };

                const mouse = new THREE.Vector2(
                    ((clientX - sizeOffset.widthOffset) / this.viewportDimensions.width) * 2 - 1,
                    -((clientY - sizeOffset.heightOffset) / this.viewportDimensions.height) * 2 + 1
                );

                raycaster.setFromCamera(mouse, this.camera);
                const intersects = raycaster.intersectObject(this.innerSphere);

                if (intersects.length > 0) {
                    if (this.helperPointer) {
                        this.helperPointer.visible = true;
                        this.helperPointer.position.copy(intersects[0].point);
                    }
                    const intersect = intersects[0];

                    const theta = Math.acos(intersect.point.y / intersect.point.length());
                    const v = theta / Math.PI;

                    if (!this.oMouseDownClientX || !this.oMouseDownClientY) {
                        return;
                    }

                    this.isDrawing = true;
                    if (intersect.uv) {
                        const pointX = Math.round(intersect.uv.x * this.textureDimensions.width);
                        const pointY = Math.round(v * this.textureDimensions.height);

                        this.points.push({ x: pointX, y: pointY });
                        this.drawContent({ x: pointX, y: pointY });
                    }
                }
            } else if (this.oMouseDownClientX && this.oMouseDownClientY) {
                this.longitude = (this.oMouseDownClientX - clientX) * SMOOTHING_FACTOR + this.onMouseDownLon;
                this.latitude = (clientY - this.oMouseDownClientY) * SMOOTHING_FACTOR + this.onMouseDownLat;
            }
        } catch (error) {
            console.error(error);
        }
    };

    onMouseUp = (): void => {
        if (!this.oMouseDownClientX && !this.oMouseDownClientY) {
            return;
        }

        this.isDrawing = false;
        this.lastPoint = null;
        this.oMouseDownClientX = null;
        this.oMouseDownClientY = null;
    };

    update(): void {
        this.renderCameraViewport();
    }

    saveCanvasAsImage = () => {
        if (!this.eraserCanvas) {
            return null;
        }

        const image = this.eraserCanvas.toDataURL('image/png');
        return image;
    };
}

export default PanoramicView;
