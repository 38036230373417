import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const NextIcon = (props: SvgIconProps) => (
    <SvgIcon
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M22.379 17.5858L28.1719 11.7929L22.379 6"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={'none'}
        />
        <path
            d="M22.379 25.3098H11.7586C9.96615 25.3098 8.24712 24.5977 6.97967 23.3303C5.71223 22.0629 5.00018 20.3438 5.00018 18.5514V18.5514C5.00018 16.7589 5.71223 15.0399 6.97967 13.7725C8.24712 12.505 9.96615 11.793 11.7586 11.793H28.1719"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={'none'}
        />
    </SvgIcon>
);
