import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const UpscaleIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M10.5 16.5V13M16 21H19.5M10.5 13V6H25.5V21H19.5M10.5 13H6V26H19.5V21M10.5 21.5L20 11M20 11V15.5M20 11H15.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
