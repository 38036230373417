import { BlobFile } from '@utils/blob/BlobFile';

export const downloadUrl = (url: string, name: string): Promise<BlobFile> => {
    return fetch(url)
        .then((res) => res.blob())
        .then((blob: Blob) => ({
            blob,
            name,
        }));
};
