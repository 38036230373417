import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GridViewIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={'0 0 20 24'}
            {...props}
        >
            <rect
                x="4"
                y="6"
                width="4.94118"
                height="4.94118"
                rx="1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
                fill="none"
            />
            <rect
                x="4"
                y="13.0605"
                width="4.94118"
                height="4.94118"
                rx="1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
                fill="none"
            />
            <rect
                x="11.0588"
                y="13.0605"
                width="4.94118"
                height="4.94118"
                rx="1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
                fill="none"
            />
            <rect
                x="11.0588"
                y="6"
                width="4.94118"
                height="4.94118"
                rx="1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
