import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { StatusDisplay } from '@pages/MainPage/components/StatusPanel/StatusDisplay';
import { JobWithAction } from '@utils/job/Job';

interface StatusSectionProps {
    icon: ReactElement;
    title: string;
    tasks: JobWithAction[];
    inactiveSection?: boolean;
    isQueueSection?: boolean;
    className?: string;
}

export const StatusSection = ({ icon, title, tasks, className, inactiveSection, isQueueSection }: StatusSectionProps) => {
    return tasks.length > 0 ? (
        <Wrapper className={className}>
            <Header>
                {icon}
                <Typography variant={'body16'}>{title}</Typography>
            </Header>

            {tasks.map((task) => (
                <StatusDisplay
                    withQueuePosition={isQueueSection && !!task.queuePosition}
                    inactiveSection={inactiveSection}
                    key={task.id}
                    task={task}
                />
            ))}
        </Wrapper>
    ) : (
        <></>
    );
};

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.6),
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
}));
