import React, { useState } from 'react';
import LocalStorageContext from './LocalStorageContext';
import { LocalStorageKeys } from '@root/utils/constants';

const LocalStorageContextProvider = ({ children }) => {
    const [storageUsername, setStorageUsername] = useState(localStorage.getItem(LocalStorageKeys.USERNAME_KEY));
    const [storageProjectId, setStorageProjectId] = useState(localStorage.getItem(LocalStorageKeys.PROJECT_KEY));
    const [storageProjectName, setStorageProjectName] = useState(localStorage.getItem(LocalStorageKeys.PROJECT_NAME_KEY));
    const [storageAccessToken, setStorageAccessToken] = useState(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN_KEY));
    const [storageRefreshToken, setStorageRefreshToken] = useState(localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN_KEY));
    const [storageTheme, setStorageTheme] = useState(localStorage.getItem(LocalStorageKeys.THEME_KEY));
    const [favoriteVisiblity, setFavoriteVisiblity] = useState(localStorage.getItem(LocalStorageKeys.FAVORITE_KEY));
    const [storageUserRole, setStorageUserRole] = useState(localStorage.getItem(LocalStorageKeys.USER_ROLE_KEY));

    const handleSetStorageUsername = (value) => {
        localStorage.setItem(LocalStorageKeys.USERNAME_KEY, value);
        setStorageUsername(value);
    };
    const handleSetStorageProjectId = (value) => {
        localStorage.setItem(LocalStorageKeys.PROJECT_KEY, value);
        setStorageProjectId(value);
    };

    const handleSetStorageProjectName = (value) => {
        localStorage.setItem(LocalStorageKeys.PROJECT_NAME_KEY, value);
        setStorageProjectName(value);
    };

    const handleSetStorageAccessToken = (value) => {
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN_KEY, value);
        setStorageAccessToken(value);
    };
    const handleSetStorageRefreshToke = (value) => {
        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN_KEY, value);
        setStorageRefreshToken(value);
    };
    const handleSetStorageTheme = (value) => {
        localStorage.setItem(LocalStorageKeys.THEME_KEY, value);
        setStorageTheme(value);
    };
    const handleSetFavoriteVisiblity = (value) => {
        localStorage.setItem(LocalStorageKeys.FAVORITE_KEY, value);
        setFavoriteVisiblity(value);
    };

    const handleSetStorageUserRole = (value) => {
        localStorage.setItem(LocalStorageKeys.USER_ROLE_KEY, value);
        setStorageUserRole(value);
    };

    return (
        <LocalStorageContext.Provider
            value={{
                storageUsername,
                setStorageUsername: handleSetStorageUsername,
                storageProjectId,
                setStorageProjectId: handleSetStorageProjectId,
                storageProjectName,
                setStorageProjectName: handleSetStorageProjectName,
                storageAccessToken,
                setStorageAccessToken: handleSetStorageAccessToken,
                storageRefreshToken,
                setStorageRefreshToken: handleSetStorageRefreshToke,
                storageTheme,
                setStorageTheme: handleSetStorageTheme,
                favoriteVisiblity,
                setFavoriteVisiblity: handleSetFavoriteVisiblity,
                storageUserRole,
                setStorageUserRole: handleSetStorageUserRole,
            }}
        >
            {children}
        </LocalStorageContext.Provider>
    );
};

export default LocalStorageContextProvider;
