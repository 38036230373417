import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AspectRatioPortraitIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 11 16"
            {...props}
        >
            <path
                opacity="0.35"
                d="M8.83333 0.5C9.42221 0.5 10 1.05997 10 1.88235L10 14.1176C10 14.94 9.42221 15.5 8.83333 15.5L2.16667 15.5C1.57779 15.5 0.999999 14.94 0.999999 14.1176L1 1.88235C1 1.05997 1.57779 0.5 2.16667 0.5L8.83333 0.5Z"
                stroke="currentColor"
                fill="none"
            />
            <rect
                opacity="0.7"
                x="8.5"
                y="2"
                width="12"
                height="6"
                transform="rotate(90 8.5 2)"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
