import { createTheme } from '@mui/material';
import Components from './ThemeComponents';
import ThemeTypography from './ThemeTypography';
import { themeBasic } from './ThemeBasic';
import '@assets/theme/ThemeType';

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#87B4EA',
            light: '#424242',
            light1: '#686868',
            grey: '#373737',
            inactive: '#8e8e8e',
            toggleInactive: '#a0a0a1',
            contrastText: '#ffffff',
            cta1: '#87B4EA',
            cta2: '#D4D4D4',
            cta3: '#e8edfa',
            progress1: '#87B4EA',
            progress2: '#CCDFF7',
        },
        neutrals: {
            main: '#2b2b2b',
            light: '#424242',
            light1: '#686868',
            inactive: '#8e8e8e',
            inactive1: '#a0a0a1',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#000000',
            grey: '#121213',
        },
        shadow: {
            grey: '#1212134D',
        },
        info: {
            main: '#6684de',
        },
        success: {
            main: '#9eeec7',
        },
        warning: {
            main: '#fed982',
        },
        error: {
            main: '#fe8588',
        },
        mode: 'dark',
    },
    components: Components,
    typography: ThemeTypography,
    ...themeBasic,
});
