import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ImportModelIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M14.5714 6H8.85714C8.09938 6 7.37266 6.30102 6.83684 6.83684C6.30102 7.37266 6 8.09938 6 8.85714V23.1429C6 23.9006 6.30102 24.6273 6.83684 25.1632C7.37266 25.699 8.09938 26 8.85714 26H23.1429C23.9006 26 24.6273 25.699 25.1632 25.1632C25.699 24.6273 26 23.9006 26 23.1429V8.85714"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M20.5716 16L16.0001 20.5714L11.4287 16"
                stroke="currentColor"
                strokeWidth="1.80952"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M26 6H20.2857C19.1491 6 18.059 6.4425 17.2553 7.23015C16.4515 8.0178 16 9.08609 16 10.2V20"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
