import { BlobFile } from './BlobFile';
import JSZip from 'jszip';
import mime from 'mime/lite';

export class FolderStructureBlob {
    private files: BlobFile[] = [];
    private subFolders: FolderStructureBlob[] = [];

    constructor(public readonly name: string) {}

    withFile(file: BlobFile): FolderStructureBlob {
        this.files.push(file);
        return this;
    }

    withFiles(files: BlobFile[]): FolderStructureBlob {
        files.forEach((file) => this.files.push(file));
        return this;
    }

    withSubFolder(folder: FolderStructureBlob): FolderStructureBlob {
        this.subFolders.push(folder);
        return this;
    }

    withSubFolders(folders: FolderStructureBlob[]): FolderStructureBlob {
        folders.forEach((folder) => this.subFolders.push(folder));
        return this;
    }

    get hasFiles(): boolean {
        return this.files.length != 0;
    }

    get hasSubFolders(): boolean {
        return this.subFolders.length != 0;
    }

    get isEmpty(): boolean {
        return !this.hasFiles && !this.hasSubFolders;
    }

    private async createJSZip(): Promise<JSZip> {
        const zip = new JSZip();

        this.addFilesToZip(zip);
        this.addSubFoldersToZip(zip);

        return zip;
    }

    addFilesToZip(zip: JSZip) {
        if (this.hasFiles) {
            this.files.map((file) => {
                const extension = mime.getExtension(file.blob.type);
                zip.file(`${file.name}.${extension}`, file.blob, { binary: true });
            });
        }
    }

    addSubFoldersToZip(zip: JSZip) {
        if (this.hasSubFolders) {
            this.subFolders.forEach(async (folder: FolderStructureBlob) => {
                const subfolderZip = zip.folder(folder.name);
                if (!subfolderZip) {
                    return;
                }
                folder.addFilesToZip(subfolderZip);
                folder.addSubFoldersToZip(subfolderZip);
            });
        }
    }

    async toZipBlob(): Promise<Blob> {
        const zip = await this.createJSZip();
        return zip.generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 6 } });
    }

    async toBuffer(): Promise<string> {
        const zip = await this.createJSZip();
        return zip.generateAsync({ type: 'base64' });
    }
}
