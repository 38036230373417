import { Alert, Slider, Table, styled } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

const Components = {
    MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
            body: {
                backgroundColor: themeParam.palette.mode === 'dark' ? themeParam.palette.neutrals.main : themeParam.palette.secondary.main,
                color: themeParam.palette.mode === 'dark' ? themeParam.palette.secondary.main : deepPurple[400],
            },
        }),
    },
    MuiSwitch: {
        styleOverrides: {
            root: {
                height: 30,
                width: 47,
            },
            track: {
                width: 37,
            },
            thumb: {
                height: 12,
                width: 12,
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                minHeight: '40px',
                fontSize: '14px',
                fontFamily: 'Roboto400',
            },
        },
    },
};

export const CustomTable = styled(Table)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#323232' : theme.palette.secondary.main,
    border: theme.palette.mode === 'dark' ? '2px solid #4D4D4D' : '2px solid #FFFFFF61',
}));

export const CssSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.primary.light1,
    height: '2px',
    marginLeft: '0',
    marginRight: '10px',
    '& 	.MuiSlider-markLabel': {
        color: theme.palette.primary.light1,
    },

    '& 	.MuiSlider-valueLabel': {
        height: '17px',
        width: '27px',
    },
    '& 	.MuiSlider-valueLabelLabel': {
        fontSize: '12px',
    },
    '& 	.MuiSlider-mark': {
        color: 'transparent',
    },

    '& .MuiSlider-track': {
        color: theme.palette.primary.main,
        height: '1px',
    },

    '& .MuiSlider-thumb': {
        color: theme.palette.primary.main,
        width: '10px',
        height: '10px',
        marginLeft: '5px',

        '&:hover, &.Mui-focusVisible': {
            boxShadow: 'none',
        },
        '&.Mui-active': {
            boxShadow: 'none',
        },
    },
}));

export const CssAlert = styled(Alert)(({ theme }) => ({
    color: theme.palette.secondary.main,
    background: theme.palette.neutrals.main,
    border: `1px solid ${theme.palette.primary.main}`,

    '& .MuiAlert-icon': {
        color: theme.palette.secondary.main,
    },
}));

export default Components;
