import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SendToGenerateIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M26 6L6 15.2308L15.2308 17.1538M26 6L18.3077 26L15.2308 17.1538M26 6L15.2308 17.1538"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill={'none'}
            />
        </SvgIcon>
    );
};
