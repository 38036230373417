import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext(null);

export const ModalContextProvider = ({ children }) => {
    const [createProjectModalState, setCreateProjectModalState] = useState(false);

    const handleOpenModal = () => setCreateProjectModalState(true);
    const handleCloseModal = () => setCreateProjectModalState(false);

    const value = {
        createProjectModalState,
        handleOpenModal,
        handleCloseModal,
    };

    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
    const context = useContext(ModalContext);
    return context;
};
