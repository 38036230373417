export const PASSWORD_PLACEHOLDER = '********';
export const EMPTY_DATE = 'None';
export const DEFAULT_USER_INFO = {
    id: '-999',
    name: 'name',
    roleId: 1,
    expirationDate: '',
    password: PASSWORD_PLACEHOLDER,
    dateCreated: '',
};
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DEFAULT_DATE_LABEL_FORMAT = 'DD/MM/YYYY';
