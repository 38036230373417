import React, { useEffect, useState } from 'react';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import DragAndDropPanel from '@root/components/DragAndDropPanel';
import { CommonConstants } from '@root/utils/constants';
import { Box, styled } from '@mui/material';

interface MixImageIngredientPanelProps {
    isFirstIngredient: boolean;
    isExpanded: boolean;
}

export const MixImageIngredientPanel = ({ isFirstIngredient, isExpanded }: MixImageIngredientPanelProps) => {
    const { firstMixingIngredient, secondMixingIngredient, setFirstMixingIngredient, setSecondMixingIngredient } = useMixImage();

    const [imageUrl, setImageUrl] = useState<string | null>(null);

    const handleUpload = (file: File) => {
        if (isFirstIngredient) {
            setFirstMixingIngredient(file);
        } else {
            setSecondMixingIngredient(file);
        }
    };

    useEffect(() => {
        if (isFirstIngredient) {
            setImageUrl(firstMixingIngredient?.dataUrl ?? null);
        } else {
            setImageUrl(secondMixingIngredient?.dataUrl ?? null);
        }
    }, [isFirstIngredient, firstMixingIngredient, secondMixingIngredient]);

    return (
        <Wrapper expanded={isExpanded}>
            <DragAndDropPanel
                isPreviewMode={!isExpanded}
                placeholderRatio={CommonConstants.DEFAULT_PLACEHOLDER_RATIO}
                enabled={true}
                OnUpload={handleUpload}
                loadedImageUrl={imageUrl ? imageUrl : ''}
            />
        </Wrapper>
    );
};

const Wrapper = styled(Box)<{ expanded: boolean }>(({ expanded }) => ({
    ...(!expanded && {
        width: '84px',
    }),

    '& .drag-and-drop-image-wrapper': {
        transition: 'all 0.5s',
        maxHeight: !expanded ? '50px' : '150px',

        '& div': {
            ...(expanded && {
                height: '150px',
            }),

            ...(!expanded && {
                width: '84px',
            }),
        },
    },

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
}));
