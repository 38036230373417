import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Box, Button, MenuItem, SelectChangeEvent, Typography, styled } from '@mui/material';
import ExpandMenuButton from '@root/components/ExpandMenuButton';
import { HotKeysList, ModelStatus, NavigationPaths, RequestErrorCode, SupportedHotKeys } from '@root/utils/constants/enums';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InputField from '@root/components/InputField';
import ActiveModelContext from '@root/context/ActiveModelContext/ActiveModelContext';
import SelectField from '@root/components/SelectField';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import useAutosave from '@root/utils/useAutosave.hook';
import { CommonConstants, ErrorMessages } from '@root/utils/constants';
import { ExpandMenuIcon } from '@root/assets/icons/ExpandMenuIcon';
import { TrainModelFormProps } from './types/TrainModelForm.types';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import ShareDialog from '../../ShareDialog';
import { FormWrapper } from '@root/components/FormWrapper';
import { ErrorTooltip } from '@root/components/ErrorTooltip';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

export const TrainModelForm = ({
    onSubmit,
    onRetrain,
    setIsChanged,
    errorMessage,
    setErrorMessage,
}: TrainModelFormProps): React.JSX.Element => {
    const navigate = useNavigate();
    const {
        activeModel,
        setActiveModelConfig,
        activeModelStatus,
        exportActiveModel,
        exportingModelIds,
        generateOtpForModel,
        updateModel,
        activeModelImages,
        exportModalState,
        setExportModalState,
        validateModelName,
    } = useContext(ActiveModelContext);
    const { trainedModelsList, refreshModels } = useContext(ModelsContext);
    const { checkLicenseStatus } = useLicenseValidation();

    const { id } = useParams();
    const location = useLocation();
    const menuActionHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_H].key,
        SupportedHotKeys[HotKeysList.CTRL_H].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_H].blockDefaultEvent
    );

    const [isActive, setIsActive] = useState<boolean>(true);
    const [parentModel, setParentModel] = useState<string>('');
    const [isParentModelFromInput, setIsParentModelFromInput] = useState<boolean>(false);
    const [modelTitle, setModelTitle] = useState<string>('');
    const [isModelTitleFromInput, setIsModelTitleFromInput] = useState<boolean>(false);
    const [conceptInstanceToken, setConceptInstanceToken] = useState<string>('');
    const [isConceptInstanceTokenFromInput, setIsConceptInstanceTokenFromInput] = useState<boolean>(false);
    const [exportButtonDisable, setExportButtonDisable] = useState<boolean>(false);
    const [advanced, setAdvanced] = useState<boolean>(false);
    const [activeModelOtp, setActiveModelOtp] = useState<string>('');

    const isExportModelButton =
        activeModel && (activeModel.StatusId === ModelStatus.Trained || activeModel.StatusId === ModelStatus.Merged);

    const isTrainModelButton = activeModel.StatusId !== ModelStatus.Trained && activeModel.StatusId !== ModelStatus.Merged;

    const isRetrainMode = id && activeModel?.StatusId === ModelStatus.Trained && activeModel.ParentId;

    const buttonAction = (): void => {
        setIsActive(!isActive);
    };

    const blurHandler = (): void => {
        const validationErrorMessage = validateModelName(modelTitle);

        if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
        }
    };

    const submitAction = (): void => {
        onSubmit();

        if (!errorMessage) {
            navigate(`/${NavigationPaths.ModelsList}`);
        }
    };

    const retrainAction = (): void => {
        onRetrain();

        if (!errorMessage) {
            navigate(`/${NavigationPaths.ModelsList}`);
        }
    };

    const handleAdvanced = (): void => {
        setAdvanced(!advanced);
    };

    const onModelTitleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (errorMessage) {
            setErrorMessage('');
        }

        if (isRetrainMode) {
            setIsChanged(true);
        }

        setActiveModelConfig((prev: any) => {
            return {
                ...prev,
                modelName: event.target.value,
            };
        });
        setIsModelTitleFromInput(true);
        setModelTitle(event.target.value);
    };

    const onParentModelInputChange = (event: SelectChangeEvent<string>, child: ReactNode): void => {
        if (isRetrainMode) {
            setIsChanged(true);
        }

        setActiveModelConfig((prev: any) => {
            return {
                ...prev,
                parentModelId: event.target.value,
            };
        });
        setIsParentModelFromInput(true);
        setParentModel(event.target.value);
    };

    const onConceptInstanceTokenInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (isRetrainMode) {
            setIsChanged(true);
        }

        setActiveModelConfig((prev: any) => {
            return {
                ...prev,
                conceptInstanceToken: event.target.value,
            };
        });
        setIsConceptInstanceTokenFromInput(true);
        setConceptInstanceToken(event.target.value);
    };

    const handleExport = async (): Promise<void> => {
        await exportActiveModel();
    };

    const handleOtpGeneration = async (): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        const result = await generateOtpForModel();

        if (result && !result.errorCode) {
            setActiveModelOtp(result);
        }
    };

    const toggleExportModal = (): void => {
        if (exportModalState) {
            setExportModalState(false);
            setActiveModelOtp('');
        } else {
            setExportModalState(true);
        }
    };

    useEffect(() => {
        if (menuActionHotKey) {
            buttonAction();
        }
    }, [menuActionHotKey]);

    useEffect(() => {
        setExportButtonDisable(!!exportingModelIds.find((el: number) => el === activeModel.Id));
    }, [exportingModelIds, activeModel]);

    useEffect(() => {
        if (!activeModel || !id) {
            return;
        }

        if (errorMessage) {
            setErrorMessage('');
        }
        setIsModelTitleFromInput(false);
        setModelTitle(activeModel.Name.replaceAll('.safetensors', ''));

        setIsParentModelFromInput(false);
        setParentModel(activeModel.ParentId === null ? '0' : activeModel.ParentId);

        const trainingSettings = activeModel.Configurations !== 'default' && JSON.parse(activeModel.Configurations);

        if (trainingSettings) {
            setIsConceptInstanceTokenFromInput(false);
            setConceptInstanceToken(trainingSettings.token);
        }
        setActiveModelConfig({
            conceptClassPrompt: trainingSettings ? trainingSettings.concept_class_prompt : '',
            conceptClassToken: trainingSettings ? trainingSettings.concept_class_token : '',
            conceptInstanceToken: trainingSettings ? trainingSettings.token : '',
            modelId: activeModel.Id,
            modelName: activeModel.Name.replaceAll('.safetensors', ''),
            previewId: trainingSettings?.preview_id || null,
            parentModelId: !activeModel.Configurations
                ? trainedModelsList.find((el: any) => el.Name.includes(CommonConstants.DEFAULT_MODEL_NAME)).Id
                : activeModel.ParentId,
        });
    }, [activeModel]);

    useEffect(() => {
        setErrorMessage('');
        setIsModelTitleFromInput(false);
        setModelTitle('');
        setIsConceptInstanceTokenFromInput(false);
        setConceptInstanceToken('');
        setActiveModelConfig({
            conceptClassPrompt: '',
            conceptClassToken: '',
            conceptInstanceToken: '',
            modelId: '',
            modelName: '',
            parentModelId: '',
            previewId: null,
        });
    }, [location]);

    useEffect(() => {
        const defaultMod = trainedModelsList.find((el: any) => el.Name.includes(CommonConstants.DEFAULT_MODEL_NAME));

        if (!id && defaultMod) {
            setActiveModelConfig((prev: any) => {
                return {
                    ...prev,
                    parentModelId: defaultMod.Id,
                };
            });

            setIsParentModelFromInput(false);
            setParentModel(defaultMod.Id);
        }
    }, [trainedModelsList, location]);

    useAutosave(
        async () => {
            if (id && !isRetrainMode) {
                if (!modelTitle) {
                    setErrorMessage(ErrorMessages.MODEL_ERROR_MESSAGE.NAME_IS_EMPTY);
                    return;
                }

                const result = await updateModel();
                if (result?.errorCode) {
                    setErrorMessage(
                        result?.errorCode === RequestErrorCode.MODEL_ALREADY_EXISTS
                            ? ErrorMessages.MODEL_ERROR_MESSAGE.ALREADY_EXISTS
                            : ErrorMessages.MODEL_ERROR_MESSAGE.UPDATE_ERROR
                    );
                }
            }
        },
        500,
        [parentModel, conceptInstanceToken],
        !isParentModelFromInput && !isConceptInstanceTokenFromInput
    );

    useAutosave(
        async () => {
            if (id && !isRetrainMode) {
                if (!modelTitle) {
                    setErrorMessage(ErrorMessages.MODEL_ERROR_MESSAGE.NAME_IS_EMPTY);
                    return;
                }

                const result = await updateModel();
                if (result?.errorCode) {
                    setErrorMessage(
                        result?.errorCode === RequestErrorCode.MODEL_ALREADY_EXISTS
                            ? ErrorMessages.MODEL_ERROR_MESSAGE.ALREADY_EXISTS
                            : ErrorMessages.MODEL_ERROR_MESSAGE.UPDATE_ERROR
                    );
                    return;
                } else {
                    refreshModels();
                }
            }
        },
        500,
        [modelTitle],
        !isModelTitleFromInput
    );

    return (
        <>
            <Wrapper>
                <FormWrapper
                    id="trainForm"
                    component="form"
                    active={isActive ? 1 : 0}
                    onSubmit={submitAction}
                >
                    <Content>
                        <SelectField
                            id="baseModel"
                            name="baseModel"
                            value={!parentModel ? '' : parentModel}
                            placeholder={'default'}
                            onChange={onParentModelInputChange}
                            label={'base model for training'}
                            isRequired={true}
                            isDisabled={activeModelStatus && !isRetrainMode}
                            content={trainedModelsList.map((model: any) => {
                                return (
                                    <MenuItem
                                        key={model.Id}
                                        value={model.Id}
                                    >
                                        {model.Name.replaceAll('.safetensors', '')}
                                    </MenuItem>
                                );
                            })}
                        />
                        <ErrorTooltip
                            className="form-menu-input"
                            title={errorMessage}
                            placement="right"
                            open={!!errorMessage}
                            arrow
                        >
                            <span>
                                <InputField
                                    id="newModelName"
                                    name="newModelName"
                                    isRequired={true}
                                    isDisabled={activeModelStatus && !isRetrainMode}
                                    error={!!errorMessage}
                                    label="new model name"
                                    value={modelTitle || ''}
                                    onBlur={blurHandler}
                                    onChange={onModelTitleInputChange}
                                    tooltipLabel={'Assign a name to the model you are creating through the new training process'}
                                />
                            </span>
                        </ErrorTooltip>
                        <ExpandIconWrapper>
                            <InputLabel variant="body16">{'advanced'}</InputLabel>
                            <ExpandIcon
                                active={+advanced}
                                onClick={handleAdvanced}
                            ></ExpandIcon>
                        </ExpandIconWrapper>
                        {advanced && (
                            <InputField
                                id="conceptInstanceToken"
                                name="conceptInstanceToken"
                                isRequired={false}
                                isDisabled={activeModelStatus && !isRetrainMode}
                                label="key word"
                                value={conceptInstanceToken || ''}
                                onChange={onConceptInstanceTokenInputChange}
                                tooltipLabel={'In this text input field, enter the word you will use when generating the car images.'}
                            />
                        )}
                    </Content>
                    <Footer>
                        {isExportModelButton && (
                            <FormButton
                                active={isActive ? 1 : 0}
                                disabled={exportButtonDisable}
                                onClick={toggleExportModal}
                                variant="contained"
                            >
                                SHARE MODEL
                            </FormButton>
                        )}
                        {isTrainModelButton && (
                            <FormButton
                                active={isActive ? 1 : 0}
                                disabled={!id || activeModelStatus || !activeModelImages.length || !!errorMessage}
                                type="submit"
                                variant="contained"
                            >
                                TRAIN MODEL
                            </FormButton>
                        )}
                        {isRetrainMode && (
                            <FormButton
                                active={isActive ? 1 : 0}
                                disabled={!activeModelImages.length || !!errorMessage}
                                onClick={retrainAction}
                                variant="contained"
                            >
                                RETRAIN MODEL
                            </FormButton>
                        )}
                    </Footer>
                </FormWrapper>
                <ExpandMenuButton
                    isActive={isActive}
                    buttonAction={buttonAction}
                />
            </Wrapper>
            <ShareDialog
                isDownloadDisabled={exportButtonDisable}
                isExport={true}
                isActive={exportModalState}
                keyValue={activeModelOtp}
                handleDownload={handleExport}
                handleKeyGeneration={handleOtpGeneration}
                onClose={toggleExportModal}
            />
        </>
    );
};

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    maxWidth: '402px',
    flex: 1,
}));

const Content = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    overflowY: 'auto',
    overflowX: 'hidden',
    gap: '32px',
}));

const Footer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 0px',
    gap: '32px',
}));

const FormButton = styled(Button)<{ active: number }>(({ active }) => ({
    width: '300px',
    transition: 'all 0.5s',
    display: active ? 'block' : 'none',
}));

const ExpandIconWrapper = styled(Box)(() => ({
    display: 'flex',
}));

const ExpandIcon = styled(ExpandMenuIcon)<{ active: number }>(({ active, theme }) => ({
    transform: active ? 'rotate(180deg)' : 'unset',
    transition: 'all 0.5s',
    cursor: 'pointer',
    '& path': {
        stroke: theme.palette.neutrals.inactive1,
    },

    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50%',

        '& path': {
            stroke: theme.palette.secondary.main,
        },
    },
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.neutrals.inactive1,
    marginRight: '16px',
    alignSelf: 'center',
}));
