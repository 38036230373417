import React from 'react';
import { Box, Divider, Skeleton, styled } from '@mui/material';
import { ListRowPlaceholderProps } from './ListRowPlaceholder.types';

const ListRowPlaceholder = ({ placeholderVariant }: ListRowPlaceholderProps): React.JSX.Element => {
    return (
        <div>
            <Wrapper>
                <PlaceholderImage
                    className={placeholderVariant}
                    variant="rectangular"
                    animation="wave"
                />
                <PlaceholderInfo
                    variant="rectangular"
                    animation="wave"
                />
            </Wrapper>
            <Divider />
        </div>
    );
};

export default ListRowPlaceholder;

const Wrapper = styled(Box)(({ theme }) => ({
    direction: 'ltr',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '64px',

    padding: '7px 29px 7px 0px',
    border: `1px solid ${theme.palette.primary.light}`,

    background: theme.palette.primary.light,
    opacity: '0.3',
}));

const PlaceholderImage = styled(Skeleton)(({ theme }) => ({
    '&.curate': {
        width: '50px',
        height: '50px',
    },

    '&.scene': {
        width: '76px',
        height: '54px',
    },

    marginLeft: '28px',

    background: theme.palette.primary.light1,
    borderRadius: '3px',
}));

const PlaceholderInfo = styled(Skeleton)(({ theme }) => ({
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',

    border: `1px solid ${theme.palette.primary.light1}`,
    borderRadius: '2px',

    marginLeft: '12px',
    height: '15px',
    width: '115px',
}));
