import { Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils';

// Camera settings
export const CAMERA_INITIAL_POSITION = new Vector3(5, 5, 5);
export const CAMERA_MIN_DISTANCE = 2;
export const CAMERA_MAX_DISTANCE = 30;
export const CAMERA_MIN_POLAR_ANGLE = degToRad(0);
export const CAMERA_MAX_POLAR_ANGLE = degToRad(90);

// Environment Sizes

export const INITIAL_SCENE_SIZE = 60;
export const ENV_BORDERS = INITIAL_SCENE_SIZE - 2;

// Colors
export const SCENE_COLOR = 0x29262e;

export const MODEL_COLOR = 0x080808;

// Material Properties
export const MATERIAL_METALNESS = 0.2;
export const MATERIAL_ROUGHNESS = 0.4;

// External Paths
export const DRACO_DECODER_PATH = 'https://unpkg.com/three@latest/examples/jsm/libs/draco/gltf/';

export const LENS_RANGE = {
    min: 4,
    max: 200,
};
export const LENS_INITIAL_VALUE = 50;

export const ROLL_RANGE = {
    min: -90,
    max: 90,
};
