import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const JobProcessingIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}
        >
            <g clipPath="url(#clip0_1519_1168)">
                <path
                    d="M12 1C18.0752 1 23 5.92487 23 12C23 18.0752 18.0752 23 12 23C5.92487 23 1 18.0752 1 12C1 5.92487 5.92487 1 12 1Z"
                    stroke="#686868"
                    strokeWidth="2"
                    strokeLinecap="round"
                    fill="none"
                />
                <path
                    d="M12 1C18.0752 1 23 5.92487 23 12"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    fill="none"
                />
            </g>
            <defs>
                <clipPath id="clip0_1519_1168">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
