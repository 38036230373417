import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <circle
                cx="15.7273"
                cy="12.7273"
                r="6.97727"
                stroke="currentColor"
                strokeWidth="1.5"
                fill="none"
            />
            <path
                d="M19.5908 19.2969L24 26.001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
