import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PanToolIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2432 10.3462C10.2432 9.00278 11.3323 7.91375 12.6757 7.91375C14.0191 7.91375 15.1081 9.00278 15.1081 10.3462V15.7932C15.1081 16.241 14.7451 16.604 14.2973 16.604C13.8495 16.604 13.4865 16.241 13.4865 15.7932V10.3462C13.4865 9.89838 13.1235 9.53537 12.6757 9.53537C12.2279 9.53537 11.8649 9.89838 11.8649 10.3462V17.9138C11.8649 18.3616 11.5019 18.7246 11.0541 18.7246C10.6063 18.7246 10.2432 18.3616 10.2432 17.9138V10.3462Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9189 8.12162C15.4711 8.12162 15.1081 8.48463 15.1081 8.93243L15.1081 15.7932C15.1081 16.241 14.7451 16.604 14.2973 16.604C13.8495 16.604 13.4865 16.241 13.4865 15.7932L13.4865 8.93243C13.4865 7.58904 14.5755 6.5 15.9189 6.5C17.2623 6.5 18.3514 7.58904 18.3514 8.93243V15.0863C18.3514 15.5341 17.9883 15.8971 17.5405 15.8971C17.0927 15.8971 16.7297 15.5341 16.7297 15.0863V8.93243C16.7297 8.48463 16.3667 8.12162 15.9189 8.12162Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7297 10.3462C16.7297 9.00278 17.8187 7.91375 19.1621 7.91375C20.5055 7.91375 21.5946 9.00278 21.5946 10.3462V16.5C21.5946 16.9478 21.2316 17.3108 20.7838 17.3108C20.336 17.3108 19.973 16.9478 19.973 16.5V10.3462C19.973 9.89838 19.6099 9.53537 19.1621 9.53537C18.7143 9.53537 18.3513 9.89838 18.3513 10.3462V15.7932C18.3513 16.241 17.9883 16.604 17.5405 16.604C17.0927 16.604 16.7297 16.241 16.7297 15.7932V10.3462Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.973 13.1424C19.973 11.8015 21.0769 10.7411 22.4054 10.7411C23.7339 10.7411 24.8378 11.8014 24.8378 13.1424V17.737C24.8378 22.5914 20.8298 26.5 15.9189 26.5H15.3784C10.766 26.5 7 22.8288 7 18.2671V15.9698C7 14.6289 8.10395 13.5686 9.43243 13.5686C10.7609 13.5686 11.8649 14.6289 11.8649 15.9698L11.8649 17.9138C11.8649 18.3616 11.5019 18.7246 11.0541 18.7246C10.6063 18.7246 10.2432 18.3616 10.2432 17.9138L10.2432 15.9698C10.2432 15.554 9.89514 15.1902 9.43243 15.1902C8.96972 15.1902 8.62162 15.554 8.62162 15.9698V18.2671C8.62162 21.9037 11.6318 24.8784 15.3784 24.8784H15.9189C19.964 24.8784 23.2162 21.6663 23.2162 17.737V13.1424C23.2162 12.7266 22.8681 12.3628 22.4054 12.3628C21.9427 12.3628 21.5946 12.7266 21.5946 13.1424L21.5946 16.5C21.5946 16.9478 21.2316 17.3108 20.7838 17.3108C20.336 17.3108 19.973 16.9478 19.973 16.5L19.973 13.1424Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
