import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddNewModelIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M14 6H8C6.89543 6 6 6.89543 6 8V24.2815C6 25.386 6.89543 26.2815 7.99999 26.2815L24.3068 26.2815C25.4113 26.2815 26.3068 25.3861 26.3068 24.2815V18.144M22.5 6V14.4101M18.5 9.95768H26.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
