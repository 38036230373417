import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider, Stack, styled } from '@mui/material';
import { Active, DndContext, KeyboardSensor, Over, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { ImageGenerationStatus, SceneMode } from '@root/utils/constants/enums';
import { CommonConstants } from '@root/utils/constants';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { useScene } from '@root/context/ScenePageContext/useScene';
import { SceneLayerDto } from '@root/types/dto';
import ExpandMenuButton from '@root/components/ExpandMenuButton';
import SortableListRowOverlay from '@root/components/SortableListHelpers/SortableListRowOverlay';
import SortableListRow from '@root/components/SortableListHelpers/SortableListRow';
import ListRowPlaceholder from '@root/components/SortableListHelpers/ListRowPlaceholder';
import SceneImagesListRow from '../SceneImagesListRow';

const SceneImagesList = (): React.JSX.Element => {
    const sceneImagesListRef = useRef<HTMLDivElement>(null);

    const {
        imagesList,
        selectedImage,
        activeMode,
        isEraserInProgress,
        isToolbarDisabled,
        currentlyInProgress,
        isMetadataDialogActive,
        setActiveMode,
        changeImagesOrder,
        moveToNextImage,
        moveToPreviousImage,
        deleteImage,
        renameImage,
        toggleMetadataDialog,
        onSelectedImageChange,
    } = useScene();

    const arrowUpPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.UP);
    const arrowDownPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.DOWN);

    const [isActive, setIsActive] = useState<boolean>(true);
    const [isMenuInFocus, setIsMenuInFocus] = useState<boolean>(false);

    const [active, setActive] = useState<Active | null>(null);

    const activeImage = useMemo(() => imagesList.find((image) => image.id === active?.id), [active, imagesList]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const menuButtonAction = (): void => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        if (arrowUpPressed && isMenuInFocus) {
            moveToPreviousImage();
        }
    }, [arrowUpPressed]);

    useEffect(() => {
        if (arrowDownPressed && isMenuInFocus) {
            moveToNextImage();
        }
    }, [arrowDownPressed]);

    useEffect(() => {
        const checkFocus = (event: MouseEvent) => {
            if (event.target !== sceneImagesListRef.current && !sceneImagesListRef.current?.contains(event.target as HTMLElement)) {
                setIsMenuInFocus(false);
            }
        };

        window.addEventListener('click', checkFocus);

        return () => {
            window.removeEventListener('click', checkFocus);
        };
    }, []);

    const dragEndHandler = (active: Active, over: Over | null): void => {
        if (over && active.id !== over?.id) {
            const activeIndex = imagesList.findIndex((image) => image.id === active.id);
            const overIndex = imagesList.findIndex((image) => image.id === over.id);

            const updatedImages = arrayMove(imagesList, activeIndex, overIndex);
            const updatedImagesIds = updatedImages.map((image) => image.id);

            changeImagesOrder(updatedImagesIds);
        }
        setActive(null);
    };

    const onSelectHandler = useCallback(
        (value: SceneLayerDto) => {
            if (isEraserInProgress) {
                return;
            }

            if (activeMode !== SceneMode.ERASE) {
                setActiveMode(SceneMode.PAN);
            }

            onSelectedImageChange(value);
        },
        [imagesList, selectedImage, isEraserInProgress]
    );

    const onDeleteHandler = useCallback(
        (imageId: number) => {
            deleteImage(imageId);
        },
        [imagesList, selectedImage]
    );

    const onRenameHandler = useCallback(
        (imageId: number, name: string) => {
            renameImage(imageId, name);
        },
        [imagesList, selectedImage]
    );

    const showMetadataDialog = useCallback(
        (id: number) => {
            toggleMetadataDialog();
            const image = imagesList.find((image) => image.id === id);
            if (image) {
                onSelectHandler(image);
            }
        },
        [imagesList, isMetadataDialogActive]
    );

    return (
        <Wrapper ref={sceneImagesListRef}>
            <ExpandMenuButton
                direction="left"
                label={'Images'}
                isActive={isActive}
                isDisabled={isToolbarDisabled}
                buttonAction={menuButtonAction}
            />
            <ImagesMenu active={isActive && !isToolbarDisabled}>
                <ImagesMenuContent>
                    {currentlyInProgress > 0 &&
                        Array.from({ length: currentlyInProgress }, (_, index) => {
                            return (
                                <ListRowPlaceholder
                                    placeholderVariant={'scene'}
                                    key={index}
                                />
                            );
                        })}
                    <DndContext
                        sensors={sensors}
                        onDragStart={({ active }) => {
                            setActive(active);
                        }}
                        onDragEnd={({ active, over }) => {
                            dragEndHandler(active, over);
                        }}
                        onDragCancel={() => {
                            setActive(null);
                        }}
                    >
                        <SortableContext items={imagesList}>
                            {imagesList
                                .concat()
                                .reverse()
                                .map(
                                    (image, i) =>
                                        image.statusPanoramaId === ImageGenerationStatus.READY && (
                                            <SortableListRow
                                                id={image.id}
                                                key={image.id}
                                            >
                                                <div>
                                                    <SceneImagesListRow
                                                        onRename={onRenameHandler}
                                                        onDelete={onDeleteHandler}
                                                        showMetadataDialog={showMetadataDialog}
                                                        image={image}
                                                        orderIndex={image.orderIndex}
                                                        isSelected={image.id === selectedImage?.id}
                                                        selectRowImage={onSelectHandler}
                                                        setFocus={setIsMenuInFocus}
                                                    />
                                                    {i !== imagesList.length - 1 && <Divider />}
                                                </div>
                                            </SortableListRow>
                                        )
                                )}
                        </SortableContext>
                        <SortableListRowOverlay>
                            {activeImage ? (
                                <SortableListRow id={activeImage.id}>
                                    <SceneImagesListRow
                                        onRename={onRenameHandler}
                                        onDelete={onDeleteHandler}
                                        selectRowImage={onSelectHandler}
                                        showMetadataDialog={showMetadataDialog}
                                        image={activeImage}
                                        setFocus={setIsMenuInFocus}
                                        isSelected={activeImage.id === selectedImage?.id}
                                    />
                                </SortableListRow>
                            ) : null}
                        </SortableListRowOverlay>
                    </DndContext>
                </ImagesMenuContent>
            </ImagesMenu>
        </Wrapper>
    );
};

export default SceneImagesList;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    maxWidth: '316px',
}));

const ImagesMenu = styled(Box)<{ active: boolean }>(({ theme, active }) => ({
    overflow: 'auto',
    scrollBehavior: 'smooth',
    direction: 'rtl',

    width: active ? '281px' : '0px',
    maxHeight: 'calc(100vh - 170px)',
    minHeight: '130px',
    height: 'fit-content',

    position: 'relative',

    background: theme.palette.neutrals.main,
    border: `${active ? 1 : 0}px solid ${theme.palette.primary.light}`,
    borderBottomLeftRadius: '16px',

    transition: 'all 0.5s',
    zIndex: 10,

    '& *': {
        display: !active && 'none',
    },
}));

const ImagesMenuContent = styled(Stack)(() => ({
    direction: 'rtl',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    marginBottom: '10px',
}));
