import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FullScreenOpenIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M4.99819 5.72656L10.6769 11.4714M4.99819 5.72656V10.9491M4.99819 5.72656H10.109M4.94142 26.1685L10.6201 20.4237M25.3846 26.1685L19.7628 20.4237M25.3846 5.72656L19.7627 11.4714M20.3306 5.72656H25.4414V10.9491M25.3846 21.004V26.2266H20.2738M10.0522 26.2266H4.94142V21.004"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
