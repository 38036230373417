import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Tooltip, styled } from '@mui/material';
import { CommonConstants, ScenePageConstants } from '@root/utils/constants';
import { InformationIcon } from '@root/assets/icons/InformationIcon';
import { useScene } from '@root/context/ScenePageContext/useScene';
import { FormWrapper } from '@root/components/FormWrapper';
import ExpandMenuButton from '@root/components/ExpandMenuButton';
import FormCheckbox from '@root/components/FormCheckbox';
import PromptSection from '@root/components/PromptSection';
import { SceneMode } from '@root/utils/constants/enums';

const SceneForm = (): React.JSX.Element => {
    const {
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        seed,
        setSeed,
        enableHighResolution,
        setEnableHighResolution,
        generatePanoramaImage,
        activeMode,
    } = useScene();

    const [isActive, setIsActive] = useState<boolean>(true);

    const submitAction = (event: React.FormEvent): void => {
        event.preventDefault();
        generatePanoramaImage();
    };

    const menuButtonAction = (): void => {
        setIsActive(!isActive);
    };

    const onNegativePromptChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setNegativePrompt(event.target.value);
    };

    const onPromptChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPrompt(event.target.value);
    };

    const onSeedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!Number.isInteger(+event.target.value)) {
            setSeed(CommonConstants.DEFAULT_SEED_VALUE);
        } else {
            setSeed(+event.target.value);
        }
    };

    const onInputSeed = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Backspace' && isNaN(+(event.target as HTMLInputElement).value)) {
            setSeed('');
        }
    };

    const onResolutionFieldChange = (isChecked: boolean): void => {
        setEnableHighResolution(isChecked);
    };

    useEffect(() => {
        setIsActive(activeMode !== SceneMode.ERASE);
    }, [activeMode]);

    return (
        <Wrapper>
            <FormWrapper
                id="sceneForm"
                component="form"
                active={isActive ? 1 : 0}
                onSubmit={submitAction}
            >
                <Content>
                    {isActive && (
                        <PromptSection
                            prompt={prompt}
                            onPromptInputChange={onPromptChange}
                            negativePrompt={negativePrompt}
                            onNegativePromptInputChange={onNegativePromptChange}
                            seed={seed.toString()}
                            onSeedInputChange={onSeedChange}
                            onInputSeed={onInputSeed}
                            isNegativePromptRequired={false}
                            isPromptRequired={false}
                            promptTooltipText={'Enter prompt that describe your request for the 360 degree environment panorama'}
                        ></PromptSection>
                    )}
                    <AdditionalSettingSection>
                        <FormCheckbox
                            id={'highResolutionField'}
                            label={'high resolution'}
                            checked={enableHighResolution}
                            disabled={false}
                            onChange={onResolutionFieldChange}
                        />
                        <CheckboxTooltip
                            title={`Generate a file of a larger dimensions and higher quality,
                            ${ScenePageConstants.HIGH_RES_PANORAMA_WIDTH} x ${ScenePageConstants.HIGH_RES_PANORAMA_HEIGHT} px`}
                        >
                            <span>
                                <TooltipIcon></TooltipIcon>
                            </span>
                        </CheckboxTooltip>
                    </AdditionalSettingSection>
                    <GenerateButton
                        variant="contained"
                        type="submit"
                        disabled={activeMode == SceneMode.PAN && !seed}
                    >
                        {'Generate 360°'}
                    </GenerateButton>
                </Content>
            </FormWrapper>
            <ExpandMenuButton
                isDisabled={activeMode === SceneMode.ERASE}
                isActive={isActive}
                buttonAction={menuButtonAction}
            />
        </Wrapper>
    );
};

export default SceneForm;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    maxWidth: '394px',
    opacity: 1,
}));

const Content = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: '30px',

    overflowY: 'auto',
    overflowX: 'hidden',
    gap: '17px',
}));

const GenerateButton = styled(Button)(() => ({
    width: '300px',
    transition: 'all 0.5s',
}));

const AdditionalSettingSection = styled(Stack)(() => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingRight: '14px',
    height: '52px',
    width: '300px',
}));

const CheckboxTooltip = styled(Tooltip)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const TooltipIcon = styled(InformationIcon)(({ theme }) => ({
    width: '14px',
    height: '14px',
    color: theme.palette.primary.main,
}));
