import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FileUploadIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 40"
            {...props}
        >
            <path
                d="M24.515 1.87552L24.515 1.87554L30.6959 10.4866L31.224 10.1076L30.696 10.4866C31.0546 10.9862 31.2474 11.5858 31.2474 12.2008V34.359C31.2474 37.1155 29.0129 39.35 26.2564 39.35H5.74359C2.98713 39.35 0.75257 37.1155 0.75257 34.359V5.64103C0.75257 2.88456 2.98713 0.65 5.74359 0.65H22.1268C23.0739 0.65 23.9629 1.1062 24.515 1.87552Z"
                stroke="currentColor"
                strokeWidth="1.3"
                fill="none"
            />
            <path
                d="M16 26.1533C16 25.1397 16 20.0296 16 15.3841M16 15.3841L11.3846 19.8185M16 15.3841L20.6154 19.8185"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.6153 1.53857V12.8463C20.6153 13.3985 21.063 13.8463 21.6153 13.8463H30.6153"
                stroke="currentColor"
                strokeWidth="1.3"
                fill="none"
            />
        </SvgIcon>
    );
};
