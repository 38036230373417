export enum HotKeysList {
    CTRL_G = 1,
    CTRL_H,
    CTRL_B,
    CTRL_S,
    CTRL_L,
    CTRL_Z,
    CTRL_Y,
    CTRL_ARROW_DOWN,
    CTRL_I,
    CTRL_D,
}

type HotKeyDetails = {
    key: string;
    withCtrl?: boolean;
    withShift?: boolean;
    withAlt?: boolean;
    blockDefaultEvent?: boolean;
};

type HotKeysInfo = {
    [key in HotKeysList]: HotKeyDetails;
};

export const SupportedHotKeys: HotKeysInfo = {
    [HotKeysList.CTRL_G]: {
        key: 'g',
        withCtrl: true,
        blockDefaultEvent: true,
    },
    [HotKeysList.CTRL_H]: {
        key: 'h',
        withCtrl: true,
        blockDefaultEvent: true,
    },
    [HotKeysList.CTRL_B]: {
        key: 'b',
        withCtrl: true,
    },
    [HotKeysList.CTRL_S]: {
        key: 's',
        withCtrl: true,
        blockDefaultEvent: true,
    },
    [HotKeysList.CTRL_L]: {
        key: 'l',
        withCtrl: true,
        blockDefaultEvent: true,
    },
    [HotKeysList.CTRL_Z]: {
        key: 'z',
        withCtrl: true,
    },
    [HotKeysList.CTRL_Y]: {
        key: 'y',
        withCtrl: true,
    },
    [HotKeysList.CTRL_ARROW_DOWN]: {
        key: 'arrowdown',
        withCtrl: true,
    },
    [HotKeysList.CTRL_I]: {
        key: 'i',
        withCtrl: true,
    },
    [HotKeysList.CTRL_D]: {
        key: 'd',
        withCtrl: true,
        blockDefaultEvent: true,
    },
};
