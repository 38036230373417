import React, { useContext } from 'react';
import { Box } from '@mui/material';

import LoaderContext from '../context/LoaderContext/LoaderContext';

const Loader = () => {
    const { loaderState } = useContext(LoaderContext);

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
                display: loaderState ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#00000087',
                zIndex: '100',
            }}
        >
            <span className="loader"></span>
        </Box>
    );
};

export default Loader;
