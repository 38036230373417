import React, { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Box, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePickerFieldProps } from './DatePickerField.types';
import { UsersListDefaults } from '@root/utils/constants';

const DatePickerField = ({ isDisabled, date, setDate, error, setDateError }: DatePickerFieldProps): JSX.Element => {
    const [value, setValue] = React.useState<Dayjs | null>(date ? dayjs(date) : null);

    useEffect(() => {
        if (!value) {
            setDate(null);
            setError(false);
        } else {
            if (value.isValid()) {
                setDate(value.format(UsersListDefaults.DEFAULT_DATE_FORMAT));
                setError(false);
            } else {
                setError(true);
            }
        }
    }, [value]);

    const setError = (value: boolean): void => {
        if (setDateError) {
            setDateError(value);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerWrapper>
                <DatePicker
                    disabled={isDisabled}
                    format={UsersListDefaults.DEFAULT_DATE_LABEL_FORMAT}
                    disablePast
                    value={value}
                    onChange={(newValue: any) => setValue(newValue)}
                    slotProps={{
                        textField: {
                            error: !!error,
                        },
                    }}
                />
            </DatePickerWrapper>
        </LocalizationProvider>
    );
};

export default DatePickerField;

const DatePickerWrapper = styled(Box)(({ theme }) => ({
    minHeight: '30px',

    '& .MuiOutlinedInput-root': {
        fontSize: '16px',
        fontFamily: 'Roboto400',

        minHeight: '30px',
        width: '100%',

        padding: '5px 11px',
        borderRadius: '6px',

        borderColor: 'transparent',
        color: theme.palette.neutrals.contrastText,

        fieldset: {
            borderColor: theme.palette.primary.light1,
        },

        '&:hover fieldset': {
            borderColor: theme.palette.primary.light1,
        },

        '& svg': {
            color: theme.palette.neutrals.contrastText,
            fill: theme.palette.neutrals.contrastText,
        },
    },

    '& .MuiPaper-root': {
        backgroundColor: theme.palette.primary.light1,
    },

    '& .MuiOutlinedInput-root.Mui-disabled': {
        borderColor: 'transparent',
        pointerEvents: 'none',

        fieldset: {
            borderColor: theme.palette.primary.light1,
        },

        '& input': {
            WebkitTextFillColor: theme.palette.primary.light1,
        },

        '& svg': {
            fill: theme.palette.primary.light1,
        },
    },

    '& input': {
        padding: '0',
    },
}));
