import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack, TextField, styled } from '@mui/material';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { prefixBaseUrl } from '@root/utils/url/url';
import { ScenePageConstants } from '@root/utils/constants';
import { ImageGenerationStatus } from '@root/utils/constants/enums';
import ListRowDragButton from '@root/components/SortableListHelpers/ListRowDragButton';
import InfoTagField from '@root/components/InfoTagField';
import { SceneImagesListRowProps } from './SceneImagesListRow.types';

const SceneImagesListRow = ({
    image,
    isSelected,
    setFocus,
    selectRowImage,
    showMetadataDialog,
    onDelete,
    onRename,
    orderIndex,
}: SceneImagesListRowProps): React.JSX.Element => {
    const [customName, setCustomName] = useState<string>('');
    const [initialName, setInitialName] = useState<string>('');
    const [path, setPath] = useState<string>('');

    const openMetadataDialog = (event: React.MouseEvent<HTMLImageElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        if (event.detail === 2) {
            showMetadataDialog(image.id);
        }
    };

    const selectRow = (): void => {
        selectRowImage(image);
        setFocus(true);
    };

    const deleteByClick = (event: React.MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        onDelete(image.id);
    };

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setCustomName(event.target.value);
    };

    const blurHandler = (): void => {
        if (customName !== initialName) {
            onRename(image.id, customName);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === 'Enter') {
            (event.target as HTMLElement).blur();
        }
    };

    const updateImageName = (): void => {
        let imageName = image.name;

        if (imageName === ScenePageConstants.DEFAULT_GENERATED_IMAGE_NAME) {
            imageName = `${imageName} ${image.orderIndex}`;
        }

        setInitialName(imageName);
        setCustomName(imageName);
    };

    useEffect(() => {
        updateImageName();
    }, []);

    useEffect(() => {
        if (orderIndex) {
            updateImageName();
        }
    }, [orderIndex]);

    useEffect(() => {
        const path = prefixBaseUrl(image.previewFilePath ? image.previewFilePath : image.filePanoramaPath);

        setPath(path);
    }, [image]);

    return (
        <Wrapper
            onClick={selectRow}
            selected={isSelected}
        >
            <ListRowDragButton />
            <PreviewSection onClick={openMetadataDialog}>
                {image.fileHdrPath && image.statusHdrId === ImageGenerationStatus.READY && (
                    <PreviewInfo>
                        <InfoTagField
                            type="default"
                            label={'HDR'}
                        />
                    </PreviewInfo>
                )}
                <StyledImage
                    loading="lazy"
                    src={path}
                />
            </PreviewSection>
            <ListInfo>
                <CustomInput
                    id={image.id.toString()}
                    multiline={false}
                    variant="outlined"
                    onChange={onChangeHandler}
                    value={customName}
                    onBlur={blurHandler}
                    onKeyDown={handleKeyDown}
                />
                <Stack direction="row">
                    <RowButton onClick={deleteByClick}>
                        <DeleteImageIcon />
                    </RowButton>
                </Stack>
            </ListInfo>
        </Wrapper>
    );
};

export default React.memo(SceneImagesListRow);

const Wrapper = styled(Box)<{ selected: boolean }>(({ theme, selected }) => ({
    direction: 'ltr',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '64px',

    padding: '7px 29px 7px 7px',
    border: '1px solid',

    '&:hover button': {
        opacity: 1,
    },

    background: selected ? theme.palette.primary.light : theme.palette.neutrals.main,
    borderColor: 'transparent',

    '&:hover': {
        background: theme.palette.primary.light,
    },
}));

const StyledImage = styled('img')(({ theme }) => ({
    width: '76px',
    height: '54px',

    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.primary.light}`,

    '&:hover': {
        border: `1px solid ${theme.palette.neutrals.inactive1}`,
    },
}));

const ListInfo = styled(Box)(() => ({
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '10px',
}));

const PreviewSection = styled(Box)(() => ({
    display: 'flex',
    position: 'relative',
}));

const PreviewInfo = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 2,
    top: 2,
}));

const CustomInput = styled(TextField)(({ theme }) => ({
    '& input': {
        padding: '0',
        paddingLeft: '5px',
        color: theme.palette.neutrals.inactive1,
    },

    '& .MuiOutlinedInput-root': {
        width: '100px',
        minHeight: '25px',

        borderRadius: '0px',
        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '16px',

        fieldset: {
            borderColor: 'transparent',
        },

        '&:hover fieldset': {
            borderColor: theme.palette.neutrals.inactive1,
        },

        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light1,
            borderWidth: '1px',
        },

        '&.Mui-focused input': {
            color: theme.palette.primary.contrastText,
        },
    },
}));

const RowButton = styled(IconButton)(({ theme }) => ({
    width: '24px',
    height: '24px',

    opacity: 0,
    color: theme.palette.neutrals.inactive1,

    '& svg': {
        fontSize: '16px',
    },

    '&:hover': {
        background: 'transparent',
        color: theme.palette.error.main,
    },
}));
