import React from 'react';
import { IconButton, Tooltip, styled } from '@mui/material';
import { ToolbarButtonProps } from './ToolbarButton.types';

const ToolbarButton = ({
    children,
    tooltipText,
    clickEvent,
    value,
    isActive = false,
    isDisabled = false,
    className,
}: ToolbarButtonProps): React.JSX.Element => {
    return (
        <Tooltip title={tooltipText}>
            <span>
                <ToolButton
                    value={value}
                    className={className || ''}
                    disableRipple
                    active={isActive}
                    disabled={isDisabled}
                    onClick={clickEvent}
                >
                    {children}
                </ToolButton>
            </span>
        </Tooltip>
    );
};

export default ToolbarButton;

const ToolButton = styled(IconButton)<{ active: boolean }>(({ theme, active }) => ({
    width: '32px',
    height: '32px',
    color: `${active ? theme.palette.primary.main : 'inherit'}`,

    ':not(.action-button)': {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));
