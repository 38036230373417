import React from 'react';
import FullSizeImageView from '@root/pages/CuratePage/components/FullSizeImageView';
import { FullScreenSectionProps } from './FullScreenSection.types';

const FullScreenSection = ({
    isFullScreenActive,
    setIsFullScreenActive,
    moveToNextImage,
    moveToPreviousImage,
    activeImageUrl,
}: FullScreenSectionProps): React.JSX.Element => {
    const closeFullScreen = (): void => {
        setIsFullScreenActive(false);
    };

    return (
        <FullSizeImageView
            active={isFullScreenActive}
            imageUrl={activeImageUrl}
            handleClose={closeFullScreen}
            moveToNext={moveToNextImage}
            moveToPrevious={moveToPreviousImage}
        />
    );
};

export default FullScreenSection;
