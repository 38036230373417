import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CameraViewRotateIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}
        >
            <mask
                id="mask0_339_32"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
            >
                <rect
                    x="0.79715"
                    y="0.469849"
                    width="24"
                    height="24"
                    fill="#currentColor"
                />
            </mask>
            <g mask="url(#mask0_339_32)">
                <path
                    d="M9.79715 20.4698L8.39715 19.0698L10.1471 17.2698C8.01382 16.9865 6.25548 16.4032 4.87215 15.5198C3.48882 14.6365 2.79715 13.6198 2.79715 12.4698C2.79715 11.0865 3.75965 9.90735 5.68465 8.93235C7.60965 7.95735 9.98048 7.46985 12.7971 7.46985C15.6138 7.46985 17.9847 7.95735 19.9097 8.93235C21.8347 9.90735 22.7971 11.0865 22.7971 12.4698C22.7971 13.5032 22.243 14.4282 21.1346 15.2448C20.0263 16.0615 18.5805 16.6698 16.7971 17.0698V15.0198C18.0805 14.6865 19.068 14.274 19.7596 13.7823C20.4513 13.2907 20.7971 12.8532 20.7971 12.4698C20.7971 11.9365 20.0846 11.3032 18.6596 10.5698C17.2346 9.83652 15.2805 9.46985 12.7971 9.46985C10.3138 9.46985 8.35965 9.83652 6.93465 10.5698C5.50965 11.3032 4.79715 11.9365 4.79715 12.4698C4.79715 12.8698 5.22215 13.349 6.07215 13.9073C6.92215 14.4657 8.13048 14.8865 9.69715 15.1698L8.39715 13.8698L9.79715 12.4698L13.7971 16.4698L9.79715 20.4698Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
};
