import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
export const DragImageIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            {...props}
        >
            <circle
                cx="10"
                cy="8"
                r="1"
                fill="#A0A0A1"
            />
            <circle
                cx="14"
                cy="8"
                r="1"
                fill="#A0A0A1"
            />
            <circle
                cx="14"
                cy="12"
                r="1"
                fill="#A0A0A1"
            />
            <circle
                cx="10"
                cy="12"
                r="1"
                fill="#A0A0A1"
            />
            <circle
                cx="10"
                cy="16"
                r="1"
                fill="#A0A0A1"
            />
            <circle
                cx="14"
                cy="16"
                r="1"
                fill="#A0A0A1"
            />
        </SvgIcon>
    );
};
