import React, { useEffect } from 'react';
import { Box, styled, Modal, IconButton } from '@mui/material';
import { FullSizeImageViewProps } from './FullSizeImageView.types';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { FullScreenCloseIcon } from '@root/assets/icons/FullScreenCloseIcon';
import { CommonConstants } from '@root/utils/constants';

const FullSizeImageView = ({
    active,
    handleClose,
    imageUrl,
    children,
    sketchImageUrl,
    moveToNext,
    moveToPrevious,
}: FullSizeImageViewProps): React.JSX.Element => {
    const arrowUpPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.UP);
    const arrowDownPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.DOWN);
    const arrowLeftPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.LEFT);
    const arrowRightPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.RIGHT);
    const escapePressed = useKeyPress(CommonConstants.DEFAULT_EXIT_BUTTON);

    useEffect(() => {
        if (active && (arrowDownPressed || arrowRightPressed)) {
            moveToNext();
        }
    }, [arrowDownPressed, arrowRightPressed]);

    useEffect(() => {
        if (active && (arrowUpPressed || arrowLeftPressed)) {
            moveToPrevious();
        }
    }, [arrowUpPressed, arrowLeftPressed]);

    useEffect(() => {
        if (active) {
            handleClose();
        }
    }, [escapePressed]);

    return (
        <div>
            <Modal
                open={active}
                onClose={handleClose}
            >
                <Overlay>
                    <OverlayImageContainer>
                        <OverlayImageWithToolbar small={!!imageUrl}>
                            <Toolbar>
                                <ToolbarIcon
                                    disableRipple
                                    onClick={handleClose}
                                >
                                    <FullScreenCloseIcon />
                                </ToolbarIcon>
                            </Toolbar>
                            {children && children}

                            {!children && imageUrl && (
                                <>
                                    {sketchImageUrl ? (
                                        <OverlayImage
                                            src={sketchImageUrl}
                                            backgroundImageUrl={imageUrl}
                                        ></OverlayImage>
                                    ) : (
                                        <OverlayImage src={imageUrl}></OverlayImage>
                                    )}
                                </>
                            )}
                        </OverlayImageWithToolbar>
                    </OverlayImageContainer>
                </Overlay>
            </Modal>
        </div>
    );
};

export default FullSizeImageView;

const Overlay = styled(Box)(({ theme }) => ({
    display: 'flex',
    position: 'fixed',
    justifyContent: 'center',
    alignItems: 'center',

    top: '0',
    left: '0',

    width: '100%',
    height: '100%',

    backgroundColor: theme.palette.neutrals.main,
    zIndex: '1000',
    overflow: 'auto',
}));

const OverlayImageContainer = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
}));

const OverlayImageWithToolbar = styled(Box)<{ small: boolean }>(({ small }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    flex: 1,

    ...(small && {
        maxWidth: 'fit-content',
        maxHeight: 'fit-content',
    }),
}));

const OverlayImage = styled('img')<{ backgroundImageUrl?: string }>(({ backgroundImageUrl }) => ({
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',

    ...(backgroundImageUrl && { background: `no-repeat url(${backgroundImageUrl})` }),
    ...(backgroundImageUrl && { backgroundSize: 'contain' }),
}));

const Toolbar = styled(Box)(() => ({
    display: 'flex',
    position: 'absolute',

    right: '22px',
    top: '22px',

    overflow: 'hidden',
    opacity: '1',

    zIndex: 10,
}));

const ToolbarIcon = styled(IconButton)(({ theme }) => ({
    width: '50px',
    height: '50px',

    color: theme.palette.primary.contrastText,
    backgroundColor: 'rgba(66, 66, 66, 0.8)',
    borderRadius: '15px',

    '& svg': {
        width: '32px',
        height: '32px',
    },
}));
