import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { StatusSection } from '@pages/MainPage/components/StatusPanel/StatusSection';
import { JobCompletedIcon } from '@assets/icons/JobCompletedIcon';
import { useJobs } from '@hooks/jobs/UseJobs';
import { JobProcessingIcon } from '@assets/icons/JobProcessingIcon';
import { JobEnqueuedIcon } from '@assets/icons/JobEnqueuedIcon';
import { JobErrorIcon } from '@assets/icons/JobErrorIcon';

interface StatusPanelProps {
    className?: string;
}

export const StatusPanel = ({ className }: StatusPanelProps): React.JSX.Element => {
    const { completed, processing, enqueued, error, hasJob } = useJobs();

    return (
        <Wrapper className={className}>
            {!hasJob ? (
                <StatusPanelLabel>{'Status of your tasks will be displayed here'}</StatusPanelLabel>
            ) : (
                <>
                    <StatusSection
                        icon={<StyledInProgressingIcon />}
                        title={'In Progress'}
                        tasks={processing}
                    />
                    <StatusSection
                        icon={<StyledInQueueIcon />}
                        title={'In Queue'}
                        tasks={enqueued}
                        inactiveSection={true}
                        isQueueSection={true}
                    />
                    <StatusSection
                        icon={<StyledErrorIcon />}
                        title={'Error'}
                        tasks={error}
                        inactiveSection={true}
                    />
                    <StatusSection
                        icon={<StyledCompletedStatusIcon />}
                        title={'Completed'}
                        tasks={completed}
                    />
                </>
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    width: '223px',
    height: '100%',
    gap: theme.spacing(4),

    background: theme.palette.neutrals.main,
    borderRadius: '5px',
    paddingRight: '10px',
    overflow: 'auto',
}));

const StyledCompletedStatusIcon = styled(JobCompletedIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const StyledInProgressingIcon = styled(JobProcessingIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const StyledInQueueIcon = styled(JobEnqueuedIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const StyledErrorIcon = styled(JobErrorIcon)(({ theme }) => ({
    color: theme.palette.error.main,
}));

const StatusPanelLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    fontSize: '16px',
    lineHeight: '22px',

    color: `${theme.palette.primary.light1} !important`,
    margin: 'auto',
    textAlign: 'center',
}));
