import React, { PointerEventHandler, useEffect, useState, WheelEventHandler } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { clamp } from 'lodash';

import { LensSliderSVG } from '@root/assets/icons/LensSliderSVG';
import { LensMarksSVG } from '@root/assets/icons/LensMarksSVG';
import { SliderMarkerSVG } from '@root/assets/icons/SliderMarkerSVG';
import { LENS_RANGE } from '@root/utils/constants/SceneModeConstants';
import { useSceneModeCurate } from '@root/context/SceneModeCurateContext/useSceneModeCurate';

const LensSlider = () => {
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [startPosition, setStartPosition] = useState<number>(0);

    const [isSliderInUse, setIsSliderInUse] = useState<boolean>(false);

    const { lensValue, setLens } = useSceneModeCurate();

    const handleSliderChange: PointerEventHandler<HTMLDivElement> = (e) => {
        if (isSliderInUse) {
            let targetValue = lensValue;

            if (startPosition - e.screenX > 7) {
                targetValue = clamp(lensValue + 1, LENS_RANGE.min, LENS_RANGE.max);
                setStartPosition(e.screenX);
            } else if (startPosition - e.screenX < -7) {
                targetValue = clamp(lensValue - 1, LENS_RANGE.min, LENS_RANGE.max);

                setStartPosition(e.screenX);
            } else {
                return;
            }

            const offset = (targetValue - 4) * 7;
            setLens(targetValue);
            setSliderValue(offset);
        }
    };

    const startSlide: PointerEventHandler<HTMLDivElement> = (e) => {
        setIsSliderInUse(true);
        setStartPosition(e.screenX);
    };

    const stopSlide = (e: PointerEvent) => {
        setIsSliderInUse(false);
    };

    const onScroll: WheelEventHandler<HTMLDivElement> = (e) => {
        let targetValue;
        if (e.deltaX > 0) {
            targetValue = lensValue + 1;
        } else if (e.deltaX < 0) {
            targetValue = lensValue - 1;
        } else {
            return;
        }

        const value = clamp(targetValue, LENS_RANGE.min, LENS_RANGE.max);

        const offset = (value - 4) * 7;
        setLens(value);
        setSliderValue(offset);
    };

    useEffect(() => {
        window.addEventListener('pointerup', stopSlide);
        return () => window.removeEventListener('pointerup', stopSlide);
    }, []);

    useEffect(() => {
        const offset = (lensValue - 4) * 7;

        if (offset !== sliderValue) {
            setSliderValue(offset);
        }
    }, [lensValue]);

    return (
        <Container>
            <SliderWrapper
                onPointerDown={startSlide}
                onPointerMove={handleSliderChange}
                onWheel={onScroll}
            >
                <MarkerWrapper>
                    <SliderMarkerSVG />
                </MarkerWrapper>

                <LensValueText>
                    {lensValue}
                    <span>mm</span>
                </LensValueText>
                <StyledSlider offset={sliderValue}>
                    <LensMarksSVG />
                    <LensSliderSVG />
                </StyledSlider>
            </SliderWrapper>
        </Container>
    );
};

export default LensSlider;

const Container = styled(Box)(() => ({
    width: '100%',
    height: '80px',

    display: 'flex',
    justifyContent: 'center',
}));

const SliderWrapper = styled(Box)(() => ({
    position: 'relative',

    height: '80px',
    width: '80%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    maskImage: ' -webkit-linear-gradient(left, rgba(196, 196, 196, 0) 20%, #FFFFFF 50%, rgba(196, 196, 196, 0) 80%)',
    cursor: 'pointer',
}));

const StyledSlider = styled(Box)<{ offset: number }>(({ offset }) => ({
    position: 'absolute',

    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px',

    transform: `translateX(calc(50% - ${offset}px))`,

    transition: 'all 0.3s',
}));

const MarkerWrapper = styled(Box)(() => ({
    position: 'relative',
    zIndex: 2,
    display: 'flex',
}));

const LensValueText = styled(Typography)(() => ({
    color: 'white',
    position: 'absolute',
    zIndex: 3,
    top: -3,
    fontSize: '28px',
    fontWeight: '500',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    userSelect: 'none' /* Standard */,
    '& span': {
        fontSize: '16px',
        fontWeight: '400',
    },
}));
