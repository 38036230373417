import React from 'react';
import { styled, Box, Tooltip } from '@mui/material';
import FormCheckbox from '../FormCheckbox';
import { PreciseModeFieldTypes } from './PreciseModeField.types';
import { InformationIcon } from '@root/assets/icons/InformationIcon';

const PreciseModeField = ({ enablePreciseMode, setEnablePreciseMode }: PreciseModeFieldTypes): React.JSX.Element => {
    const onPreciseModeChange = (isChecked: boolean) => {
        setEnablePreciseMode(isChecked);
    };

    return (
        <Wrapper>
            <FormCheckbox
                id={'preciseMode'}
                label={'precise mode'}
                checked={!!enablePreciseMode}
                onChange={onPreciseModeChange}
            />
            <CheckboxTooltip title={'Precise mode follows reference image closer, transfering more details and color.'}>
                <span>
                    <TooltipIcon></TooltipIcon>
                </span>
            </CheckboxTooltip>
        </Wrapper>
    );
};

export default PreciseModeField;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}));

const CheckboxTooltip = styled(Tooltip)(() => ({
    width: '14px',
    height: '14px ',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    boxSizing: 'border-box',
}));

const TooltipIcon = styled(InformationIcon)(({ theme }) => ({
    width: '14px',
    height: '14px',
    color: theme.palette.primary.main,
}));
