import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';
import { useQuickAction } from '@root/context/QuickActionContext/useQuickAction';

export interface ViewAllButtonProps {
    fullPage: string;
}

const ViewAllButton = ({ fullPage }: ViewAllButtonProps): React.JSX.Element => {
    const { externalCloseAction } = useQuickAction();

    return (
        <ButtonContainer
            className="opacity"
            component={NavLink}
            onClick={externalCloseAction}
            to={fullPage}
        >
            <ButtonLabel variant="body14"> {'View all'}</ButtonLabel>
            <StyledArrowForward />
        </ButtonContainer>
    );
};

export default ViewAllButton;

const ButtonContainer = styled(Box)<{ to: string }>(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    gap: '4px',
    textDecoration: 'none',
    width: '80px',
}));

const ButtonLabel = styled(Typography)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.primary.main}`,
}));

const StyledArrowForward = styled(ArrowForward)(() => ({
    fontSize: '14px',
    paddingTop: '2px',
}));
