import { Box, IconButton, styled } from '@mui/material';

export const MetadataSectionWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    outline: 'none !important',
    position: 'relative',

    maxHeight: '80%',
    height: '100%',
    width: '90%',

    borderRadius: '15px',
    background: theme.palette.primary.light,
}));

export const MetadataLeftPanel = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',
    width: '80%',
    height: '100%',

    padding: '30px 60px 30px 30px',
    gap: theme.spacing(2.4),

    '& .react-transform-wrapper': {
        width: '100%',
        height: '100%',
        borderRadius: '15px',
    },

    '& .react-transform-component': {
        width: '100%',
        height: '100%',
        borderRadius: '15px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        objectFit: 'contain',

        borderRadius: '15px',
        filter: 'url("#BorderRadiusFilter")',
    },
}));

export const MetadataRightPanel = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    width: '20%',
    height: '100%',

    gap: theme.spacing(6),
    paddingTop: '30px',
    paddingRight: '64px',
}));

export const MetadataInfoWrapper = styled(Box)(() => ({
    height: 'calc(100% - 50px)',
    overflow: 'auto',

    display: 'flex',
    flexDirection: 'column',

    gap: '20px',
}));

export const MetadataImageBox = styled(Box)(() => ({
    width: '100%',
    height: '100%',
}));

export const MetadataTools = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    gap: '14px',
}));

export const MetadataToolButton = styled(IconButton)(() => ({
    width: '24px',
    height: '24px',
}));
