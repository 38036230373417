import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExpandMenuIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 24"
            {...props}
        >
            <path
                d="M17.5 10L12.5 15L7.5 10"
                stroke="#686868"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill={'none'}
            />
        </SvgIcon>
    );
};
