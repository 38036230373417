import React from 'react';
import { SelectFieldProps } from './SelectField.types';
import { FormControl, MenuItem, Select, styled, Typography, Box } from '@mui/material';

const SelectField = ({
    id,
    name,
    value,
    label,
    onChange,
    isRequired,
    isDisabled,
    placeholder,
    isPrimary = true,
    content,
}: SelectFieldProps): React.JSX.Element => {
    return (
        <Wrapper primary={isPrimary}>
            {label && <InputLabel variant="body16">{label}</InputLabel>}
            <FormControlElement
                primary={isPrimary}
                required={isRequired}
                disabled={isDisabled}
            >
                <Select
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                >
                    {placeholder && isDisabled && (
                        <MenuItem
                            disabled
                            key={0}
                            value={0}
                        >
                            {placeholder}
                        </MenuItem>
                    )}
                    {content}
                </Select>
            </FormControlElement>
        </Wrapper>
    );
};

export default SelectField;

const Wrapper = styled(Box)<{ primary: boolean }>(({ primary }) => ({
    minHeight: primary ? '63px' : 'auto',
    width: primary ? '300px' : '100px',
    minWidth: primary ? '100px' : 'auto',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    marginBottom: '6px',
    color: theme.palette.primary.light1,
}));

const FormControlElement = styled(FormControl)<{ primary: boolean }>(({ theme, primary }) => ({
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    minHeight: primary ? '40px' : '30px',

    '& .MuiOutlinedInput-root': {
        width: primary ? '300px' : '100px',
        minHeight: primary ? '40px' : '30px',

        fontSize: '14px',
        fontFamily: 'Roboto400',
    },

    '& .MuiSelect-select': {
        padding: '0',
        paddingLeft: '13px',
        paddingRight: '14px',
        paddingTop: primary ? '10px' : '6px',
        paddingBottom: primary ? '10px' : '6px',
    },

    '& .MuiSelect-icon': {
        color: theme.palette.primary.light1,
    },
}));
