export enum LicenseStatus {
    VERIFIED = 1,
    FAILED = 2,
}

export const LicenseStatusLabels: { [index: number]: string } = {
    [LicenseStatus.VERIFIED]: 'Verified',
    [LicenseStatus.FAILED]: 'Failed',
};

export enum LicenseValidationErrorType {
    NO_INTERNET_CONNECTION = 1,
    EXPIRED = 2,
    LICENSE_NOT_FOUND = 3,
    PUBLIC_KEY_NOT_FOUND = 4,
}

export const LicenseValidationErrorLabels: { [index: number]: string } = {
    [LicenseValidationErrorType.NO_INTERNET_CONNECTION]: 'No internet connection',
    [LicenseValidationErrorType.EXPIRED]: 'License is expired',
    [LicenseValidationErrorType.LICENSE_NOT_FOUND]: 'License not found',
    [LicenseValidationErrorType.PUBLIC_KEY_NOT_FOUND]: 'Public key not found',
};

export enum LicenseType {
    ONLINE = 1,
    OFFLINE = 2,
}

export const LicenseTypeLabels: { [index: number]: string } = {
    [LicenseType.ONLINE]: 'Online',
    [LicenseType.OFFLINE]: 'Offline',
};

export enum ValidationTriggerType {
    AUTO = 1,
    MANUAL = 2,
}

export const ValidationTriggerTypeLabels: { [index: number]: string } = {
    [ValidationTriggerType.AUTO]: 'Auto',
    [ValidationTriggerType.MANUAL]: 'Manual',
};
