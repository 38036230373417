import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { sortByID } from '@utils/sortByID';

import ActiveModelContext from './ActiveModelContext';
import LoaderContext from '../LoaderContext/LoaderContext';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import AuthContext from '../AuthContext/AuthContext';
import { useModels } from '@hooks/model/UseModels';
import { useModelApi } from '@hooks/model/ModelApi';
import { useJobs } from '@hooks/jobs/UseJobs';
import { JobStatus } from '@utils/job/JobStatus';
import { ModelStatus, NavigationPaths } from '@root/utils/constants/enums';
import { ErrorMessages } from '@root/utils/constants';

const ActiveModelContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const { createFrontendJob, updateFrontendJob, updateJobStatus } = useJobs();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { createNotification, notificationMessages } = useNotifications();
    const { isSignedIn } = useContext(AuthContext);
    const { getModel, refreshModels } = useModels();
    const {
        getModelImages,
        uploadImages: uploadImagesApi,
        uploadImage: uploadImageApi,
        deleteTrainImage,
        updateModel: updateModelApi,
        updateLabel: updateLabelApi,
        trainModel,
        getModelPath: getModelPathApi,
        exportModel,
        generateOtp,
    } = useModelApi();

    const [activeModel, setActiveModel] = useState('');
    const [activeModelStatus, setActiveModelStatus] = useState(false);
    const [activeModelConfig, setActiveModelConfig] = useState({
        conceptClassPrompt: '',
        conceptClassToken: '',
        conceptInstanceToken: '',
        modelId: '',
        modelName: '',
        parentModelId: '',
        previewId: null,
    });
    const [activeModelImages, setActiveModelImages] = useState([]);
    const [exportingModelIds, setExportingModelIds] = useState([]);
    const [exportJobs, setExportJobs] = useState([]);
    const [exportedModelId, setExportedModelId] = useState(null);

    const [removedFiles, setRemovedFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [updatedLabels, setUpdatedLabels] = useState([]);
    const [isDraft, setIsDraft] = useState(false);
    const [isDefaultPage, setIsDefaultPage] = useState(false);
    const [exportModalState, setExportModalState] = useState(false);

    const getModelConfig = async (modelId) => {
        if (!modelId) {
            setActiveModel('');
            setActiveModelStatus(false);
        } else {
            const model = await getModel(modelId);
            if (model) {
                setActiveModel(model.model);
                setActiveModelStatus(model.statusId !== ModelStatus.Draft);
            }
        }
    };

    const handleSetActiveModelImages = async (modelId) => {
        if (!modelId) {
            setActiveModelImages([]);
            return;
        }
        if (modelId == activeModel.Id) {
            return;
        }
        showLoader();

        const data = undefined;
        const images = await getModelImages(modelId, data);

        if (images && !images.errorCode) {
            const sortedImages = sortByID(images);
            setActiveModelImages(sortedImages.concat([]).reverse());
        } else {
            setActiveModelImages([]);
        }
        hideLoader();
    };

    const uploadImages = async (formData) => {
        const images = await uploadImagesApi(formData);
        if (images) {
            const sortedImages = sortByID(images);
            setActiveModelImages(sortedImages.concat([]).reverse());
        } else {
            createNotification(notificationMessages.image.uploading.title, notificationMessages.image.uploading.error);
        }
    };

    const uploadImage = async (formData) => {
        return await uploadImageApi(formData);
    };

    const deleteImage = async (imageId) => {
        return await deleteTrainImage(imageId);
    };

    const validateModelName = (name) => {
        let errorMessage = '';

        if (!name || !name.trim()) {
            errorMessage = ErrorMessages.MODEL_ERROR_MESSAGE.NAME_IS_EMPTY;
        }

        return errorMessage;
    };

    const updateModel = async (predefinedConfig) => {
        const config = predefinedConfig || activeModelConfig;
        const result = await updateModelApi(config);
        return result;
    };

    const updateLabel = (label) => {
        updateLabelApi(label);
    };

    const handleTrainModel = async (id = activeModel.Id) => {
        showLoader();

        const result = await trainModel(id);

        if (result && !result.errorCode) {
            navigate(`/${NavigationPaths.ModelsList}`);
            refreshModels();
            updateJobStatus();
        } else {
            createNotification(notificationMessages.model.training.title, notificationMessages.model.training.error);
        }
        hideLoader();
    };

    const saveModel = (url) => {
        const element = document.createElement('a');
        element.href = `${window._env_.REACT_APP_BASE_URL}${url}`;
        element.click();
    };

    const getExportModelPath = async (modelId) => {
        return await getModelPathApi(modelId);
    };

    const getModelPathStatus = async () => {
        const modelPath = await getExportModelPath(activeModel.Id);
        return modelPath?.status;
    };

    const getModelPath = async (modelId) => {
        const result = await getExportModelPath(activeModel.Id);
        if (result) {
            saveModel(result);
            createNotification(notificationMessages.model.exported.title, null, 'browser');
            setExportingModelIds((prev) => prev.filter((el) => el !== modelId));
            setExportedModelId(modelId);
        } else {
            setTimeout(() => getModelPath(modelId), 3000);
        }
    };

    const generateOtpForModel = async () => {
        const result = await generateOtp(activeModel.Id);

        return result;
    };

    const exportActiveModel = async () => {
        setExportingModelIds((prev) => [...prev, activeModel.Id]);

        const status = await getModelPathStatus();

        let success = true;
        if (!status) {
            const result = await exportModel(activeModel.Id);
            success = result && !result.errorCode;

            if (success) {
                createNotification(notificationMessages.model.exporting.title, notificationMessages.model.exporting.info);
            }
        }

        const job = generateJob(activeModel, !success);
        const jobId = createFrontendJob(job);

        if (job.jobStatusId !== JobStatus.ERROR) {
            setExportJobs([
                ...exportJobs,
                {
                    jobId,
                    modelId: activeModel.Id,
                },
            ]);
        }

        await getModelPath(activeModel.Id);
    };

    const generateJob = (model, isErrorJob = false) => {
        const date = new Date().toUTCString();
        return {
            createdById: -1,
            dateCreated: date,
            dateFinished: null,
            id: -1,
            jobStatusId: isErrorJob ? JobStatus.ERROR : JobStatus.PROCESSING,
            name: `Export Model ${model.Name}`,
            percentage: null,
            queuePosition: null,
        };
    };

    useEffect(() => {
        if (!isSignedIn) {
            setActiveModel('');
            setActiveModelStatus(false);
            setActiveModelConfig((prev) => {
                return {
                    ...prev,
                    conceptClassPrompt: '',
                    conceptClassToken: '',
                    conceptInstanceToken: '',
                    modelId: '',
                    modelName: '',
                    parentModelId: '',
                };
            });
            setActiveModelImages([]);
            setExportingModelIds([]);
        }
    }, [isSignedIn]);

    useEffect(() => {
        if (exportedModelId === null) {
            return;
        }

        const jobId = exportJobs.find((job) => job.modelId === exportedModelId)?.jobId;
        if (jobId !== undefined) {
            updateFrontendJob(jobId, 100, JobStatus.FINISHED);
        }
    }, [exportedModelId]);

    useEffect(() => {
        setIsDraft(!!removedFiles.length || !!uploadedFiles.length || !!updatedLabels.length);
    }, [removedFiles, uploadedFiles, updatedLabels]);

    return (
        <ActiveModelContext.Provider
            value={{
                activeModel,
                getModelConfig,
                activeModelConfig,
                setActiveModelConfig,
                activeModelStatus,
                activeModelImages,
                setActiveModelImages,
                handleSetActiveModelImages,
                updateModel,
                handleTrainModel,
                uploadImages,
                uploadImage,
                deleteImage,
                exportActiveModel,
                exportingModelIds,
                generateOtpForModel,
                updateLabel,

                removedFiles,
                setRemovedFiles,
                uploadedFiles,
                setUploadedFiles,
                updatedLabels,
                setUpdatedLabels,
                isDraft,
                setIsDraft,
                isDefaultPage,
                setIsDefaultPage,
                exportModalState,
                setExportModalState,
                validateModelName,
            }}
        >
            {children}
        </ActiveModelContext.Provider>
    );
};

export default ActiveModelContextProvider;
