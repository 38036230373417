import React from 'react';
import { styled, IconButton } from '@mui/material';
import { BackButtonProps } from './BackButton.types';
import { ArrowBackIosRounded } from '@mui/icons-material';

const BackButton = ({ moveToPrevious, isDisabled = false }: BackButtonProps): React.JSX.Element => {
    return (
        <ImageControlButtonLeft
            disabled={isDisabled}
            onClick={moveToPrevious}
        >
            <ArrowBackIosRounded />
        </ImageControlButtonLeft>
    );
};

export default BackButton;

const ImageControlButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: '50%',

    '& svg': {
        width: '2rem',
        height: '2rem',
    },
}));

const ImageControlButtonLeft = styled(ImageControlButton)(() => ({
    left: '-60px',
}));
