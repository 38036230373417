import { LocalStorageKeys } from '../constants';

export const clearAuthStorage = () => {
    localStorage.removeItem(LocalStorageKeys.USERNAME_KEY);
    localStorage.removeItem(LocalStorageKeys.PROJECT_KEY);
    localStorage.removeItem(LocalStorageKeys.PROJECT_NAME_KEY);
    localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN_KEY);
    localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN_KEY);
    localStorage.removeItem(LocalStorageKeys.USER_ROLE_KEY);
};
