import { styled } from '@mui/material';

export const AuxiliaryCanvas = styled('canvas')<{
    display: number;
    isTransparent: boolean;
    loading?: boolean;
    animated: any;
}>(({ display, isTransparent, loading, animated }) => ({
    position: 'absolute',

    display: !display && 'none',
    touchAction: 'none',

    ...(loading && {
        pointerEvents: 'none',
    }),

    opacity: isTransparent ? 0.7 : 1,

    ...(animated && {
        animation: 'loading_animation 2s infinite',

        '@keyframes loading_animation': {
            '50%': {
                opacity: '0.2',
            },
        },
    }),
}));
