import { useRequest } from '@hooks/request/UseRequest';
import { LicenseValidationApiItems } from '@root/types/apiTypes';
import { SupportedRequestError } from '@root/types/commonTypes';
import { LicenseValidationLogDto, LicenseValidationStatusDto } from '@root/types/dto';

const LICENSE_VALIDATION_END_POINT = '/license';
const LICENSE_VALIDATION_LOGS_END_POINT = '/license-logs';

export const useLicenseValidationApi = (): LicenseValidationApiItems => {
    const { requestGet, requestPost, requestPostWithJson } = useRequest();

    const getLastValidationInfo = async (): Promise<LicenseValidationStatusDto | SupportedRequestError> => {
        const response = await requestGet(LICENSE_VALIDATION_END_POINT);
        return response?.data;
    };

    const getLicenseValidationLogs = async (
        pageSize: number,
        pageNumber: number
    ): Promise<{ items: LicenseValidationLogDto[]; totalCount: number } | SupportedRequestError> => {
        const data = {
            page: pageNumber,
            pageSize: pageSize,
        };
        const response = await requestPostWithJson(LICENSE_VALIDATION_LOGS_END_POINT, { data });
        return response?.data;
    };

    const triggerLicenseValidation = async (): Promise<LicenseValidationStatusDto | SupportedRequestError> => {
        const response = await requestPost(`${LICENSE_VALIDATION_END_POINT}/verify`);
        return response?.data;
    };

    return {
        getLastValidationInfo,
        getLicenseValidationLogs,
        triggerLicenseValidation,
    };
};
