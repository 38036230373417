import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { LicenseValidationLogDto, LicenseValidationStatusDto } from '@root/types/dto';
import { LicenseStatus, RequestErrorCode } from '@root/utils/constants/enums';
import { isResponseWithError } from '@root/utils/helpers';
import { useLicenseValidationApi } from '@root/hooks/api/useLicenseValidationApi';
import { LicenseContextItems } from '@root/types/contextTypes';

export const DEFAULT_LOG_PAGE_SIZE = 5;

const LicenseContext = createContext<LicenseContextItems | null>(null);

export const LicenseContextProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    const { getLastValidationInfo, getLicenseValidationLogs, triggerLicenseValidation } = useLicenseValidationApi();

    const [validationStatus, setValidationStatus] = useState<LicenseValidationStatusDto | null>(null);
    const [validationLogs, setValidationLogs] = useState<LicenseValidationLogDto[] | null>(null);
    const [logPageSize, setLogPageSize] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [totalLogCount, setTotalLogRowCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const checkLicenseStatus = async (): Promise<boolean> => {
        const result = await getLastValidationInfo();

        if (isResponseWithError(result) && result.errorCode === RequestErrorCode.LICENSE_NOT_FOUND) {
            setValidationStatus({
                licenseStatus: LicenseStatus.FAILED,
                licenseExpiration: null,
                licenseLastValidation: Date.now().toString(),
                licenseValidationAttempt: 0,
            });
            return false;
        }

        // If the validation has not yet passed,
        // or the request returned an error, the functionality should not be blocked
        if (!result || isResponseWithError(result)) {
            setValidationStatus(null);
            return true;
        } else {
            setValidationStatus(result);
            return result.licenseStatus === LicenseStatus.VERIFIED;
        }
    };

    const getLicenseValidationInfo = async (): Promise<void> => {
        setIsLoading(true);

        const pageSizeValue = logPageSize && logPageSize < 0 ? totalLogCount : logPageSize;

        const result = await getLicenseValidationLogs(pageSizeValue || DEFAULT_LOG_PAGE_SIZE, pageNumber + 1);

        if (isResponseWithError(result)) {
            setValidationLogs(null);
        } else {
            setValidationLogs(result.items);
            setTotalLogRowCount(result.totalCount);
        }

        setIsLoading(false);
    };

    const runLicenseValidation = async (): Promise<void> => {
        setIsLoading(true);
        const result = await triggerLicenseValidation();
        if (isResponseWithError(result)) {
            setIsLoading(false);
            return;
        }
        getLicenseValidationInfo();
    };

    useEffect(() => {
        if (!logPageSize) {
            return;
        }

        getLicenseValidationInfo();
    }, [logPageSize, pageNumber]);

    const contextValue = {
        totalLogCount,
        validationStatus,
        validationLogs,
        logPageSize,
        isLoading,
        pageNumber,
        setPageNumber,
        setLogPageSize,
        checkLicenseStatus,
        getLicenseValidationInfo,
        runLicenseValidation,
    };

    return <LicenseContext.Provider value={contextValue}>{children}</LicenseContext.Provider>;
};

export const useLicenseValidation = (): LicenseContextItems => {
    const context = useContext(LicenseContext);

    if (context === null) {
        throw new Error('useAppVerification cannot be used without its provider');
    }

    return context;
};
