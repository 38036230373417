import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useJobsApi } from '../api/useJobsApi';
import { Job, JobWithAction } from '@utils/job/Job';
import { JobStatus } from '@utils/job/JobStatus';
import _ from 'lodash';
import AuthContext from '@root/context/AuthContext/AuthContext';
import { SupportedRequestError } from '@root/types/commonTypes';

const AUTO_REFRESH_WAIT_TIME = 5000;
const DEBOUNCE_TIME = 1000;

const debounceUpdateStatus = _.debounce(
    async (getStatus: () => Promise<Job[] | SupportedRequestError>, onGetStatus: (jobs: Job[]) => void) => {
        const jobs = await getStatus();

        if (jobs && !(jobs as SupportedRequestError).errorCode) {
            onGetStatus(jobs as Job[]);
        }
    },
    DEBOUNCE_TIME
);

interface JobsContext {
    completed: JobWithAction[];
    processing: JobWithAction[];
    enqueued: JobWithAction[];
    error: JobWithAction[];
    updateJobStatus: () => Promise<void>;
    clearJobs: () => Promise<void>;
    hasJob: boolean;

    createFrontendJob: (job: Job | JobWithAction) => number;
    updateFrontendJob: (id: number, percentage: number | null, jobStatusId: JobStatus) => void;
}

const JobsContext = createContext<JobsContext | null>(null);

interface JobsContextProviderProps {
    children?: ReactNode;
}

export const JobsContextProvider = ({ children }: JobsContextProviderProps) => {
    const { getJobStatus, cancelJob, removeOldJobs } = useJobsApi();

    const [backendJobs, setBackendJobs] = useState<Job[]>([]);
    const [completed, setCompleted] = useState<JobWithAction[]>([]);
    const [processing, setProcessing] = useState<JobWithAction[]>([]);
    const [enqueued, setEnqueued] = useState<JobWithAction[]>([]);
    const [error, setError] = useState<JobWithAction[]>([]);
    const { isSignedIn } = useContext(AuthContext);
    const [frontendJobs, setFrontendJobs] = useState<Job[] | JobWithAction[]>([]);

    const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);

    const updateJobStatus = async () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        debounceUpdateStatus(getJobStatus, setBackendJobs);

        setTimeoutId(setTimeout(() => updateJobStatus(), AUTO_REFRESH_WAIT_TIME));
    };

    const clearJobs = async (): Promise<void> => {
        const result = await removeOldJobs();

        if ((result as number[])?.length) {
            updateJobStatus();
        }

        if (completed.length) {
            completed.forEach((job) => {
                if (job.id < 0 && job.cancel) {
                    job.cancel();
                }
            });
        }

        if (error.length) {
            error.forEach((job) => {
                if (job.id < 0 && job.cancel) {
                    job.cancel();
                }
            });
        }
    };

    const generateJobId = (): number => {
        let id = -1;
        while (frontendJobs.find((job) => job.id == id)) {
            id -= 1;
        }
        return id;
    };

    const createFrontendJob = (job: Job | JobWithAction): number => {
        const newId = generateJobId();

        const newJobWithValidId = {
            ...job,
            id: newId,
        };

        setFrontendJobs((jobs) => [...jobs, newJobWithValidId]);

        return newId;
    };

    const updateFrontendJob = (id: number, percentage: number | null, jobStatusId: JobStatus) => {
        setFrontendJobs((jobs) =>
            jobs.map((job) => ({
                ...job,
                percentage: job.id == id ? percentage : job.percentage,
                jobStatusId: job.id == id ? jobStatusId : job.jobStatusId,
            }))
        );
    };

    const generateCancelFrontEndJobHandler = (id: number) => () => {
        setFrontendJobs((jobs) => jobs.filter((job) => job.id != id));
    };

    const addBackendJobActions = (jobs: Job[]): JobWithAction[] => {
        return jobs.map(
            (job) =>
                ({
                    ...job,
                    cancel: async () => {
                        const result = await cancelJob(job.id);

                        if (result && !(result as SupportedRequestError).errorCode) {
                            setBackendJobs((jobs) => jobs.filter((jobItem) => jobItem.id != job.id));
                        }
                    },
                }) as JobWithAction
        );
    };

    const addFrontendJobActions = (jobs: Job[] | JobWithAction[]): JobWithAction[] => {
        return jobs.map(
            (job) =>
                ({
                    ...job,
                    ...(!(job as JobWithAction).cancel && {
                        cancel:
                            job.jobStatusId == JobStatus.FINISHED || job.jobStatusId == JobStatus.ERROR
                                ? generateCancelFrontEndJobHandler(job.id)
                                : null,
                    }),
                }) as JobWithAction
        );
    };

    useEffect(() => {
        isSignedIn && updateJobStatus();
    }, [isSignedIn]);

    useEffect(() => {
        if (timeoutId) {
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [timeoutId]);

    useEffect(() => {
        const jobsWithAction = addBackendJobActions(backendJobs);
        jobsWithAction.push(...addFrontendJobActions(frontendJobs));

        const completedJob = jobsWithAction.filter((job) => job.jobStatusId == JobStatus.FINISHED);
        const processingJob = jobsWithAction.filter((job) => job.jobStatusId == JobStatus.PROCESSING);
        const enqueuedJob = jobsWithAction
            .filter((job) => job.jobStatusId == JobStatus.ENQUEUED)
            .sort((job1, job2) => job1.queuePosition - job2.queuePosition)
            .map((job) => ({ ...job, percentage: 0 }));
        const errorJob = jobsWithAction.filter((job) => job.jobStatusId == JobStatus.ERROR);
        setCompleted(completedJob);
        setProcessing(processingJob);
        setEnqueued(enqueuedJob);
        setError(errorJob);

        if (processingJob.length + enqueuedJob.length == 0 && timeoutId) {
            // nothing to wait
            clearTimeout(timeoutId);
        }
    }, [backendJobs, frontendJobs]);

    const value = {
        completed,
        processing,
        enqueued,
        error,
        updateJobStatus,
        clearJobs,
        hasJob: backendJobs.length + frontendJobs.length > 0,

        createFrontendJob,
        updateFrontendJob,
    };

    return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
};

export const useJobs = (): JobsContext => {
    const ctx = useContext(JobsContext);

    if (!ctx) {
        throw new Error(`${useJobs.name} must be used within a ${JobsContextProvider.name}`);
    }

    return ctx;
};
