import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DeleteImageIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                d="M6 6V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V6"
                stroke="currentColor"
                strokeWidth="1.5"
                fill={'none'}
            />
            <path
                d="M9 6V6C9 4.34315 10.3431 3 12 3V3C13.6569 3 15 4.34315 15 6V6"
                stroke="currentColor"
                strokeWidth="1.5"
                fill={'none'}
            />
            <path
                d="M4 6L20 6"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                fill={'none'}
            />
            <path
                d="M14.5 10V16.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                fill={'none'}
            />
            <path
                d="M9.5 10V16.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                fill={'none'}
            />
        </SvgIcon>
    );
};
