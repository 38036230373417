import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SelectIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.4865 13.8839C24.9003 13.8637 25.252 14.1828 25.2722 14.5965C25.3761 16.7275 24.952 18.6305 24.038 20.5224C23.8053 21.0039 23.553 21.463 23.283 21.8977C25.2387 22.8383 26.8723 24.081 27.6446 25.0572C27.9016 25.3821 27.8466 25.8537 27.5217 26.1107C27.1969 26.3677 26.7252 26.3127 26.4682 25.9879C25.8528 25.2101 24.3298 24.03 22.4091 23.144C20.6592 25.3547 18.3934 26.6958 16.0087 26.8121C14.1121 26.9046 12.5901 25.9721 12.273 24.5124C11.9449 23.0022 13.0218 21.5654 15.0359 20.8814C17.2816 20.1187 19.7556 20.5105 21.8858 21.3023C22.1716 20.8597 22.4399 20.3819 22.6873 19.8698C23.4997 18.1885 23.8648 16.5338 23.774 14.6695C23.7538 14.2558 24.0728 13.9041 24.4865 13.8839ZM20.9478 22.5612C19.1177 21.9461 17.1878 21.7347 15.5183 22.3017C13.8814 22.8576 13.6397 23.7377 13.7388 24.1939C13.8488 24.7006 14.4999 25.3839 15.9357 25.3139C17.6733 25.2292 19.4619 24.286 20.9478 22.5612Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6528 5.2046C13.3317 5.0703 14.033 5 14.75 5C15.467 5 16.1683 5.0703 16.8472 5.2046C17.2535 5.28499 17.5177 5.67955 17.4374 6.08589C17.357 6.49223 16.9624 6.75647 16.5561 6.67608C15.9725 6.56064 15.3687 6.5 14.75 6.5C14.1313 6.5 13.5275 6.56064 12.9439 6.67608C12.5376 6.75647 12.143 6.49223 12.0626 6.08589C11.9823 5.67955 12.2465 5.28499 12.6528 5.2046ZM19.6829 7.01711C19.9134 6.67291 20.3792 6.58068 20.7234 6.81112C21.8954 7.59574 22.9043 8.60462 23.6889 9.7766C23.9193 10.1208 23.8271 10.5866 23.4829 10.8171C23.1387 11.0475 22.6729 10.9553 22.4424 10.6111C21.7669 9.60203 20.898 8.73311 19.8889 8.05757C19.5447 7.82714 19.4525 7.3613 19.6829 7.01711ZM9.81707 7.01711C10.0475 7.3613 9.95528 7.82714 9.61108 8.05757C8.60203 8.73311 7.73311 9.60203 7.05757 10.6111C6.82714 10.9553 6.3613 11.0475 6.01711 10.8171C5.67291 10.5866 5.58068 10.1208 5.81112 9.7766C6.59574 8.60462 7.60462 7.59574 8.7766 6.81112C9.1208 6.58068 9.58663 6.67291 9.81707 7.01711ZM5.08589 13.0626C5.49223 13.143 5.75647 13.5376 5.67608 13.9439C5.56064 14.5275 5.5 15.1313 5.5 15.75C5.5 16.3687 5.56064 16.9725 5.67608 17.5561C5.75647 17.9624 5.49223 18.357 5.08589 18.4374C4.67955 18.5177 4.28499 18.2535 4.2046 17.8472C4.0703 17.1683 4 16.467 4 15.75C4 15.033 4.0703 14.3317 4.2046 13.6528C4.28499 13.2465 4.67955 12.9823 5.08589 13.0626ZM6.01711 20.6829C6.3613 20.4525 6.82714 20.5447 7.05757 20.8889C7.73311 21.898 8.60203 22.7669 9.61108 23.4424C9.95528 23.6729 10.0475 24.1387 9.81707 24.4829C9.58664 24.8271 9.1208 24.9193 8.7766 24.6889C7.60462 23.9043 6.59574 22.8954 5.81112 21.7234C5.58068 21.3792 5.67291 20.9134 6.01711 20.6829Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
