import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { IconButton, Tooltip, styled } from '@mui/material';
import ModelsContext from '../../../../../context/ModelsContext/ModelsContext';
import { useJobs } from '@hooks/jobs/UseJobs';
import { JobStatus } from '@utils/job/JobStatus';
import { JobWithAction } from '@utils/job/Job';
import { ImportModelIcon } from '@root/assets/icons/ImportModelIcon';
import ShareDialog from '@root/pages/Models/ShareDialog';
import { ImportWithOptStatus } from '@root/utils/constants/enums';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const TOTAL_UPLOAD_PERCENTAGE = 95;
const IMPORT_CANCEL_REASON = 'Canceled by user';

interface ImportJob extends JobWithAction {
    totalSize: number;
}

const ImportModel = (): React.JSX.Element => {
    const { handleImportModel, handleImportWithOtp } = useContext(ModelsContext);
    const { createFrontendJob, updateFrontendJob } = useJobs();
    const { checkLicenseStatus } = useLicenseValidation();

    const [importJob, setImportJob] = useState<ImportJob | null>(null);
    const [uploadedSize, setUploadedSize] = useState<number | null>(null);
    const [jobCompletedSuccessfully, setJobCompletedSuccessfully] = useState<boolean | null>(null);
    const [importModalState, setImportModalState] = useState<boolean>(false);
    const [importStatus, setImportStatus] = useState<ImportWithOptStatus | null>(null);

    const cancelTokenSource = axios.CancelToken.source();

    const toggleImportModal = async (): Promise<void> => {
        if (importModalState) {
            setImportModalState(false);
            setImportStatus(null);
        } else {
            const isValid = await checkLicenseStatus();

            if (!isValid) {
                return;
            }

            setImportModalState(true);
        }
    };

    const onFileUploadProgress = (size: number): void => {
        setUploadedSize(size);
    };

    const handleImportModelBtn = async (file: File): Promise<void> => {
        if (importJob || !file) {
            return;
        }

        const newJob = generateJobItem(file);
        newJob.id = createFrontendJob(newJob);
        setImportJob(newJob);

        toggleImportModal();
        handleImportModel(file, onFileUploadProgress, cancelTokenSource).then((success: boolean) => setJobCompletedSuccessfully(success));
    };

    const handleImportWithKey = async (key: string): Promise<void> => {
        const result = await handleImportWithOtp(key);
        setImportStatus(result);

        if (result === ImportWithOptStatus.Success) {
            toggleImportModal();
        }
    };

    const handleImportCancel = (): void => {
        cancelTokenSource.cancel(IMPORT_CANCEL_REASON);
    };

    const generateJobItem = (file: File): ImportJob => {
        const date = new Date().toUTCString();
        return {
            cancel: async () => {
                handleImportCancel();
            },
            createdById: -1,
            dateCreated: date,
            dateFinished: null,
            id: -1,
            jobStatusId: JobStatus.PROCESSING,
            name: 'Import Model',
            percentage: 0,
            queuePosition: 0,
            totalSize: file.size,
        };
    };

    useEffect(() => {
        if (!uploadedSize || !importJob) {
            return;
        }

        const newPercentage = Math.round((uploadedSize / importJob.totalSize) * TOTAL_UPLOAD_PERCENTAGE);

        if (importJob.percentage == null || newPercentage - importJob.percentage >= 1 || uploadedSize == importJob.totalSize) {
            const updatedJob: ImportJob = {
                ...importJob,
                percentage: newPercentage,
            };
            setImportJob(updatedJob);
        }
    }, [uploadedSize]);

    useEffect(() => {
        if (!importJob) {
            return;
        }

        updateFrontendJob(importJob.id, importJob.percentage, importJob.jobStatusId);
        if (importJob.jobStatusId == JobStatus.FINISHED || importJob.jobStatusId == JobStatus.ERROR) {
            setImportJob(null);
        }
    }, [importJob]);

    useEffect(() => {
        if (jobCompletedSuccessfully == null) {
            return;
        }

        setImportJob((job) =>
            job
                ? {
                      ...job,
                      percentage: jobCompletedSuccessfully ? 100 : job.percentage,
                      jobStatusId: jobCompletedSuccessfully ? JobStatus.FINISHED : JobStatus.ERROR,
                  }
                : null
        );
        setJobCompletedSuccessfully(null);
    }, [jobCompletedSuccessfully]);

    return (
        <>
            <Tooltip title="Import model">
                <ActionButton
                    disableRipple
                    component="span"
                    onClick={toggleImportModal}
                >
                    <ImportModelIcon />
                </ActionButton>
            </Tooltip>

            <ShareDialog
                isExport={false}
                isActive={importModalState}
                onClose={toggleImportModal}
                uploadModel={handleImportModelBtn}
                handleKeyApply={handleImportWithKey}
                importStatus={importStatus}
            />
        </>
    );
};

export default ImportModel;

const ActionButton = styled(IconButton)<{ component: any }>(({ theme }) => ({
    width: '32px',
    height: '32px',
    color: theme.palette.primary.contrastText,

    '& svg': {
        width: '32px',
        height: '32px',
    },
}));
