import { useRequest } from '@hooks/request/UseRequest';
import { CancelTokenSource } from 'axios';
import { ModelApiItems } from '@root/types/apiTypes';
import { ImageLabel, ModelImageFile, SupportedRequestError } from '@root/types/commonTypes';
import { MergeModelsDto, ModelDto } from '@root/types/dto';
import { buildFormData, getRequestResult } from '@root/utils/helpers';

export const MODEL_END_POINT = '/models/';

export const useModelApi = (): ModelApiItems => {
    const { requestGet, requestGetWithJson, requestPostWithForm, requestPostWithJson, requestPost, requestDelete } = useRequest();

    const newModel = async (data: object): Promise<string | number | SupportedRequestError> => {
        const response = await requestPostWithJson(`${MODEL_END_POINT}create`, { data });
        return getRequestResult(response, { propertyName: 'model_id' });
    };

    const getModels = async (): Promise<ModelDto[] | SupportedRequestError> => {
        const response = await requestGet(MODEL_END_POINT);
        return getRequestResult(response, { propertyName: 'models' });
    };

    const getModel = async (modelId: string | number): Promise<ModelDto | SupportedRequestError> => {
        const response = await requestGet(`${MODEL_END_POINT}${modelId}`);
        return getRequestResult(response, { propertyName: 'model' });
    };

    const deleteModel = async (modelId: string | number): Promise<{ message: string } | SupportedRequestError> => {
        const response = await requestDelete(`${MODEL_END_POINT}${modelId}`);
        return response?.data;
    };

    const importModel = async (
        modelFile: File,
        onProgress: (size: number) => void,
        cancelTokenSource: CancelTokenSource
    ): Promise<{ Id: number; message: string } | SupportedRequestError> => {
        const data = new FormData();
        data.append('file', modelFile);

        const response = await requestPostWithForm(`${MODEL_END_POINT}import`, {
            data,
            onUploadProgress: (progressEvent) => onProgress(progressEvent.loaded),
            cancelToken: cancelTokenSource.token,
        });
        return response?.data;
    };

    const getModelImages = async (modelId: string | number, data: object): Promise<ModelImageFile[] | SupportedRequestError> => {
        const response = await requestGetWithJson(`/images/training/models/${modelId}/images`, {
            data,
        });
        return getRequestResult(response, { propertyName: 'image' });
    };

    const uploadImages = async (formData: FormData): Promise<ModelImageFile[] | SupportedRequestError> => {
        const response = await requestPostWithForm('/images/training/upload', { data: formData });
        return getRequestResult(response, { propertyName: 'images' });
    };

    const uploadImage = async (formData: FormData): Promise<ModelImageFile | SupportedRequestError> => {
        const response = await requestPostWithForm('/images/training/upload-with-label', { data: formData });
        return response?.data;
    };

    const deleteTrainImage = async (imageId: string | number): Promise<{ message: string } | SupportedRequestError> => {
        const response = await requestDelete(`/images/training/${imageId}`);
        return response?.data;
    };

    const updateModel = async (activeModelConfig: object): Promise<number | SupportedRequestError> => {
        const response = await requestPostWithJson(`${MODEL_END_POINT}update`, { data: activeModelConfig });
        return getRequestResult(response, { propertyName: 'model' });
    };

    const updateLabel = async (label: ImageLabel): Promise<void> => {
        await requestPostWithJson('/images/training/update', { data: label });
    };

    const trainModel = async (id: string | number): Promise<any | SupportedRequestError> => {
        const data = {
            modelId: id,
        };
        const response = await requestPostWithJson(`${MODEL_END_POINT}train`, { data });
        return response?.data;
    };

    const getModelPath = async (id: string | number) => {
        const response = await requestGet(`${MODEL_END_POINT}get_export_path/${id}`);

        const status = response?.data?.status;
        const result = response?.data?.result;
        if (!!status && !!result) {
            return result;
        } else {
            return null;
        }
    };

    const exportModel = async (id: string | number): Promise<{ message: string } | SupportedRequestError> => {
        const response = await requestGet(`${MODEL_END_POINT}export/${id}`);
        return response?.data;
    };

    const mergeModels = async (mergeConfig: MergeModelsDto): Promise<number | SupportedRequestError> => {
        const formData = buildFormData(mergeConfig);

        const response = await requestPostWithForm(`${MODEL_END_POINT}merge-models`, { data: formData });
        return getRequestResult(response, { propertyName: 'Model Id' });
    };

    const generateOtp = async (id: string | number): Promise<string | SupportedRequestError> => {
        const response = await requestPost(`${MODEL_END_POINT}generate-otp/${id}`);
        return response?.data;
    };

    const applyOtp = async (otp: string): Promise<number | SupportedRequestError> => {
        const response = await requestPostWithJson(`${MODEL_END_POINT}apply-otp`, { data: { otp } });
        return response?.data;
    };

    return {
        newModel,
        trainModel,
        deleteModel,
        mergeModels,
        updateModel,

        getModels,
        getModel,
        getModelImages,

        uploadImages,
        uploadImage,
        updateLabel,
        deleteTrainImage,

        getModelPath,
        exportModel,
        generateOtp,

        applyOtp,
        importModel,
    };
};
