import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Stack, Tooltip, Typography, styled } from '@mui/material';

import { Done } from '@mui/icons-material';

import { useCurate } from '@hooks/curate/useCurate';
import useKeyPress from '@hooks/helpers/useKeyPress';

import { IconButton } from '@pages/CuratePage/components/ToolPanel/IconButton';
import { MixingImageButton } from '@pages/CuratePage/components/ToolPanel/MixingImageButton';
import UpscaleToolButton from './UpscaleToolButton';

import ProjectsContext from '@root/context/ProjectsContext/ProjectsContext';

import { SelectIcon } from '@assets/icons/SelectIcon';
import { ImportIcon } from '@assets/icons/ImportIcon';
import { BrushIcon } from '@assets/icons/BrushIcon';
import { BackgroundReplaceIcon } from '@assets/icons/BackgroundReplaceIcon';
import { DownloadIcon } from '@assets/icons/DownloadIcon';
import { PrevIcon } from '@assets/icons/PrevIcon';
import { NextIcon } from '@assets/icons/NextIcon';
import { EraserIcon } from '@assets/icons/EraserIcon';
import { SendToGenerateIcon } from '@assets/icons/SendToGenerateIcon';
import { FullScreenOpenIcon } from '@assets/icons/FullScreenOpenIcon';
import { StyleDriveIcon } from '@assets/icons/StyleDriveIcon';
import { SceneModeIcon } from '@assets/icons/SceneModeIcon';

import { notificationMessages } from '@utils/constants/notificationMessages';
import { CurateTools, HotKeysList, SupportedHotKeys } from '@utils/constants/enums';

const ToolPanel = () => {
    const {
        setRedoUsed,
        setUndoUsed,
        isRedoDisable,
        isUndoDisable,

        saveImage,
        downloadImage,
        canvasImageSrc,
        uploadImagesAsLayer,
        toggleFullScreen,
        isImageReady,
        setIsImageReady,
        setIsProgressBarActive,
        isProgressBarActive,
        toggleTool,
        activeTool,
        isEraserInProgress,
        isImagesLoading,
    } = useCurate();

    const { setProjectImagesNumber } = useContext(ProjectsContext);

    const moveToGenerateHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_D].key,
        SupportedHotKeys[HotKeysList.CTRL_D].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_D].blockDefaultEvent
    );
    const undoHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_Z].key,
        SupportedHotKeys[HotKeysList.CTRL_Z].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_Z].blockDefaultEvent
    );
    const redoHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_Y].key,
        SupportedHotKeys[HotKeysList.CTRL_Y].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_Y].blockDefaultEvent
    );

    const disabled = !canvasImageSrc || isEraserInProgress || isImagesLoading;

    const [notificationState, setNotificationState] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState(false);
    const inputImage = useRef(null);

    useEffect(() => {
        if (moveToGenerateHotKey) {
            handleSaveImage();
        }
    }, [moveToGenerateHotKey]);

    useEffect(() => {
        if (undoHotKey && !isUndoDisable) {
            setUndoUsed(true);
        }
        if (redoHotKey && !isRedoDisable) {
            setRedoUsed(true);
        }
    }, [undoHotKey, redoHotKey]);

    useEffect(() => {
        if (isImageReady) {
            setNotificationMessage(notificationMessages.curate.generation.success);
            updateNotificationState();
            setIsImageReady(false);
        }
    }, [isImageReady]);

    const handleSaveImage = async () => {
        const response = await saveImage();
        if (response && !response.errorCode) {
            setProjectImagesNumber(response.projectImagesNumber);
            setNotificationMessage(notificationMessages.curate.moveToGenerate.success);
            updateNotificationState();
        }
    };

    const updateNotificationState = () => {
        setNotificationState(true);
        setTimeout(() => {
            setNotificationState(false);
        }, 2000);
    };

    const uploadImage = async (e) => {
        if (!e.target.files.length) {
            return;
        }
        await uploadImagesAsLayer(e.target.files);
    };

    const toggleProgressBarVisibility = () => {
        setIsProgressBarActive(!isProgressBarActive);
    };

    return (
        <Wrapper
            alignItems="center"
            direction="row"
        >
            <ToolsPart
                alignItems="center"
                direction="row"
                gap={2.4}
            >
                <Tooltip title="Brush">
                    <span>
                        <IconButton
                            disabled={isEraserInProgress || isImagesLoading}
                            value={CurateTools.Brush}
                            onClick={toggleTool}
                            active={activeTool === CurateTools.Brush ? 1 : 0}
                        >
                            <BrushIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="Lasso">
                    <span>
                        <IconButton
                            value={CurateTools.Lasso}
                            active={activeTool === CurateTools.Lasso ? 1 : 0}
                            disabled={disabled}
                            onClick={toggleTool}
                        >
                            <SelectIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <CustomDivider orientation="vertical" />
                <Tooltip title="Scene Shift">
                    <span>
                        <IconButton
                            value={CurateTools.SceneShift}
                            active={activeTool === CurateTools.SceneShift ? 1 : 0}
                            disabled={isEraserInProgress || isImagesLoading}
                            onClick={toggleTool}
                        >
                            <BackgroundReplaceIcon />
                        </IconButton>
                    </span>
                </Tooltip>

                <MixingImageButton disabled={isEraserInProgress || isImagesLoading} />

                <Tooltip title="Style Drive">
                    <span>
                        <IconButton
                            value={CurateTools.StyleDrive}
                            active={activeTool === CurateTools.StyleDrive ? 1 : 0}
                            disabled={isEraserInProgress || isImagesLoading}
                            onClick={toggleTool}
                        >
                            <StyleDriveIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title="3D Mode">
                    <span>
                        <IconButton
                            value={CurateTools.SceneMode}
                            active={activeTool === CurateTools.SceneMode ? 1 : 0}
                            disabled={disabled}
                            onClick={toggleTool}
                        >
                            <SceneModeIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <CustomDivider orientation="vertical" />
                <UpscaleToolButton disabled={disabled} />

                <Tooltip title="Eraser">
                    <span>
                        <IconButton
                            value={CurateTools.Eraser}
                            active={activeTool === CurateTools.Eraser ? 1 : 0}
                            disabled={disabled}
                            onClick={toggleTool}
                        >
                            <EraserIcon />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title="Full screen">
                    <span>
                        <IconButton
                            disableRipple
                            disabled={disabled}
                            onClick={toggleFullScreen}
                        >
                            <FullScreenOpenIcon />
                        </IconButton>
                    </span>
                </Tooltip>

                <CustomDivider orientation="vertical" />
                <Tooltip title="Download image">
                    <span>
                        <IconButton
                            onClick={downloadImage}
                            disabled={disabled}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                {canvasImageSrc && (
                    <Tooltip title="Send to Create page">
                        <span>
                            <IconButton
                                disabled={notificationState}
                                onClick={handleSaveImage}
                            >
                                <SendToGenerateIcon color={'inherite'} />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
                <CustomDivider orientation="vertical" />

                <Tooltip title="Upload new image">
                    <span>
                        <IconButton
                            disabled={isEraserInProgress || isImagesLoading}
                            onClick={() => inputImage.current.click()}
                        >
                            <ImportIcon color={'inherite'} />
                            <input
                                style={{ display: 'none' }}
                                onChange={uploadImage}
                                ref={inputImage}
                                type="file"
                                accept="image/png, image/jpeg, image/webp"
                                multiple
                            />
                        </IconButton>
                    </span>
                </Tooltip>
                <CustomDivider orientation="vertical" />
                <Tooltip title="Undo action ">
                    <span>
                        <IconButton
                            disabled={disabled || isUndoDisable}
                            onClick={() => setUndoUsed(true)}
                        >
                            <PrevIcon />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title="Redo action ">
                    <span>
                        <IconButton
                            disabled={disabled || isRedoDisable}
                            onClick={() => setRedoUsed(true)}
                        >
                            <NextIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </ToolsPart>

            <ToolsPart
                justifySelf="flex-end"
                alignItems="center"
                direction="row"
                gap={2}
                ml="auto"
            >
                {notificationState && (
                    <NotificationSection
                        gap={1}
                        pl={18.5}
                    >
                        <Done
                            sx={{
                                color: 'primary.inactive',
                                fontSize: '10px',
                            }}
                        />
                        <Typography
                            component={'p'}
                            variant="body12"
                            color="primary.inactive"
                        >
                            {notificationMessage}
                        </Typography>
                    </NotificationSection>
                )}
                <ProgressBarAction onClick={toggleProgressBarVisibility}></ProgressBarAction>
            </ToolsPart>
        </Wrapper>
    );
};

export default ToolPanel;

const Wrapper = styled(Stack)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '70px',
    borderBottom: `1px solid ${theme.palette.primary.light1}`,
    padding: theme.spacing(1.9, 4, 1.9, 3),

    '@media(max-width:1500px)': {
        height: '65px',
    },
    zIndex: 20,
}));

const CustomDivider = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.light1,

    width: '1px',

    height: '30px',
    '&:hover': {
        background: 'transparent',
    },
}));

const ToolsPart = styled(Stack)(() => ({
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1,

    '& button': {
        height: '32px',
        width: '32px',
    },
}));

const NotificationSection = styled(Stack)(() => ({
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'start',
    whiteSpace: 'nowrap',

    '& button': {
        color: 'black !important',
        height: '30px',
        fontSize: '14px',
    },
}));

const ProgressBarAction = styled(Box)(() => ({
    width: '40px',
    height: '60px',
    cursor: 'pointer',
}));
