import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import ProjectListPageHeader from './components/ProjectListPageHeader';
import ProjectTable from './components/ProjectTable';

import ProjectsContext from '../../../context/ProjectsContext/ProjectsContext';

const ProjectsListPage = () => {
    const { projects, handleSetProjects } = useContext(ProjectsContext);

    const [value, setValue] = useState('');
    const [projectList, setProjectList] = useState('');

    useEffect(() => {
        handleSetProjects();
    }, []);

    useEffect(() => {
        setProjectList(projects);
        setValue('');
    }, [projects]);

    useEffect(() => {
        const newList = projects.filter((el) => el.Name.toLowerCase().startsWith(value.toLowerCase()));
        setProjectList(newList);
    }, [value]);

    return (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100% - 60px)',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    width: '1200px',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    padding: '60px 0px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '50px',
                }}
            >
                <ProjectListPageHeader
                    value={value}
                    setValue={setValue}
                />
                <ProjectTable data={projectList} />
            </Box>
            <Outlet />
        </Box>
    );
};

export default ProjectsListPage;
