class SelectCanvasArea {
    constructor(canvasElement, viewportElement) {
        this.canvasElement = canvasElement;

        this.viewportElement = viewportElement;

        this.isDrawing = false;

        this.points = [];

        this.enable = false;

        this.ctx = this.canvasElement.getContext('2d');

        this.fillStyle = 'rgba(0,0,0,0.3)';
        this.lineWidth = 2;
        this.strokeStyle = 'black';
        this.isLineDash = true;
        this.lineDashStyle = [5, 3];
    }

    computePointInCanvas(clientX, clientY) {
        if (this.viewportElement) {
            const boundingRect = this.viewportElement.getBoundingClientRect();

            return {
                x: clientX - boundingRect.left,
                y: clientY - boundingRect.top,
            };
        } else {
            return null;
        }
    }

    setLines = () => {
        this.isLineDash && this.ctx.setLineDash(this.lineDashStyle);
        this.ctx.strokeStyle = this.strokeStyle;
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.fillStyle = this.fillStyle;
        this.ctx.globalAlpha = 1;
    };

    renderSelection = () => {
        if (this.points.length <= 1) {
            return;
        }

        this.ctx.beginPath();
        this.setLines();

        this.points.forEach((point, index) => {
            if (index == 0) {
                this.ctx.moveTo(point.x, point.y);
            } else {
                this.ctx.lineTo(point.x, point.y);
            }
        });

        this.ctx.stroke();
    };

    endPath = () => {
        this.ctx.lineTo(this.points[0].x, this.points[0].y);
        this.ctx.stroke();
        this.ctx.fill();
        this.ctx.closePath();
    };

    onMouseDown = (e) => {
        if (!this.enable) return;
        this.isDrawing = true;
        const point = e.clientX
            ? this.computePointInCanvas(e.clientX, e.clientY)
            : this.computePointInCanvas(e.touches[0].clientX, e.touches[0].clientY);
        this.points = [point];
    };

    onMouseMove = (e) => {
        if (!this.isDrawing) return;
        const point = e.clientX
            ? this.computePointInCanvas(e.clientX, e.clientY)
            : this.computePointInCanvas(e.touches[0].clientX, e.touches[0].clientY);

        this.points.push(point);

        this.renderSelection();
    };

    onMouseUp = () => {
        if (!this.isDrawing) return;
        if (this.points.length > 2) {
            this.endPath();
        } else {
            this.points = [];
        }

        this.isDrawing = false;
    };

    clearCanvas = (width = this.viewportElement.clientWidth, height = this.viewportElement.clientHeight) => {
        this.ctx.clearRect(0, 0, width, height);
    };
}

export default SelectCanvasArea;
