import React, { useContext } from 'react';
import { Navigate, Route } from 'react-router-dom';
import routesConfig from './routesConfig';
import AuthContext from '@root/context/AuthContext/AuthContext';
import { GuardType, UserRoles } from '@root/utils/constants/enums';

const GuardedRoute = ({ guardType, children }) => {
    const { isSignedIn, userRole } = useContext(AuthContext);

    let accessDenied = false;
    const shouldBeLoggedIn = guardType === GuardType.LoggedInOnly;
    const shouldBeAdmin = guardType === GuardType.AdminOnly;

    if (shouldBeLoggedIn) {
        accessDenied = !isSignedIn;
    }
    if (!accessDenied && shouldBeAdmin) {
        accessDenied = userRole !== UserRoles.Admin;
    }

    if (accessDenied) {
        return (
            <Navigate
                to="/"
                replace
            />
        );
    }
    return children;
};

const generateRoute = (routes, element) => {
    return routes.map((route, index) => {
        return route.guard ? (
            <Route
                path={route.path}
                element={<GuardedRoute guardType={route.guard}>{route[element]}</GuardedRoute>}
                key={index}
            >
                {route.child && generateRoute(route.child, element)}
            </Route>
        ) : (
            <Route
                path={route.path}
                element={route[element]}
                key={index}
            >
                {route.child && generateRoute(route.child, element)}
            </Route>
        );
    });
};

const getNavItemObject = (parentRoutesArray, parentRouterName) => {
    let childRoutes = [];

    parentRoutesArray.forEach((route) => {
        if (route.label === parentRouterName) {
            childRoutes = [...route.child];
        } else {
            if (route.child) {
                const child = getNavItemObject(route.child, parentRouterName);
                childRoutes = child.length > 0 ? [...child] : [...childRoutes];
            }
        }
    });

    return childRoutes;
};

export const mainRoutes = generateRoute(routesConfig, 'mainElement');
export const ProjectPageNav = getNavItemObject(routesConfig, 'ProjectPage');
export const GeneratePageNav = getNavItemObject(routesConfig, 'CreatePage');
export const MainNav = getNavItemObject(routesConfig, 'MainNavigation');
export const SecondaryNav = getNavItemObject(routesConfig, 'SecondaryNavigation');
