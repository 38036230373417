import { useRequest } from '@root/hooks/request/UseRequest';
import { SceneApiItems } from '@root/types/apiTypes/SceneApiItems';
import { SupportedRequestError } from '@root/types/commonTypes';
import { ErasePanoramaDto, GeneratePanoramaDto, SceneLayerDto, SceneSessionDto } from '@root/types/dto';
import { buildFormData, getRequestResult } from '@root/utils/helpers';

const SCENE_SESSIONS_END_POINT = '/scene-sessions';
const SCENE_LAYERS_END_POINT = '/scene-layers';
const HDR_GENERATIONS_END_POINT = '/generations/hdr';

export const useSceneApi = (): SceneApiItems => {
    const { requestPutWithJson, requestDelete, requestPostWithJson, requestGet, requestPostWithForm } = useRequest();

    // Scene Session

    const createSceneSession = async (projectId: number): Promise<SceneSessionDto | SupportedRequestError> => {
        const response = await requestPostWithJson(SCENE_SESSIONS_END_POINT, {
            data: { projectId },
        });

        return response?.data;
    };

    const getSceneSessionById = async (sessionId: number): Promise<SceneSessionDto | SupportedRequestError> => {
        const response = await requestGet(`${SCENE_SESSIONS_END_POINT}/${sessionId}`);

        return response?.data;
    };

    const getSceneSessionByProjectId = async (projectId: number): Promise<SceneSessionDto | SupportedRequestError> => {
        const result = await requestGet(`/projects/${projectId}${SCENE_SESSIONS_END_POINT}`);

        return result?.data;
    };

    const deleteSceneSessionById = async (sessionId: number): Promise<number | SupportedRequestError> => {
        const response = await requestDelete(`${SCENE_SESSIONS_END_POINT}/${sessionId}`);

        return getRequestResult(response, { propertyName: 'id' });
    };

    const getSceneSessionLayers = async (sessionId: number): Promise<SceneLayerDto[] | SupportedRequestError> => {
        const response = await requestGet(`${SCENE_SESSIONS_END_POINT}/${sessionId}/layers`);

        return response?.data;
    };

    // Scene Session Layer

    const getSceneLayerById = async (layerId: number): Promise<SceneLayerDto | SupportedRequestError> => {
        const response = await requestGet(`${SCENE_LAYERS_END_POINT}/${layerId}`);

        return response?.data;
    };

    const deleteSceneLayerById = async (layerId: number): Promise<string | SupportedRequestError> => {
        const response = await requestDelete(`${SCENE_LAYERS_END_POINT}/${layerId}`);

        return getRequestResult(response, { propertyName: 'id' });
    };

    const changeSceneLayersOrder = async (sceneSessionId: number, layersId: number[]): Promise<number | SupportedRequestError> => {
        const response = await requestPutWithJson(`${SCENE_LAYERS_END_POINT}/change-order`, {
            data: {
                sceneSessionId,
                layersId,
            },
        });

        return getRequestResult(response, { propertyName: 'sceneSessionId' });
    };

    const renameSceneLayerById = async (layerId: number, name: string): Promise<SceneLayerDto | SupportedRequestError> => {
        const response = await requestPutWithJson(`${SCENE_LAYERS_END_POINT}/${layerId}/rename`, {
            data: {
                name,
            },
        });

        return response?.data;
    };

    // Generations

    const generatePanorama = async (generationConfig: GeneratePanoramaDto): Promise<number | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);
        const response = await requestPostWithForm(`${HDR_GENERATIONS_END_POINT}/panorama`, {
            data: formData,
        });

        return response?.data;
    };

    const erasePanorama = async (generationConfig: ErasePanoramaDto): Promise<number | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);
        const response = await requestPostWithForm(`${HDR_GENERATIONS_END_POINT}/eraser`, {
            data: formData,
        });

        return response?.data;
    };

    const generateHdr = async (generationConfig: { sceneLayerId: number }): Promise<number | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);
        const response = await requestPostWithForm(`${HDR_GENERATIONS_END_POINT}/hdr`, {
            data: formData,
        });

        return response?.data;
    };

    return {
        createSceneSession,
        getSceneSessionById,
        getSceneSessionByProjectId,
        deleteSceneSessionById,
        getSceneSessionLayers,

        getSceneLayerById,
        deleteSceneLayerById,
        changeSceneLayersOrder,
        renameSceneLayerById,

        generatePanorama,
        erasePanorama,
        generateHdr,
    };
};
