import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';

import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { useCurate } from '@root/hooks/curate/useCurate';

import ListRowPlaceholder from '@root/components/SortableListHelpers/ListRowPlaceholder';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import CurateLayersList from '../CurateLayersList';

import { CommonConstants } from '@root/utils/constants';
import { CurateTools } from '@root/utils/constants/enums';

const CurateLayersInfo = (): React.JSX.Element => {
    const layersRef = useRef<HTMLDivElement>(null);

    const {
        layers,
        changeOrder,
        deleteLayer,
        moveToNextLayer,
        moveToPreviousLayer,
        currentlyInProgress,
        uploadQueue,
        imageMenuIsActive,
        setImageMenuIsActive,
        updatedLayerByBrush,
        selectLayerByOrderIndex,
        toggleMetadataDialog,
        lastVisibleLayerId,
        getBlendedImageFile,
        isEraserInProgress,
        isSketchSelected,
        renameLayer,
        activeTool,
    } = useCurate();

    const { activeImageLayerIdSD } = useStyleDrive();
    const { activeImageLayerIdMix } = useMixImage();

    const arrowUpPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.UP);
    const arrowDownPressed = useKeyPress(CommonConstants.ARROW_BUTTONS.DOWN);

    const [focus, setFocus] = useState(false);
    const [size, setSize] = useState(true);

    const isShowLayers = activeTool !== CurateTools.SceneMode;

    const buttonAction = () => {
        setImageMenuIsActive(!imageMenuIsActive);
    };

    useEffect(() => {
        if (arrowUpPressed && focus) {
            moveToPreviousLayer();
        }
    }, [arrowUpPressed]);

    useEffect(() => {
        if (arrowDownPressed && focus) {
            moveToNextLayer();
        }
    }, [arrowDownPressed]);

    useEffect(() => {
        setSize(isShowLayers);
        setImageMenuIsActive(imageMenuIsActive && isShowLayers);
    }, [activeTool]);

    useEffect(() => {
        const checkFocus = (event: MouseEvent) => {
            if (event.target !== layersRef.current && !layersRef.current?.contains(event.target as HTMLElement)) {
                setFocus(false);
            }
        };

        window.addEventListener('click', checkFocus);

        return () => {
            window.removeEventListener('click', checkFocus);
        };
    }, []);

    const handleDeleteLayer = useCallback(
        (layerId: number) => {
            deleteLayer(layerId);
        },
        [layers, activeImageLayerIdSD, activeImageLayerIdMix]
    );

    const selectLayerByIndex = useCallback(
        (layerOrder: number) => {
            selectLayerByOrderIndex(layerOrder);
        },
        [isEraserInProgress, isSketchSelected, updatedLayerByBrush]
    );

    const onChange = useCallback(
        (layerOrder: number[]) => {
            changeOrder(layerOrder);
        },
        [layers]
    );

    const handleRenameLayer = useCallback(
        (layerId: number, name: string) => {
            renameLayer(layerId, name);
        },
        [layers]
    );

    const getBlendedImage = useCallback(async (background: string, foreground: string) => {
        const file = await getBlendedImageFile(background, foreground, true);
        return file;
    }, []);

    const showMetadataDialog = useCallback(() => {
        toggleMetadataDialog();
    }, []);

    return (
        <Wrapper
            size={size ? 1 : 0}
            ref={layersRef}
        >
            <LayersButton active={imageMenuIsActive && layers?.length ? 1 : 0}>
                <Tooltip title="Images">
                    <span>
                        <IconButtonCss
                            disableRipple
                            disabled={!layers?.length}
                            onClick={buttonAction}
                        >
                            {imageMenuIsActive && layers?.length ? <KeyboardArrowRightOutlined /> : <KeyboardArrowLeftOutlined />}
                        </IconButtonCss>
                    </span>
                </Tooltip>
            </LayersButton>
            <Layers active={imageMenuIsActive && layers?.length ? 1 : 0}>
                <Title>
                    <Typography>Images</Typography>
                </Title>
                <LayersStack>
                    {(currentlyInProgress > 0 || uploadQueue > 0) &&
                        Array.from({ length: currentlyInProgress + uploadQueue }, (_, index) => {
                            return (
                                <ListRowPlaceholder
                                    placeholderVariant={'curate'}
                                    key={index}
                                />
                            );
                        })}
                    <CurateLayersList
                        layers={layers}
                        lastVisibleLayerId={lastVisibleLayerId}
                        deleteLayer={handleDeleteLayer}
                        selectLayerByIndex={selectLayerByIndex}
                        onChange={onChange}
                        showMetadataDialog={showMetadataDialog}
                        getBlendedImage={getBlendedImage}
                        renameLayer={handleRenameLayer}
                        setFocus={setFocus}
                    />
                </LayersStack>
            </Layers>
        </Wrapper>
    );
};

export default CurateLayersInfo;

const Wrapper = styled(Box)<{ size: number }>(({ size }) => ({
    display: 'flex',
    flex: 1,
    maxWidth: '301px',
    opacity: !size ? 0 : 1,
}));

const Title = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '16px 10px 10px 14px',
}));

const LayersButton = styled(Box)<{ active: number }>(({ theme, active }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '30px',
    width: '34px',
    height: '64px',

    borderBottomLeftRadius: '15px',
    borderTopLeftRadius: '15px',
    background: theme.palette.neutrals.main,
    border: `1px solid ${theme.palette.primary.light}`,

    transition: 'all 0.5s',
    transitionDelay: active ? '0s' : ' 0.5s',
}));

const IconButtonCss = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& svg': {
        fill: theme.palette.primary.light1,

        '&:hover': {
            fill: theme.palette.secondary.main,
        },
    },
}));

const Layers = styled(Box)<{ active: number }>(({ theme, active }) => ({
    width: active ? '300px' : '0px',
    maxHeight: active ? 'calc(100vh - 170px)' : '54px',
    height: 'fit-content',
    position: 'relative',
    boxSizing: 'border-box',

    background: theme.palette.neutrals.main,
    border: `${active ? 1 : 0}px solid ${theme.palette.primary.light}`,
    borderBottomLeftRadius: active ? '16px' : '0px',

    transition: 'all 0.5s',
    zIndex: 10,

    '& *': {
        display: !active && 'none',
    },
}));

const LayersStack = styled(Stack)(() => ({
    direction: 'rtl',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    maxHeight: 'calc(100vh - 270px)',
    marginBottom: '10px',
}));
