import download from 'downloadjs';
import { FolderStructureBlob } from '../../utils/blob/FolderStructureBlob';

export interface UseMultipleDownloads {
    downloadZip: (folderBlob: FolderStructureBlob) => Promise<any>;
}

export const useMultipleDownloads = (): UseMultipleDownloads => {
    const downloadZip = async (folderBlob: FolderStructureBlob) => {
        const zipFile = await folderBlob.toZipBlob();
        return download(zipFile, `${folderBlob.name}.zip`, 'application/zip');
    };

    return {
        downloadZip,
    };
};
