import React, { PointerEventHandler, useEffect, useState, WheelEventHandler } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { clamp } from 'lodash';

import { SliderMarkerSVG } from '@root/assets/icons/SliderMarkerSVG';
import { ROLL_RANGE } from '@root/utils/constants/SceneModeConstants';
import { useSceneModeCurate } from '@root/context/SceneModeCurateContext/useSceneModeCurate';

const RollSlider = () => {
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [startPosition, setStartPosition] = useState<number>(0);

    const [isSliderInUse, setIsSliderInUse] = useState<boolean>(false);

    const { rollValue, setRoll } = useSceneModeCurate();

    const handleSliderChange: PointerEventHandler<HTMLDivElement> = (e) => {
        if (isSliderInUse) {
            let targetValue = rollValue;

            if (startPosition - e.screenX > 7) {
                targetValue = clamp(rollValue + 1, ROLL_RANGE.min, ROLL_RANGE.max);
                setStartPosition(e.screenX);
            } else if (startPosition - e.screenX < -7) {
                targetValue = clamp(rollValue - 1, ROLL_RANGE.min, ROLL_RANGE.max);

                setStartPosition(e.screenX);
            } else {
                return;
            }

            const offset = (targetValue - 4) * 7;
            setRoll(targetValue);
            setSliderValue(offset);
        }
    };

    const startSlide: PointerEventHandler<HTMLDivElement> = (e) => {
        setIsSliderInUse(true);
        setStartPosition(e.screenX);
    };

    const stopSlide = (e: PointerEvent) => {
        setIsSliderInUse(false);
    };

    const onScroll: WheelEventHandler<HTMLDivElement> = (e) => {
        let targetValue;
        if (e.deltaX > 0) {
            targetValue = rollValue + 1;
        } else if (e.deltaX < 0) {
            targetValue = rollValue - 1;
        } else {
            return;
        }

        const value = clamp(targetValue, ROLL_RANGE.min, ROLL_RANGE.max);

        const offset = (value - 4) * 7;
        setRoll(value);
        setSliderValue(offset);
    };

    useEffect(() => {
        window.addEventListener('pointerup', stopSlide);
        return () => window.removeEventListener('pointerup', stopSlide);
    }, []);

    useEffect(() => {
        const offset = (rollValue - 4) * 7;

        if (offset !== sliderValue) {
            setSliderValue(offset);
        }
    }, [rollValue]);

    return (
        <Container>
            <SliderWrapper
                onPointerDown={startSlide}
                onPointerMove={handleSliderChange}
                onWheel={onScroll}
            >
                <MarkerWrapper>
                    <SliderMarkerSVG />
                </MarkerWrapper>

                <RollValueText>{rollValue}˚</RollValueText>
                <StyledSlider offset={sliderValue}>
                    <svg
                        width="2515"
                        height="24"
                        viewBox="0 0 2515 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.99709 0H0V24H1.99709V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M8.99416 6H6.99707V18H8.99416V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M15.9912 6H13.9941V18H15.9912V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M22.9883 6H20.9912V18H22.9883V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M29.9854 6H27.9883V18H29.9854V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M36.9824 6H34.9854V18H36.9824V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M43.9795 6H41.9824V18H43.9795V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M50.9766 6H48.9795V18H50.9766V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M57.9737 6H55.9766V18H57.9737V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M64.9707 6H62.9736V18H64.9707V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M71.9678 0H69.9707V24H71.9678V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M78.9649 6H76.9678V18H78.9649V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M85.9619 6H83.9648V18H85.9619V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M92.959 6H90.9619V18H92.959V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M99.9561 6H97.959V18H99.9561V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M106.953 6H104.956V18H106.953V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M113.95 6H111.953V18H113.95V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M120.947 6H118.95V18H120.947V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M127.944 6H125.947V18H127.944V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M134.941 6H132.944V18H134.941V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M141.938 0H139.941V24H141.938V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M148.936 6H146.938V18H148.936V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M155.933 6H153.936V18H155.933V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M162.93 6H160.933V18H162.93V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M169.927 6H167.93V18H169.927V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M176.924 6H174.927V18H176.924V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M183.921 6H181.924V18H183.921V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M190.918 6H188.921V18H190.918V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M197.915 6H195.918V18H197.915V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M204.912 6H202.915V18H204.912V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M211.909 0H209.912V24H211.909V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M218.906 6H216.909V18H218.906V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M225.903 6H223.906V18H225.903V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M232.9 6H230.903V18H232.9V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M239.897 6H237.9V18H239.897V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M246.895 6H244.897V18H246.895V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M253.892 6H251.895V18H253.892V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M260.889 6H258.892V18H260.889V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M267.886 6H265.889V18H267.886V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M274.883 6H272.886V18H274.883V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M281.88 0H279.883V24H281.88V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M288.877 6H286.88V18H288.877V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M295.874 6H293.877V18H295.874V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M302.871 6H300.874V18H302.871V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M309.868 6H307.871V18H309.868V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M316.865 6H314.868V18H316.865V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M323.862 6H321.865V18H323.862V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M330.859 6H328.862V18H330.859V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M337.856 6H335.859V18H337.856V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M344.854 6H342.856V18H344.854V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M351.851 0H349.854V24H351.851V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M358.848 6H356.851V18H358.848V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M365.845 6H363.848V18H365.845V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M372.842 6H370.845V18H372.842V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M379.839 6H377.842V18H379.839V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M386.836 6H384.839V18H386.836V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M393.833 6H391.836V18H393.833V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M400.83 6H398.833V18H400.83V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M407.827 6H405.83V18H407.827V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M414.824 6H412.827V18H414.824V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M421.821 0H419.824V24H421.821V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M428.818 6H426.821V18H428.818V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M435.815 6H433.818V18H435.815V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M442.813 6H440.815V18H442.813V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M449.81 6H447.812V18H449.81V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M456.807 6H454.81V18H456.807V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M463.804 6H461.807V18H463.804V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M470.801 6H468.804V18H470.801V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M477.798 6H475.801V18H477.798V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M484.795 6H482.798V18H484.795V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M491.792 0H489.795V24H491.792V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M498.789 6H496.792V18H498.789V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M505.786 6H503.789V18H505.786V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M512.783 6H510.786V18H512.783V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M519.78 6H517.783V18H519.78V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M526.777 6H524.78V18H526.777V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M533.774 6H531.777V18H533.774V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M540.772 6H538.774V18H540.772V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M547.769 6H545.771V18H547.769V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M554.766 6H552.769V18H554.766V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M561.763 0H559.766V24H561.763V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M568.76 6H566.763V18H568.76V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M575.757 6H573.76V18H575.757V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M582.754 6H580.757V18H582.754V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M589.751 6H587.754V18H589.751V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M596.748 6H594.751V18H596.748V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M603.745 6H601.748V18H603.745V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M610.742 6H608.745V18H610.742V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M617.739 6H615.742V18H617.739V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M624.736 6H622.739V18H624.736V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M631.733 0H629.736V24H631.733V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M638.73 6H636.733V18H638.73V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M645.728 6H643.73V18H645.728V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M652.725 6H650.728V18H652.725V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M659.722 6H657.725V18H659.722V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M666.719 6H664.722V18H666.719V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M673.716 6H671.719V18H673.716V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M680.713 6H678.716V18H680.713V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M687.71 6H685.713V18H687.71V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M694.707 6H692.71V18H694.707V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M701.704 0H699.707V24H701.704V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M708.701 6H706.704V18H708.701V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M715.698 6H713.701V18H715.698V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M722.695 6H720.698V18H722.695V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M729.692 6H727.695V18H729.692V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M736.689 6H734.692V18H736.689V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M743.687 6H741.689V18H743.687V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M750.684 6H748.687V18H750.684V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M757.681 6H755.684V18H757.681V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M764.678 6H762.681V18H764.678V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M771.675 0H769.678V24H771.675V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M778.672 6H776.675V18H778.672V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M785.669 6H783.672V18H785.669V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M792.666 6H790.669V18H792.666V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M799.663 6H797.666V18H799.663V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M806.66 6H804.663V18H806.66V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M813.657 6H811.66V18H813.657V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M820.654 6H818.657V18H820.654V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M827.651 6H825.654V18H827.651V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M834.648 6H832.651V18H834.648V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M841.646 0H839.648V24H841.646V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M848.643 6H846.646V18H848.643V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M855.64 6H853.643V18H855.64V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M862.637 6H860.64V18H862.637V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M869.634 6H867.637V18H869.634V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M876.631 6H874.634V18H876.631V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M883.628 6H881.631V18H883.628V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M890.625 6H888.628V18H890.625V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M897.622 6H895.625V18H897.622V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M904.619 6H902.622V18H904.619V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M911.616 0H909.619V24H911.616V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M918.613 6H916.616V18H918.613V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M925.61 6H923.613V18H925.61V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M932.607 6H930.61V18H932.607V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M939.605 6H937.607V18H939.605V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M946.602 6H944.604V18H946.602V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M953.599 6H951.602V18H953.599V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M960.596 6H958.599V18H960.596V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M967.593 6H965.596V18H967.593V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M974.59 6H972.593V18H974.59V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M981.587 0H979.59V24H981.587V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M988.584 6H986.587V18H988.584V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M995.581 6H993.584V18H995.581V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1002.58 6H1000.58V18H1002.58V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1009.58 6H1007.58V18H1009.58V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1016.57 6H1014.58V18H1016.57V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1023.57 6H1021.57V18H1023.57V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1030.57 6H1028.57V18H1030.57V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1037.56 6H1035.57V18H1037.56V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1044.56 6H1042.56V18H1044.56V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1051.56 0H1049.56V24H1051.56V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1058.55 6H1056.56V18H1058.55V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1065.55 6H1063.55V18H1065.55V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1072.55 6H1070.55V18H1072.55V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1079.55 6H1077.55V18H1079.55V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1086.54 6H1084.55V18H1086.54V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1093.54 6H1091.54V18H1093.54V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1100.54 6H1098.54V18H1100.54V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1107.53 6H1105.54V18H1107.53V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1114.53 6H1112.53V18H1114.53V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1121.53 0H1119.53V24H1121.53V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1128.53 6H1126.53V18H1128.53V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1135.52 6H1133.53V18H1135.52V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1142.52 6H1140.52V18H1142.52V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1149.52 6H1147.52V18H1149.52V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1156.51 6H1154.52V18H1156.51V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1163.51 6H1161.51V18H1163.51V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1170.51 6H1168.51V18H1170.51V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1177.5 6H1175.51V18H1177.5V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1184.5 6H1182.5V18H1184.5V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1191.5 0H1189.5V24H1191.5V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1198.5 6H1196.5V18H1198.5V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1205.49 6H1203.5V18H1205.49V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1212.49 6H1210.49V18H1212.49V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1219.49 6H1217.49V18H1219.49V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1226.48 6H1224.49V18H1226.48V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1233.48 6H1231.48V18H1233.48V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1240.48 6H1238.48V18H1240.48V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1247.48 6H1245.48V18H1247.48V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1254.47 6H1252.48V18H1254.47V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1261.47 0H1259.47V24H1261.47V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1268.47 6H1266.47V18H1268.47V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1275.46 6H1273.47V18H1275.46V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1282.46 6H1280.46V18H1282.46V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1289.46 6H1287.46V18H1289.46V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1296.46 6H1294.46V18H1296.46V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1303.45 6H1301.46V18H1303.45V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1310.45 6H1308.45V18H1310.45V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1317.45 6H1315.45V18H1317.45V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1324.44 6H1322.45V18H1324.44V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1331.44 0H1329.44V24H1331.44V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1338.44 6H1336.44V18H1338.44V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1345.43 6H1343.44V18H1345.43V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1352.43 6H1350.43V18H1352.43V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1359.43 6H1357.43V18H1359.43V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1366.43 6H1364.43V18H1366.43V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1373.42 6H1371.43V18H1373.42V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1380.42 6H1378.42V18H1380.42V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1387.42 6H1385.42V18H1387.42V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1394.41 6H1392.42V18H1394.41V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1401.41 0H1399.41V24H1401.41V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1408.41 6H1406.41V18H1408.41V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1415.41 6H1413.41V18H1415.41V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1422.4 6H1420.41V18H1422.4V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1429.4 6H1427.4V18H1429.4V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1436.4 6H1434.4V18H1436.4V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1443.39 6H1441.4V18H1443.39V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1450.39 6H1448.39V18H1450.39V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1457.39 6H1455.39V18H1457.39V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1464.38 6H1462.39V18H1464.38V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1471.38 0H1469.38V24H1471.38V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1478.38 6H1476.38V18H1478.38V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1485.38 6H1483.38V18H1485.38V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1492.37 6H1490.38V18H1492.37V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1499.37 6H1497.37V18H1499.37V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1506.37 6H1504.37V18H1506.37V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1513.36 6H1511.37V18H1513.36V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1520.36 6H1518.36V18H1520.36V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1527.36 6H1525.36V18H1527.36V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1534.36 6H1532.36V18H1534.36V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1541.35 0H1539.36V24H1541.35V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1548.35 6H1546.35V18H1548.35V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1555.35 6H1553.35V18H1555.35V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1562.34 6H1560.35V18H1562.34V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1569.34 6H1567.34V18H1569.34V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1576.34 6H1574.34V18H1576.34V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1583.33 6H1581.34V18H1583.33V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1590.33 6H1588.33V18H1590.33V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1597.33 6H1595.33V18H1597.33V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1604.33 6H1602.33V18H1604.33V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1611.32 0H1609.33V24H1611.32V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1618.32 6H1616.32V18H1618.32V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1625.32 6H1623.32V18H1625.32V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1632.31 6H1630.32V18H1632.31V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1639.31 6H1637.31V18H1639.31V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1646.31 6H1644.31V18H1646.31V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1653.31 6H1651.31V18H1653.31V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1660.3 6H1658.31V18H1660.3V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1667.3 6H1665.3V18H1667.3V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1674.3 6H1672.3V18H1674.3V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1681.29 0H1679.3V24H1681.29V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1688.29 6H1686.29V18H1688.29V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1695.29 6H1693.29V18H1695.29V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1702.29 6H1700.29V18H1702.29V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1709.28 6H1707.29V18H1709.28V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1716.28 6H1714.28V18H1716.28V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1723.28 6H1721.28V18H1723.28V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1730.27 6H1728.28V18H1730.27V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1737.27 6H1735.27V18H1737.27V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1744.27 6H1742.27V18H1744.27V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1751.26 0H1749.27V24H1751.26V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1758.26 6H1756.26V18H1758.26V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1765.26 6H1763.26V18H1765.26V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1772.26 6H1770.26V18H1772.26V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1779.25 6H1777.26V18H1779.25V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1786.25 6H1784.25V18H1786.25V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1793.25 6H1791.25V18H1793.25V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1800.24 6H1798.25V18H1800.24V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1807.24 6H1805.24V18H1807.24V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1814.24 6H1812.24V18H1814.24V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1821.24 0H1819.24V24H1821.24V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1828.23 6H1826.24V18H1828.23V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1835.23 6H1833.23V18H1835.23V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1842.23 6H1840.23V18H1842.23V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1849.22 6H1847.23V18H1849.22V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1856.22 6H1854.22V18H1856.22V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1863.22 6H1861.22V18H1863.22V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1870.21 6H1868.22V18H1870.21V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1877.21 6H1875.21V18H1877.21V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1884.21 6H1882.21V18H1884.21V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1891.21 0H1889.21V24H1891.21V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1898.2 6H1896.21V18H1898.2V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1905.2 6H1903.2V18H1905.2V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1912.2 6H1910.2V18H1912.2V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1919.19 6H1917.2V18H1919.19V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1926.19 6H1924.19V18H1926.19V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1933.19 6H1931.19V18H1933.19V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1940.19 6H1938.19V18H1940.19V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1947.18 6H1945.19V18H1947.18V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1954.18 6H1952.18V18H1954.18V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1961.18 0H1959.18V24H1961.18V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M1968.17 6H1966.18V18H1968.17V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1975.17 6H1973.17V18H1975.17V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1982.17 6H1980.17V18H1982.17V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1989.17 6H1987.17V18H1989.17V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M1996.16 6H1994.17V18H1996.16V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2003.16 6H2001.16V18H2003.16V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2010.16 6H2008.16V18H2010.16V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2017.15 6H2015.16V18H2017.15V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2024.15 6H2022.15V18H2024.15V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2031.15 0H2029.15V24H2031.15V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M2038.14 6H2036.15V18H2038.14V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2045.14 6H2043.14V18H2045.14V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2052.14 6H2050.14V18H2052.14V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2059.14 6H2057.14V18H2059.14V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2066.13 6H2064.14V18H2066.13V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2073.13 6H2071.13V18H2073.13V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2080.13 6H2078.13V18H2080.13V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2087.12 6H2085.13V18H2087.12V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2094.12 6H2092.12V18H2094.12V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2101.12 0H2099.12V24H2101.12V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M2108.12 6H2106.12V18H2108.12V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2115.11 6H2113.12V18H2115.11V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2122.11 6H2120.11V18H2122.11V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2129.11 6H2127.11V18H2129.11V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2136.1 6H2134.11V18H2136.1V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2143.1 6H2141.1V18H2143.1V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2150.1 6H2148.1V18H2150.1V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2157.09 6H2155.1V18H2157.09V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2164.09 6H2162.09V18H2164.09V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2171.09 0H2169.09V24H2171.09V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M2178.09 6H2176.09V18H2178.09V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2185.08 6H2183.09V18H2185.08V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2192.08 6H2190.08V18H2192.08V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2199.08 6H2197.08V18H2199.08V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2206.07 6H2204.08V18H2206.07V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2213.07 6H2211.07V18H2213.07V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2220.07 6H2218.07V18H2220.07V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2227.07 6H2225.07V18H2227.07V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2234.06 6H2232.07V18H2234.06V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2241.06 0H2239.06V24H2241.06V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M2248.06 6H2246.06V18H2248.06V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2255.05 6H2253.06V18H2255.05V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2262.05 6H2260.05V18H2262.05V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2269.05 6H2267.05V18H2269.05V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2276.04 6H2274.05V18H2276.04V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2283.04 6H2281.04V18H2283.04V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2290.04 6H2288.04V18H2290.04V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2297.04 6H2295.04V18H2297.04V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2304.03 6H2302.04V18H2304.03V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2311.03 0H2309.03V24H2311.03V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M2318.03 6H2316.03V18H2318.03V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2325.02 6H2323.03V18H2325.02V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2332.02 6H2330.02V18H2332.02V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2339.02 6H2337.02V18H2339.02V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2346.02 6H2344.02V18H2346.02V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2353.01 6H2351.02V18H2353.01V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2360.01 6H2358.01V18H2360.01V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2367.01 6H2365.01V18H2367.01V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2374 6H2372.01V18H2374V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2381 0H2379V24H2381V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M2388 6H2386V18H2388V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2395 6H2393V18H2395V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2401.99 6H2400V18H2401.99V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2408.99 6H2406.99V18H2408.99V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2415.99 6H2413.99V18H2415.99V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2422.98 6H2420.99V18H2422.98V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2429.98 6H2427.98V18H2429.98V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2436.98 6H2434.98V18H2436.98V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2443.97 6H2441.98V18H2443.97V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2450.97 0H2448.97V24H2450.97V0Z"
                            fill="#686868"
                        />
                        <path
                            d="M2457.97 6H2455.97V18H2457.97V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2464.97 6H2462.97V18H2464.97V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2471.96 6H2469.97V18H2471.96V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2478.96 6H2476.96V18H2478.96V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2485.96 6H2483.96V18H2485.96V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2492.95 6H2490.96V18H2492.95V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2499.95 6H2497.95V18H2499.95V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2506.95 6H2504.95V18H2506.95V6Z"
                            fill="#424242"
                        />
                        <path
                            d="M2513.95 6H2511.95V18H2513.95V6Z"
                            fill="#424242"
                        />
                    </svg>
                </StyledSlider>
            </SliderWrapper>
        </Container>
    );
};

export default RollSlider;

const Container = styled(Box)(() => ({
    width: '100%',
    height: '80px',

    display: 'flex',
    justifyContent: 'center',
}));

const SliderWrapper = styled(Box)(() => ({
    position: 'relative',

    height: '80px',
    width: '80%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    maskImage: ' -webkit-linear-gradient(left, rgba(196, 196, 196, 0) 20%, #FFFFFF 50%, rgba(196, 196, 196, 0) 80%)',
    cursor: 'pointer',
}));

const StyledSlider = styled(Box)<{ offset: number }>(({ offset }) => ({
    position: 'absolute',

    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '20px',
    gap: '8px',

    transform: `translateX(calc(-33px - ${offset}px))`,

    transition: 'all 0.3s',
}));

const MarkerWrapper = styled(Box)(() => ({
    position: 'relative',
    zIndex: 2,
    display: 'flex',
}));

const RollValueText = styled(Typography)(() => ({
    color: 'white',
    position: 'absolute',
    zIndex: 3,
    top: -3,
    fontSize: '28px',
    fontWeight: '500',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    userSelect: 'none' /* Standard */,
}));
