import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InformationIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            {...props}
        >
            <circle
                cx="9.19922"
                cy="9.2002"
                r="6.3625"
                stroke="currentColor"
                strokeWidth="1.275"
                fill={'none'}
            />
            <path
                d="M10 6.25C10 6.66421 9.66421 7 9.25 7C8.83579 7 8.5 6.66421 8.5 6.25C8.5 5.83579 8.83579 5.5 9.25 5.5C9.66421 5.5 10 5.83579 10 6.25Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 12L8.5 8.5C8.5 8.08579 8.83579 7.75 9.25 7.75C9.66421 7.75 10 8.08579 10 8.5L10 12C10 12.4142 9.66421 12.75 9.25 12.75C8.83579 12.75 8.5 12.4142 8.5 12Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
