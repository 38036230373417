type ratioType = { [key: string]: number };

const suggestedRatio: ratioType = {
    '2:1': 2 / 1,
    '16:9': 16 / 9,
    '7:4': 7 / 4,
    '3:2': 3 / 2,
    '4:3': 4 / 3,
    '5:4': 5 / 4,
    '1:1': 1 / 1,
    '4:5': 4 / 5,
    '3:4': 3 / 4,
    '2:3': 2 / 3,
    '4:7': 4 / 7,
    '9:16': 9 / 16,
    '1:2': 1 / 2,
};

export const getImageAspectRatio = (width: number, height: number): string => {
    const initialRatio = width / height;
    let currentSuggestedRatio = '';
    let minDifference = 1;

    for (const key in suggestedRatio) {
        const currentRatio = suggestedRatio[key];
        const difference = Math.abs(initialRatio - currentRatio);

        if (difference < minDifference) {
            minDifference = difference;
            currentSuggestedRatio = key;
        }
    }

    return currentSuggestedRatio;
};
