import { createTheme } from '@mui/material';
import Components from './ThemeComponents';
import ThemeTypography from './ThemeTypography';
import { themeBasic } from './ThemeBasic';

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#87B4EA',
        },
        info: {
            main: '#667296',
        },
        success: {
            main: '#87B4EA',
        },
        mode: 'light',
    },
    components: Components,
    typography: ThemeTypography,
    ...themeBasic,
});
