import React from 'react';
import { Box, styled } from '@mui/material';
import CurateImage from './CurateImage';
import CurateScene from './CurateScene';
import { useCurate } from '@root/hooks/curate/useCurate';
import { CurateTools } from '@root/utils/constants/enums';

export const CurateMainContent = () => {
    const { activeTool } = useCurate();
    return <Wrapper>{activeTool === CurateTools.SceneMode ? <CurateScene /> : <CurateImage />}</Wrapper>;
};

const Wrapper = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: 1,
}));
