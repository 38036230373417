import React, { PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';
import { UpscaleToolContextItems } from '@root/types/contextTypes';
import { UpscaleOptionDetails, UpscaleOptions, supportedUpscaleOptions } from '@root/utils/constants/enums';
import { ImageInfoForUpscale } from '@root/types/commonTypes';
import { CommonConstants } from '@root/utils/constants';

const UpscaleToolContext = createContext<UpscaleToolContextItems | null>(null);

export const UpscaleToolProvider = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    const [imageForUpscale, setImageForUpscale] = useState<ImageInfoForUpscale | null>(null);
    const [upscaleOptionsList, setUpscaleOptionsList] = useState<UpscaleOptionDetails[] | null>(null);

    useEffect(() => {
        if (!imageForUpscale) {
            return;
        }

        const updatedOptions = getUpscaleOptions();
        setUpscaleOptionsList(updatedOptions);
    }, [imageForUpscale]);

    const getUpscaleOptions = (): UpscaleOptionDetails[] | null => {
        if (!imageForUpscale) {
            return null;
        }

        const currentPixelCount = imageForUpscale.width * imageForUpscale.height;
        const isUpscaleEnable = currentPixelCount <= CommonConstants.IMAGE_MAX_SIZE_FOR_UPSCALE;
        const upscaleOptions = Object.values(supportedUpscaleOptions).map((value) => value?.scaleFactor);

        const updatedOptions: UpscaleOptionDetails[] = upscaleOptions.map((option: UpscaleOptions) => ({
            scaleFactor: option,
            label: supportedUpscaleOptions[option].label,
            enabled: isUpscaleEnable,
        }));

        return updatedOptions;
    };

    const value: UpscaleToolContextItems = {
        imageForUpscale,
        setImageForUpscale,
        upscaleOptionsList,
    } as UpscaleToolContextItems;

    return <UpscaleToolContext.Provider value={value}>{children}</UpscaleToolContext.Provider>;
};

export const useUpscaleTool = (): UpscaleToolContextItems => {
    const context = useContext(UpscaleToolContext);

    if (context === null) {
        throw new Error('useUpscaleTool cannot be used without its provider');
    }

    return context;
};
