import React from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import { CameraViewErrorPanelProps } from './CameraViewErrorPanel.types';
import { Model3dError } from '@root/utils/constants/enums';

const CameraViewErrorPanel = ({ errorType, submitAction }: CameraViewErrorPanelProps): React.JSX.Element => {
    return (
        <PanelWrapper>
            <InfoTypography>{Model3dError[errorType]?.errorMessage}</InfoTypography>
            <InfoTypography>{Model3dError[errorType]?.proposedAction}</InfoTypography>
            <ConfirmationButton
                variant="contained"
                onClick={submitAction}
            >
                {'OK'}
            </ConfirmationButton>
        </PanelWrapper>
    );
};

export default CameraViewErrorPanel;

const PanelWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '300px',
    height: '103px',
    padding: '14px',

    backgroundColor: theme.palette.primary.light,

    borderRadius: '6px',
    overflow: 'hidden',
    cursor: 'inherit',
}));

const InfoTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '20px',

    color: theme.palette.primary.contrastText,
}));

const ConfirmationButton = styled(Button)(() => ({
    margin: '14px 0px',
    width: '87px ',
    height: '30px',
    alignSelf: 'center',
    position: 'absolute',
    bottom: 0,
}));
