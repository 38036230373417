import React, { useEffect } from 'react';
import { Snackbar, Typography } from '@mui/material';
import { CssAlert } from '../assets/theme/ThemeComponents';
import { useNotifications } from '@context/NotificationsContext/useNotifications';

const Notification = ({ notification }) => {
    const { deleteNotification } = useNotifications();

    const closeAlert = () => {
        deleteNotification(notification.id);
    };

    useEffect(() => {
        const timeout = setTimeout(() => deleteNotification(notification.id), 6000);
        return () => clearTimeout(timeout);
    }, []);
    return (
        <Snackbar
            open={notification.state}
            sx={{ position: 'static' }}
        >
            <CssAlert
                onClose={closeAlert}
                sx={{
                    width: '472px',
                    height: 'max-containe',
                    '& svg': {
                        fontSize: '24px',
                    },
                }}
                severity="info"
            >
                <Typography
                    variant="body24"
                    sx={{ textTransform: 'capitalize' }}
                >
                    {notification.title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Roboto300',
                        fontSize: '16px',
                    }}
                >
                    {notification.text}
                </Typography>
            </CssAlert>
        </Snackbar>
    );
};

export default Notification;
