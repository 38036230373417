import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Modal, TextField, Typography, styled } from '@mui/material';
import GenerateContext from '../../../../context/GenerateContext/GenerateContext';
import ProjectsContext from '../../../../context/ProjectsContext/ProjectsContext';
import { useModal } from '../../../../context/ModalContext/useModal';
import { NavigationPaths, RequestErrorCode } from '@root/utils/constants/enums';
import { ErrorMessages, ProjectConstants } from '@root/utils/constants';
import { ErrorTooltip } from '@root/components/ErrorTooltip';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const CreateNewProjectModal = (): React.JSX.Element => {
    const navigate = useNavigate();

    const { createProjectModalState, handleCloseModal } = useModal();
    const { checkLicenseStatus } = useLicenseValidation();
    const { setDefaultSettings } = useContext(GenerateContext);
    const { createNewProject, validateProjectName } = useContext(ProjectsContext);

    const [newProjectName, setNewProjectName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleNewProject = async (): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        const newProject = {
            name: `${newProjectName}`,
        };

        const result = await createNewProject(newProject);

        if (result?.errorCode) {
            setErrorMessage(
                result?.errorCode === RequestErrorCode.PROJECT_ALREADY_EXISTS
                    ? ErrorMessages.PROJECT_NAME_ERROR_MESSAGE.ALREADY_EXISTS
                    : ErrorMessages.PROJECT_NAME_ERROR_MESSAGE.CREATE_ERROR
            );
        } else if (result.Id) {
            setNewProjectName('');

            handleCloseModal();
            setDefaultSettings();
            navigate(`/${NavigationPaths.CreatePage}/${result.Id}`);
        }
    };

    const onProjectCreateButton = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault();

        const validationErrorMessage = validateProjectName(newProjectName);
        if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
            return;
        }

        handleNewProject();
    };

    const onProjectNameInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (errorMessage) {
            setErrorMessage('');
        }

        setNewProjectName(event.target.value);
    };

    const blurHandler = (): void => {
        const validationErrorMessage = validateProjectName(newProjectName);

        if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
        }
    };

    const onModalClose = (): void => {
        setErrorMessage('');
        handleCloseModal();
    };

    return (
        <CreateModal
            open={createProjectModalState}
            onClose={onModalClose}
        >
            <form
                id="createProjectForm"
                onSubmit={onProjectCreateButton}
            >
                <FormWrapper>
                    <FormContent>
                        <ModalTitle> {'Create new project'} </ModalTitle>
                        <ErrorTooltip
                            className="modal-input"
                            title={errorMessage}
                            placement="right"
                            open={!!errorMessage}
                            arrow
                        >
                            <NameInput
                                helperText={`Project name: ${
                                    newProjectName.length === ProjectConstants.PROJECT_NAME_MAX_LENGTH
                                        ? '0'
                                        : ProjectConstants.PROJECT_NAME_MAX_LENGTH - newProjectName.length
                                }/${ProjectConstants.PROJECT_NAME_MAX_LENGTH}`}
                                inputProps={{ maxLength: ProjectConstants.PROJECT_NAME_MAX_LENGTH }}
                                size="small"
                                variant="outlined"
                                placeholder="New project"
                                value={newProjectName}
                                onBlur={blurHandler}
                                onChange={onProjectNameInputChange}
                            />
                        </ErrorTooltip>
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            {'Create'}
                        </Button>
                    </FormContent>
                </FormWrapper>
            </form>
        </CreateModal>
    );
};

export default CreateNewProjectModal;

const CreateModal = styled(Modal)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const FormWrapper = styled(Box)(() => ({
    width: '500px',
    height: '300px',
    background: '#292929',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '10px',
    borderRadius: '6px',
}));

const FormContent = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    border: '1px dotted #ffffff20',
    padding: '40px',
}));

const ModalTitle = styled(Typography)(() => ({
    fontFamily: 'Roboto400',
    fontSize: '24px',
    lineHeight: '30px',
    '@media (max-width:1500px)': {
        fontSize: '18px',
        lineHeight: '24px',
    },
}));

const NameInput = styled(TextField)(() => ({
    width: '70%',

    '& input': {
        height: '24px',
        padding: '9px 14px',
    },
}));
