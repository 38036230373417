import React from 'react';
import { Tooltip, styled } from '@mui/material';

export const ErrorTooltip = styled(({ className, ...props }: any) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    '&.form-menu-input': {
        '& .MuiTooltip-tooltip': {
            height: '40px',
            marginTop: '20px',
            marginLeft: '-15px',
        },
    },
    '&.modal-input': {
        '& .MuiTooltip-tooltip': {
            marginBottom: '25px',
        },
    },
    '& .MuiTooltip-tooltip': {
        height: '33px',
        backgroundColor: theme.palette.error.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .MuiTooltip-arrow': {
        color: theme.palette.error.main,
    },
}));
