import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfoMenuIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 25"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.25 7.5C5.25 7.08579 5.58579 6.75 6 6.75H18C18.4142 6.75 18.75 7.08579 18.75 7.5C18.75 7.91421 18.4142 8.25 18 8.25H6C5.58579 8.25 5.25 7.91421 5.25 7.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.25 12.5C5.25 12.0858 5.58579 11.75 6 11.75H18C18.4142 11.75 18.75 12.0858 18.75 12.5C18.75 12.9142 18.4142 13.25 18 13.25H6C5.58579 13.25 5.25 12.9142 5.25 12.5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.25 17.5C9.25 17.0858 9.58579 16.75 10 16.75H18C18.4142 16.75 18.75 17.0858 18.75 17.5C18.75 17.9142 18.4142 18.25 18 18.25H10C9.58579 18.25 9.25 17.9142 9.25 17.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
