import React from 'react';
import { Box, styled } from '@mui/material';
import { Typography } from '@mui/material';
import DragAndDropPanel from '@root/components/DragAndDropPanel';
import InfluenceSlider from '@root/components/InfluenceSlider';
import { ReferenceImageProps } from './ReferenceImageField.types';

const ReferenceImageField = ({ setInfluenceImage, influenceImage, influence, setInfluence }: ReferenceImageProps): JSX.Element => {
    return (
        <FieldWrapper>
            <SubTitle variant={'subtitle2'}>drive your style</SubTitle>
            <Wrapper>
                <DragAndDropPanel
                    enabled={true}
                    OnUpload={setInfluenceImage}
                    loadedImageUrl={influenceImage ? URL.createObjectURL(influenceImage) : ''}
                ></DragAndDropPanel>
            </Wrapper>
            <InfluenceSlider
                id={'style influence'.replace(/\s/g, '')}
                value={influence}
                OnChange={setInfluence}
                label={'style Influence'}
                step={1}
                range={[0, 100]}
                endAdornment={'%'}
            ></InfluenceSlider>
        </FieldWrapper>
    );
};

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
}));

const FieldWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '300px',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.neutrals.inactive1,
}));

export default ReferenceImageField;
