import React, { useEffect, useState } from 'react';
import { Box, Slider, styled } from '@mui/material';
import { useCurate } from '@root/hooks/curate/useCurate';
import { BrushIcon } from '@root/assets/icons/BrushIcon';
import { EraserIcon } from '@root/assets/icons/EraserIcon';
import { IconButton } from '@pages/CuratePage/components/ToolPanel/IconButton';
import { CuratePageConstants } from '@root/utils/constants';
import { AddNewCanvasIcon } from '@root/assets/icons/AddNewCanvasIcon';
import CreateNewSketchModal from '../CreateNewSketchModal';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { HotKeysList, SupportedHotKeys } from '@root/utils/constants/enums';
import BrushSlider from '@root/components/BrushSlider';

const BrushDrawingSettings = () => {
    const { color, setColor, brushSize, onBrushSizeChange, eraserSize, isEraserSelected, setIsEraserSelected } = useCurate();

    const switchBrush = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_B].key,
        SupportedHotKeys[HotKeysList.CTRL_B].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_B].blockDefaultEvent
    );
    const showModal = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_L].key,
        SupportedHotKeys[HotKeysList.CTRL_L].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_L].blockDefaultEvent
    );

    const [modalState, setModalState] = useState(false);

    const handleShadeChange = (e) => {
        const value = parseInt(e.target.value);
        const hexValue = value.toString(16).padStart(2, '0');
        const newShade = `#${hexValue}${hexValue}${hexValue}`;
        setColor(newShade);
    };

    const updateEraserState = (state) => {
        setIsEraserSelected(state);
    };

    useEffect(() => {
        if (switchBrush) {
            updateEraserState(!isEraserSelected);
        }
        if (showModal) {
            setModalState(true);
        }
    }, [switchBrush, showModal]);

    return (
        <>
            <BrushToolWrapper>
                <span>
                    <AddNewCanvasButton
                        disableRipple
                        onClick={() => setModalState(true)}
                    >
                        <AddNewCanvasIcon />
                    </AddNewCanvasButton>
                </span>
                <span>
                    <ToolButton
                        disableRipple
                        onClick={() => updateEraserState(true)}
                        active={isEraserSelected ? 1 : 0}
                    >
                        <EraserIcon />
                    </ToolButton>
                </span>
                <span>
                    <ToolButton
                        disableRipple
                        onClick={() => updateEraserState(false)}
                        active={!isEraserSelected ? 1 : 0}
                    >
                        <BrushIcon />
                    </ToolButton>
                </span>
            </BrushToolWrapper>
            <BrushSlider
                name="brushSize"
                value={isEraserSelected ? eraserSize : brushSize}
                step={1}
                min={1}
                max={100}
                onChange={onBrushSizeChange}
            />
            <PaletteWrapper disabled={isEraserSelected}>
                <ColorSlider
                    track={false}
                    id="brushColor"
                    name="brushColor"
                    isDisabled={isEraserSelected}
                    value={
                        isEraserSelected
                            ? parseInt(CuratePageConstants.DEFAULT_ERASER_COLOR.substring(1, 3), 16)
                            : parseInt(color.substring(1, 3), 16)
                    }
                    min={0}
                    max={255}
                    step={1}
                    valueLabelDisplay="off"
                    onChange={handleShadeChange}
                />
            </PaletteWrapper>
            <CreateNewSketchModal
                open={modalState}
                close={() => setModalState(false)}
            />
        </>
    );
};

export default BrushDrawingSettings;

const BrushToolWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginRight: '23px',
    gap: '9px',
}));

const PaletteWrapper = styled(Box)(({ theme, disabled }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100px',
    height: '14px',

    borderRadius: '15px',
    border: `1px solid ${theme.palette.primary.light1}`,

    background: `linear-gradient(270deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.contrastText})`,
    opacity: disabled ? '0.2' : '1',
}));

const ColorSlider = styled(Slider)(({ theme, isDisabled }) => ({
    width: '88px',
    height: '14px',

    padding: '0',
    marginLeft: '0',

    color: 'transparent',
    pointerEvents: isDisabled ? 'none' : 'auto',

    '& .MuiSlider-thumb': {
        color: theme.palette.primary.cta2,

        width: '11px',
        height: '11px',

        padding: '6px',
        border: `1px solid ${theme.palette.primary.inactive}`,

        '&:hover, &.Mui-focusVisible': {
            boxShadow: 'none',
        },
        '&.Mui-active': {
            boxShadow: 'none',
        },
    },
}));

const AddNewCanvasButton = styled(IconButton)(() => ({
    marginRight: '15px',

    '& svg': {
        width: '24px',
        height: '24px',
    },
}));

const ToolButton = styled(IconButton)(() => ({
    '& svg': {
        width: '24px',
        height: '24px',
    },
}));
