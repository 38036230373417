import axios from 'axios';
import { TokenUpdate } from './TokenUpdate';
import { LocalStorageKeys } from '@root/utils/constants';
import { clearAuthStorage } from '@utils/auth/clearAuthStorage';

axios.defaults.baseURL = window._env_.REACT_APP_BASE_API_URL;

if (localStorage[LocalStorageKeys.ACCESS_TOKEN_KEY]) {
    TokenUpdate();
}

axios.interceptors.response.use(
    (resp) => resp,
    async (error) => {
        if (error.response && error.response.status === 401 && !error.config.refreshed) {
            error.config.refreshed = true;
            const token = localStorage[LocalStorageKeys.REFRESH_TOKEN_KEY];

            const response = await axios.post(
                '/auth/refresh',
                {},
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN_KEY, response.data.access_token);
                TokenUpdate();

                error.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;

                return axios(error.config);
            } else if (error.config.url !== '/auth/login') {
                clearAuthStorage();
                window.location.href = '/';
            }
        }
        return error.response;
    }
);
