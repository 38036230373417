import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { RestartAlt } from '@mui/icons-material';

import { useCurate } from '@root/hooks/curate/useCurate';

import DragAndDropFileUploader from '@root/components/DragAndDropFileUploader';
import SceneModeFooter from './SceneModeFooter';

import { MAX_FILE_SIZE_3D_VIWER } from '@utils/constants/cameraViewConstants';
import { Model3dErrorCode } from '@root/utils/constants/enums';

const CurateScene = (): React.JSX.Element => {
    const { isLoading, loadedModel, addModelToScene, initScene, isSceneReady, recenterCamera } = useCurate();

    const sceneRef = useRef<HTMLDivElement | null>(null);

    const [errorType, setErrorType] = useState<Model3dErrorCode | null>(null);

    useEffect(() => {
        if (!sceneRef.current) {
            return;
        }

        initScene(sceneRef.current);
    }, [loadedModel]);

    return (
        <>
            <Wrapper>
                <ImagePlaceholder>
                    {loadedModel ? (
                        <>
                            {!isSceneReady && (
                                <Loader>
                                    <span className="loader"></span>
                                </Loader>
                            )}
                            <Scene ref={sceneRef}></Scene>
                            {isSceneReady && (
                                <ButtonWrapper>
                                    <IconButton onClick={recenterCamera}>
                                        <RestartAlt />
                                    </IconButton>
                                </ButtonWrapper>
                            )}
                        </>
                    ) : (
                        <UploaderWrapper>
                            <DragAndDropFileUploader
                                addModel={addModelToScene}
                                currentFileName={'uploadedFileName'}
                                onError={setErrorType}
                                isLoading={isLoading}
                                maxFileSize={MAX_FILE_SIZE_3D_VIWER}
                            />
                        </UploaderWrapper>
                    )}
                </ImagePlaceholder>
            </Wrapper>
            <SceneModeFooter />
        </>
    );
};

export default CurateScene;

const Wrapper = styled(Box)(({ theme }) => ({
    flex: 3,
    height: 'calc(100% - 285px)',

    marginTop: '19px',
    marginBottom: '30px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ImagePlaceholder = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100%',
    width: '80%',
    objectPosition: 'center',
    objectFit: 'contain',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
}));

const ButtonWrapper = styled(Box)(() => ({
    display: 'flex',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
}));

const Loader = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Scene = styled(Box)(() => ({
    width: '100%',
    height: '100%',
}));

const UploaderWrapper = styled(Box)(() => ({
    position: 'relative',
    width: '520px',
    height: '265px',
}));
