import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, MenuItem, styled } from '@mui/material';
import { BrushGenerationSettingsProps } from './GeneratePageForm.types';
import { FormWrapper } from '@root/components/FormWrapper';
import PromptSection from '@root/components/PromptSection';
import InfluenceSlider from '@root/components/InfluenceSlider';
import GenerateContext from '@root/context/GenerateContext/GenerateContext';
import AspectRatioField from './components/AspectRatioField';
import FormCheckbox from '@root/components/FormCheckbox';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import ExpandMenuButton from '@root/components/ExpandMenuButton';
import SelectField from '@root/components/SelectField';
import CameraView from './components/CameraView';
import ToggleField from '@root/components/ToggleField';
import ControlImage from './components/ControlImage';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { ControlImageOption, HotKeysList, ModelForGenerationType, SupportedHotKeys } from '@root/utils/constants/enums';

const GeneratePageForm = ({ onSubmit }: BrushGenerationSettingsProps) => {
    const [isActive, setIsActive] = useState(true);

    const {
        enableHighResolution,
        onEnableHighResolutionInputChange,
        numberOfImages,
        onNumberOfImagesInputChange,
        controlDepthImage,
        controlDepthWeight,
        onControlDepthImageChange,
        controlSketchImage,
        controlSketchWeight,
        onControlSketchImageChange,
        setControlDepthWeight,
        setControlSketchWeight,
        modelForGeneration,
        onModelForGenerationInputChange,
        prompt,
        onPromptInputChange,
        seed,
        onSeedInputChange,
        onInputSeed,
        negativePrompt,
        onNegativePromptInputChange,
        selectedModelType,
        setSelectedModelType,
        changeControlImageOption,
        controlImageOption,
    } = useContext(GenerateContext);
    const { trainedModelsList } = useContext(ModelsContext);

    const menuActionHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_H].key,
        SupportedHotKeys[HotKeysList.CTRL_H].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_H].blockDefaultEvent
    );
    const generateHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_G].key,
        SupportedHotKeys[HotKeysList.CTRL_G].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_G].blockDefaultEvent
    );

    const buttonAction = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        if (menuActionHotKey) {
            buttonAction();
        }
    }, [menuActionHotKey]);

    useEffect(() => {
        if (generateHotKey && seed && negativePrompt && prompt) {
            onSubmit();
        }
    }, [generateHotKey]);

    return (
        <Wrapper>
            <FormWrapper
                id="generateForm"
                component="form"
                active={isActive ? 1 : 0}
                onSubmit={onSubmit}
            >
                <Content active={isActive ? 1 : 0}>
                    {isActive && (
                        <PromptSection
                            prompt={prompt}
                            onPromptInputChange={onPromptInputChange}
                            negativePrompt={negativePrompt}
                            onNegativePromptInputChange={onNegativePromptInputChange}
                            seed={seed}
                            onSeedInputChange={onSeedInputChange}
                            onInputSeed={onInputSeed}
                            isNegativePromptDisabled={selectedModelType !== ModelForGenerationType.CUSTOM}
                        ></PromptSection>
                    )}

                    <ModelTypeSection>
                        <ToggleField
                            selectedOption={selectedModelType}
                            optionsList={Object.values(ModelForGenerationType)}
                            isRequired={true}
                            setSelectedOption={setSelectedModelType}
                        />
                    </ModelTypeSection>

                    {selectedModelType === ModelForGenerationType.CUSTOM && (
                        <>
                            <SelectField
                                id="modelForGeneration"
                                name="modelForGeneration"
                                value={modelForGeneration}
                                onChange={onModelForGenerationInputChange}
                                label={'model for generation'}
                                isRequired={true}
                                isDisabled={false}
                                content={trainedModelsList.map((model: any) => {
                                    return (
                                        <MenuItem
                                            key={model.Id}
                                            value={model.Id}
                                        >
                                            {model.Name.replaceAll('.safetensors', '')}
                                        </MenuItem>
                                    );
                                })}
                            />
                            <InfluenceSlider
                                id="numberOfImages"
                                value={numberOfImages}
                                OnChange={onNumberOfImagesInputChange}
                                label={'number of images'}
                                step={1}
                                range={[1, 8]}
                            ></InfluenceSlider>
                        </>
                    )}
                    <ImageSettingsSection>
                        <AspectRatioField />
                        {selectedModelType === ModelForGenerationType.CUSTOM && (
                            <FormCheckbox
                                id={'enable high resolution'}
                                label={'enable high resolution'}
                                checked={enableHighResolution}
                                onChange={onEnableHighResolutionInputChange}
                            />
                        )}
                    </ImageSettingsSection>

                    <ControlImageSection active={selectedModelType === ModelForGenerationType.CUSTOM}>
                        <ToggleField
                            selectedOption={controlImageOption}
                            optionsList={Object.values(ControlImageOption)}
                            isRequired={false}
                            setSelectedOption={changeControlImageOption}
                        />

                        <ControlImage
                            id={'control depth image'}
                            enabled={controlImageOption === ControlImageOption.PHOTO}
                            image={controlDepthImage}
                            weight={controlDepthWeight}
                            onWeightChange={setControlDepthWeight}
                            onImageChange={onControlDepthImageChange}
                        />

                        <ControlImage
                            id={'control sketch image'}
                            enabled={controlImageOption === ControlImageOption.SKETCH}
                            image={controlSketchImage}
                            weight={controlSketchWeight}
                            onWeightChange={setControlSketchWeight}
                            onImageChange={onControlSketchImageChange}
                        />
                        <CameraView
                            weight={controlDepthWeight}
                            onWeightChange={setControlDepthWeight}
                        />
                    </ControlImageSection>
                </Content>
                <Footer>
                    <GenerateButton
                        type="submit"
                        variant="contained"
                        disabled={!seed || !negativePrompt || !prompt}
                    >
                        GENERATE
                    </GenerateButton>
                </Footer>
            </FormWrapper>
            <ExpandMenuButton
                isActive={isActive}
                buttonAction={buttonAction}
            />
        </Wrapper>
    );
};

export default GeneratePageForm;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    maxWidth: '413px',
    flex: 1,
}));

const Footer = styled(Box)(() => ({
    display: 'flex',
    height: '94px',
    padding: '25px 0px',
}));

const Content = styled(Box)<{ active: number }>(({ active }) => ({
    gap: '32px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    overflowY: 'auto',
    overflowX: 'hidden',
    opacity: !active ? 0 : 1,
}));

const ImageSettingsSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
}));

const ModelTypeSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '300px',
}));

const ControlImageSection = styled(Box)<{ active: boolean }>(({ active }) => ({
    display: active ? 'flex' : 'none',
    flexDirection: 'column',
    width: '300px',
    gap: '13px',
}));

const GenerateButton = styled(Button)(() => ({
    width: '300px',
    transition: 'all 0.5s',
}));
