import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const JobEnqueuedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}
        >
            <g clipPath="url(#clip0_1515_13428)">
                <g mask="url(#mask0_1515_13428)">
                    <path
                        d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
                        stroke="#686868"
                        strokeWidth="1.5"
                        fill="none"
                    />
                    <path
                        d="M5.39062 7.5C5.39062 7.38811 5.43507 7.28081 5.51419 7.20169C5.59331 7.12257 5.70061 7.07813 5.8125 7.07812H18.1875C18.2994 7.07813 18.4067 7.12257 18.4858 7.20169C18.5649 7.28081 18.6094 7.38811 18.6094 7.5C18.6094 7.61189 18.5649 7.71919 18.4858 7.79831C18.4067 7.87743 18.2994 7.92188 18.1875 7.92188H5.8125C5.70061 7.92188 5.59331 7.87743 5.51419 7.79831C5.43507 7.71919 5.39063 7.61189 5.39062 7.5ZM12.5625 11.5781H5.8125C5.70061 11.5781 5.59331 11.6226 5.51419 11.7017C5.43507 11.7808 5.39063 11.8881 5.39062 12C5.39063 12.1119 5.43507 12.2192 5.51419 12.2983C5.59331 12.3774 5.70061 12.4219 5.8125 12.4219H12.5625C12.6744 12.4219 12.7817 12.3774 12.8608 12.2983C12.9399 12.2192 12.9844 12.1119 12.9844 12C12.9844 11.8881 12.9399 11.7808 12.8608 11.7017C12.7817 11.6226 12.6744 11.5781 12.5625 11.5781ZM12.5625 16.0781H5.8125C5.70061 16.0781 5.59331 16.1226 5.51419 16.2017C5.43507 16.2808 5.39063 16.3881 5.39062 16.5C5.39063 16.6119 5.43507 16.7192 5.51419 16.7983C5.59331 16.8774 5.70061 16.9219 5.8125 16.9219H12.5625C12.6744 16.9219 12.7817 16.8774 12.8608 16.7983C12.9399 16.7192 12.9844 16.6119 12.9844 16.5C12.9844 16.3881 12.9399 16.2808 12.8608 16.2017C12.7817 16.1226 12.6744 16.0781 12.5625 16.0781ZM20.2969 14.25C20.2969 14.3216 20.2787 14.392 20.244 14.4546C20.2093 14.5172 20.1593 14.57 20.0986 14.6079L15.5986 17.4204C15.5347 17.4603 15.4613 17.4824 15.386 17.4844C15.3107 17.4863 15.2363 17.4681 15.1704 17.4316C15.1046 17.3951 15.0497 17.3416 15.0115 17.2767C14.9732 17.2118 14.9531 17.1378 14.9531 17.0625V11.4375C14.9531 11.3622 14.9732 11.2882 15.0115 11.2233C15.0497 11.1584 15.1046 11.1049 15.1704 11.0684C15.2363 11.0319 15.3107 11.0137 15.386 11.0156C15.4613 11.0176 15.5347 11.0397 15.5986 11.0796L20.0986 13.8921C20.1593 13.93 20.2093 13.9828 20.244 14.0454C20.2787 14.108 20.2969 14.1784 20.2969 14.25ZM19.0791 14.25L15.7969 12.199V16.301L19.0791 14.25Z"
                        fill="currentColor"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1515_13428">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
