import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddImageIngredientIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={'0 0 66 61'}
            {...props}
        >
            <path
                d="M55.61 32.5V20.379C55.6138 18.7833 55.1905 17.2156 54.3839 15.8387C53.5773 14.4619 52.4168 13.326 51.023 12.549L33.152 2.62801C31.8226 1.88825 30.3264 1.5 28.805 1.5C27.2837 1.5 25.7874 1.88825 24.458 2.62801L6.58703 12.546C5.19279 13.3232 4.03207 14.4596 3.22544 15.837C2.41881 17.2145 1.99569 18.7828 2.00003 20.379V39.621C1.99622 41.2167 2.4196 42.7844 3.2262 44.1613C4.0328 45.5381 5.19323 46.674 6.58703 47.451L24.458 57.372C25.7874 58.1118 27.2837 58.5 28.805 58.5C30.3264 58.5 31.8226 58.1118 33.152 57.372L42.302 52.294"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                fill="none"
            />
            <path
                d="M53.792 14.9883L28.802 30.0003M28.802 30.0003L3.81201 14.9883M28.802 30.0003V58.4703"
                stroke="currentColor"
                strokeWidth="3"
            />
            <path
                d="M63.802 47.3333L55.302 39M55.302 39L46.802 47.3333M55.302 39V59"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
