import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useAdminApi } from '@root/hooks/api/useAdminApi';
import { UserInfoDto } from '@root/types/dto';
import { sortByExpirationDate } from '@root/utils/helpers';
import { UsersListDefaults } from '@root/utils/constants';
import UsersListRow from './components';
import { EditModeAction } from '@root/utils/constants/enums';
import { AddIcon } from '@root/assets/icons/AddIcon';

const UsersList = (): React.JSX.Element => {
    const { getAll } = useAdminApi();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
    const [usersListUpdated, setUsersListUpdated] = useState<boolean>(false);
    const [activeRowId, setActiveRowId] = useState<string>('');
    const [users, setUsers] = useState<UserInfoDto[]>([]);

    useEffect(() => {
        getAll().then((users) => {
            if ((users as UserInfoDto[])?.length) {
                const sortedUsers = sortByExpirationDate(users as UserInfoDto[]);
                setUsers(sortedUsers);
            } else {
                setUsers([]);
            }
        });
    }, []);

    useEffect(() => {
        if (usersListUpdated) {
            getAll().then((users) => {
                if ((users as UserInfoDto[])?.length) {
                    const sortedUsers = sortByExpirationDate(users as UserInfoDto[]);
                    setUsers(sortedUsers);
                    setUsersListUpdated(false);
                } else {
                    setUsers([]);
                    setUsersListUpdated(false);
                }
                setUsersListUpdated(false);
            });
        }
    }, [usersListUpdated]);

    const startCreateMode = (): void => {
        setIsEditMode(true);
        setIsCreateMode(true);
        setActiveRowId(UsersListDefaults.DEFAULT_USER_INFO.id);
    };

    const getCreateUserRow = (): React.JSX.Element => (
        <UsersListRow
            isEditModeActive={isEditMode}
            toggleEditMode={setIsEditMode}
            activeRowId={activeRowId}
            setActiveRowId={setActiveRowId}
            userInfo={UsersListDefaults.DEFAULT_USER_INFO}
            isCreateMode={isCreateMode}
            setIsCreateMode={setIsCreateMode}
            editActionType={EditModeAction.Create}
            setUsersListUpdated={setUsersListUpdated}
        />
    );

    return (
        <List>
            <ListHeader>
                <HeaderLabel>{'User name'}</HeaderLabel>
                <HeaderLabel>{'Password'}</HeaderLabel>
                <HeaderLabel>{'Expiration date'}</HeaderLabel>
                <HeaderLabel>{'Role'}</HeaderLabel>
            </ListHeader>

            <ItemRow>
                <AddIcon onClick={startCreateMode} />
            </ItemRow>

            <Items>
                {isCreateMode && getCreateUserRow()}
                {users?.length ? (
                    users.map((userInfo, i) => (
                        <UsersListRow
                            key={i}
                            isEditModeActive={isEditMode}
                            toggleEditMode={setIsEditMode}
                            activeRowId={activeRowId}
                            setActiveRowId={setActiveRowId}
                            userInfo={userInfo}
                            isCreateMode={isCreateMode}
                            setIsCreateMode={setIsCreateMode}
                            editActionType={EditModeAction.Update}
                            setUsersListUpdated={setUsersListUpdated}
                        />
                    ))
                ) : (
                    <></>
                )}
            </Items>
        </List>
    );
};

export default UsersList;

const List = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    width: '1300px',

    border: `1px solid ${theme.palette.primary.light}`,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',

    marginLeft: '24px',
    marginRight: '24px',
    marginBottom: '24px',
}));

const ItemRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    paddingLeft: '50px',
    paddingRight: '30px',

    width: '100%',
    height: '55px',

    borderBottom: `1px solid ${theme.palette.primary.light}`,
}));

const ListHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    paddingLeft: '50px',
    height: '55px',
    gap: '250px',

    backgroundColor: theme.palette.primary.light,
}));

const Items = styled(Stack)(() => ({
    overflowX: 'auto',
}));

const HeaderLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    fontSize: '16px',
    lineHeight: '18px',

    marginBottom: '6px',
    color: theme.palette.neutrals.light1,
}));
