import React, { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';
import { SceneMode } from '@root/utils/constants/enums';
import PanoramicView from '@root/lib/PanoramicView';
import { ScenePanoramaProps } from './ScenePanorama.types';
import { prefixBaseUrl } from '@root/utils/url/url';

const ScenePanorama = ({
    eraserSize,
    activeImageUrl,
    activeMode,
    setIsLoading,
    setEraserMask,
    isDrawingBlocked = false,
}: ScenePanoramaProps): React.JSX.Element => {
    const container = useRef<HTMLDivElement>(null);
    const canvasElement = useRef<HTMLCanvasElement>(null);

    const [threeScene, setThreeScene] = useState<any | null>(null);
    const [isCameraDisabled, setIsCameraDisabled] = useState<boolean>(false);
    const [isSpaceDown, setIsSpaceDown] = useState<boolean>(false);
    const [isShiftDown, setIsShiftDown] = useState<boolean>(false);
    const [texturePath, setTexturePath] = useState<string>('');

    const loadPanorama = (path?: string): void => {
        setIsLoading(true);
        threeScene.loadTexture(path || texturePath, setIsLoading);
    };

    const showPanorama = (): void => {
        if (activeImageUrl) {
            const path = `${prefixBaseUrl(activeImageUrl)}?${Date.now()}`;
            setTexturePath(path);
            loadPanorama(path);
        }
    };

    const onResize = (): void => {
        if (!container.current?.clientWidth || !container.current?.clientHeight) {
            return;
        }

        threeScene.onWindowResize(container.current.clientWidth, container.current.clientHeight);
    };

    const handleCanvasInput = (event?: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): void => {
        if (!threeScene || isCameraDisabled || isDrawingBlocked) {
            return;
        }
        threeScene.onMouseDown(event);

        document.addEventListener('mouseup', onMouseUpHandler);
        document.addEventListener('touchend', onMouseUpHandler);
    };

    const onMouseMove = (event?: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): void => {
        if (!threeScene || isCameraDisabled || isDrawingBlocked) {
            if (isDrawingBlocked) {
                threeScene.updatePinterVisibility(false);
            }
            return;
        }

        threeScene.onMouseMove(event);
    };

    const onMouseUpHandler = (event: MouseEvent | TouchEvent): void => {
        if (!threeScene || isCameraDisabled || isDrawingBlocked) {
            return;
        }

        threeScene.onMouseUp(event);

        if (threeScene.points.length > 1 && !threeScene.isMultiselect) {
            saveEraserCanvas();
        }

        document.removeEventListener('mouseup', onMouseUpHandler);
        document.removeEventListener('touchend', onMouseUpHandler);
    };

    const preventDefaultEvent = (event: any): void => {
        event.preventDefault();
    };

    const saveEraserCanvas = async (): Promise<void> => {
        const savedCanvasInfo = threeScene.saveCanvasAsImage();

        if (!savedCanvasInfo) {
            return;
        }

        const eraserImage = await fetch(savedCanvasInfo).then((res) => res.blob());

        if (setEraserMask) {
            setEraserMask(eraserImage);
        }
    };

    useEffect(() => {
        if (!container?.current || !canvasElement?.current) {
            return;
        }
        const panoramicViewScene = new PanoramicView(
            canvasElement.current,
            container.current?.clientWidth,
            container.current?.clientHeight
        );
        setThreeScene(panoramicViewScene);
    }, []);

    useEffect(() => {
        if (threeScene) {
            showPanorama();
        }

        if (activeMode !== SceneMode.PAN && activeMode !== SceneMode.ERASE) {
            setIsCameraDisabled(true);

            if (threeScene) {
                threeScene.removeCanvasWithHelper();
            }
        } else {
            setIsCameraDisabled(false);
        }

        setEraserMask && setEraserMask(null);

        if (!threeScene) {
            return;
        }

        if (activeMode === SceneMode.PAN) {
            threeScene.isEraserDrawing = false;
            threeScene.removeCanvasWithHelper();
        }

        if (activeMode === SceneMode.ERASE) {
            threeScene.addCanvas();
            threeScene.isEraserDrawing = true;
        }
    }, [activeMode]);

    useEffect(() => {
        if (activeMode !== SceneMode.ERASE || !threeScene) {
            return;
        }

        threeScene.isEraserDrawing = !isSpaceDown;
    }, [isSpaceDown]);

    useEffect(() => {
        if (activeMode !== SceneMode.ERASE || !threeScene) {
            return;
        }

        threeScene.isMultiselect = isShiftDown;

        if (!isShiftDown && threeScene.points.length > 1 && !threeScene.isDrawing) {
            saveEraserCanvas();
        }
    }, [isShiftDown]);

    useEffect(() => {
        if (eraserSize && threeScene && activeImageUrl) {
            threeScene.eraserSize = eraserSize;
            threeScene.updatePinterRadius(eraserSize);
        }
    }, [eraserSize]);

    useEffect(() => {
        if (!threeScene || !activeImageUrl) {
            return;
        }

        showPanorama();

        if (activeMode === SceneMode.ERASE) {
            threeScene.addCanvas();
        }
    }, [activeImageUrl]);

    useEffect(() => {
        if (!container?.current) {
            return;
        }

        const instance = container.current;

        instance.addEventListener('contextmenu', preventDefaultEvent);
        return () => {
            instance.removeEventListener('contextmenu', preventDefaultEvent);
        };
    }, [container]);

    useEffect(() => {
        if (!threeScene) {
            return;
        }

        showPanorama();

        window.addEventListener('resize', onResize);
        window.addEventListener('keydown', (e) => {
            if (e.code === 'Space') {
                setIsSpaceDown(true);
            }

            if (e.key === 'Shift') {
                setIsShiftDown(true);
            }
        });
        window.addEventListener('keyup', (e) => {
            if (e.code === 'Space') {
                setIsSpaceDown(false);
            }
            if (e.key === 'Shift') {
                setIsShiftDown(false);
            }
        });

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('keydown', (e) => {
                if (e.code === 'Space') {
                    setIsSpaceDown(true);
                }
            });
            window.removeEventListener('keyup', (e) => {
                if (e.code === 'Space') {
                    setIsShiftDown(false);
                }
            });
            threeScene?.clearScene();
        };
    }, [threeScene]);

    return (
        <CanvasContainer
            mode={activeMode}
            ref={container}
        >
            <canvas
                ref={canvasElement}
                id="three-panorama"
                onMouseDown={handleCanvasInput}
                onTouchStart={handleCanvasInput}
                onMouseMove={onMouseMove}
                onTouchMove={onMouseMove}
            ></canvas>
        </CanvasContainer>
    );
};

export default React.memo(ScenePanorama);

const CanvasContainer = styled(Box)<{ mode: SceneMode }>(({ mode }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',

    overflow: 'hidden',
    height: '100%',
    width: '100%',

    '& canvas': {
        cursor: mode === SceneMode.PAN ? 'grab' : 'default',
        height: '100% !important',
        width: '100% !important',
        position: 'relative',
    },
    '& canvas: active': {
        cursor: mode === SceneMode.PAN ? 'grabbing' : 'default',
    },
}));
