import React from 'react';
import { SliderThumb, SvgIcon } from '@mui/material';

const LargeThumb = (props: any): React.JSX.Element => {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 6 38"
                fill="none"
                {...props}
            >
                <path
                    d="M3 2.5L3 35.5"
                    stroke="currentColor"
                    strokeWidth="5"
                    strokeLinecap="round"
                />
            </SvgIcon>
        </SliderThumb>
    );
};

export default LargeThumb;
