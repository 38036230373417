import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExpandSectionIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            {...props}
        >
            <path
                d="M10.5556 15.3344L17.7778 22.5566L25 15.3344"
                stroke="#87B4EA"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </SvgIcon>
    );
};
