export enum ModelStatusColor {
    Draft = 'white',
    Queued = 'rgb(237, 108, 2)',
    Training = 'rgb(237, 108, 2)',
    Trained = 'rgb(46, 125, 50)',
    Error = 'rgb(211, 47, 47)',
    QueuedForMerging = 'rgb(237, 108, 2)',
    Merging = 'rgb(237, 108, 2)',
    Merged = 'rgb(46, 125, 50)',
}
