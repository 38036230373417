import React from 'react';
import { Box, styled } from '@mui/material';
import FormCheckbox from '@root/components/FormCheckbox';
import InfluenceSlider from '@root/components/InfluenceSlider';
import SwitchField from '@root/components/SwitchField';
import ReferenceImageField from '@root/components/ReferenceImageField';
import { BrushGenerationSettingsProps } from './BrushGenerationSettings.types';
import PreciseModeField from '@root/components/PreciseModeField';

const BrushGenerationSettings = ({
    influenceImage,
    influence,
    setInfluence,
    setInfluenceImage,
    sketchInfluence,
    setSketchInfluence,
    onConvertToSketchChecked,
    isConvertToSketchChecked,
    enablePreciseMode,
    setEnablePreciseMode,
    isFullRerender,
    setIsFullRerender,
    isPartialRerenderEnabled,
    isSketch = false,
}: BrushGenerationSettingsProps): React.JSX.Element => {
    const onSwitchChange = async (checked: boolean): Promise<void> => {
        if (checked) {
            await onConvertToSketchChecked();
        }
    };

    const handleChange = (isChecked: boolean): void => {
        setIsFullRerender(isChecked);
    };

    return (
        <SketchFieldWrapper>
            <Box>
                {!isSketch && (
                    <SwitchField
                        checked={isConvertToSketchChecked}
                        disabled={isConvertToSketchChecked}
                        title={'convert to sketch'}
                        OnChange={onSwitchChange}
                    />
                )}
                <InfluenceSlider
                    id={'sketch influence'.replace(/\s/g, '')}
                    value={sketchInfluence}
                    OnChange={setSketchInfluence}
                    label={'sketch influence'}
                    step={1}
                    range={[0, 100]}
                    endAdornment={'%'}
                ></InfluenceSlider>
            </Box>
            <SectionWrapper>
                <ReferenceImageField
                    setInfluenceImage={setInfluenceImage}
                    influenceImage={influenceImage}
                    influence={influence}
                    setInfluence={setInfluence}
                ></ReferenceImageField>
                <PreciseModeField
                    enablePreciseMode={enablePreciseMode}
                    setEnablePreciseMode={setEnablePreciseMode}
                ></PreciseModeField>
            </SectionWrapper>
            <FormCheckbox
                id={'generationType'}
                label={'regenerate the whole image'}
                checked={isPartialRerenderEnabled ? isFullRerender : true}
                disabled={!isPartialRerenderEnabled}
                onChange={handleChange}
            />
        </SketchFieldWrapper>
    );
};

const SketchFieldWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
}));

const SectionWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}));

export default BrushGenerationSettings;
