import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material';
import { ToggleFieldProps } from './ToggleField.types';

const ToggleField = ({
    selectedOption,
    setSelectedOption,
    optionsList,
    elementsList,
    isExclusive = true,
    isRequired = true,
    withLabels = true,
    disabled = false,
}: ToggleFieldProps): React.JSX.Element => {
    const handleChange = (_: React.MouseEvent<HTMLElement>, option: string): void => {
        if (!option && isRequired) {
            return;
        }

        setSelectedOption(option);
    };

    return (
        <Container>
            <ToggleGroup
                fullWidth
                exclusive={isExclusive}
                disabled={disabled}
                value={selectedOption}
                onChange={handleChange}
            >
                {optionsList.map((item: string, index: number) => (
                    <ToggleOption
                        secondary={!withLabels}
                        disableRipple
                        value={item}
                        key={index}
                    >
                        {withLabels && <ToggleLabel>{item}</ToggleLabel>}
                        {elementsList?.length === optionsList.length && <>{elementsList[index]}</>}
                    </ToggleOption>
                ))}
            </ToggleGroup>
        </Container>
    );
};

export default ToggleField;

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',

    backgroundColor: theme.palette.primary.light,
    borderRadius: '6px',
}));

const ToggleGroup = styled(ToggleButtonGroup)(() => ({
    width: '100%',
    padding: '2px 2px',
}));

const ToggleOption = styled(ToggleButton)<{ secondary: boolean }>(({ theme, secondary }) => ({
    height: secondary ? '24px' : '36px',
    padding: '0px',
    textTransform: 'none',

    borderRadius: '6px',
    border: 'none',

    '&:hover': {
        backgroundColor: 'transparent',
    },

    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const ToggleLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '14px',
    color: theme.palette.neutrals.contrastText,
}));
