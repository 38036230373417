import React from 'react';
import { Box, Tooltip, styled } from '@mui/material';
import { IconButton } from './IconButton';
import { UpscaleIcon } from '@root/assets/icons/UpscaleIcon';
import { useUpscaleTool } from '@root/context/UpscaleToolContext/useUpscaleTool';
import { useCurate } from '@root/hooks/curate/useCurate';
import { CurateTools } from '@root/utils/constants/enums';
import { CommonConstants } from '@root/utils/constants';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

interface UpscaleToolButtonProps {
    disabled: boolean;
}

const UpscaleToolButton = ({ disabled }: UpscaleToolButtonProps): React.JSX.Element => {
    const { upscaleOptionsList } = useUpscaleTool();

    const { generateUpscaleLayer, toggleTool, activeTool } = useCurate();

    const { checkLicenseStatus } = useLicenseValidation();

    const submitUpscale = async (scaleFactor: number): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        generateUpscaleLayer(scaleFactor);
    };

    return (
        <UpscaleContainer>
            <Tooltip title="Upscale">
                <span>
                    <IconButton
                        value={CurateTools.Upscale}
                        active={activeTool === CurateTools.Upscale ? 1 : 0}
                        disabled={disabled}
                        onClick={toggleTool}
                    >
                        <UpscaleIcon />
                    </IconButton>
                </span>
            </Tooltip>
            {activeTool === CurateTools.Upscale && (
                <Tooltip
                    placement="right"
                    title={
                        upscaleOptionsList?.length && !upscaleOptionsList.find((item) => item.enabled)
                            ? CommonConstants.UPSCALE_WARNING_MESSAGE
                            : ''
                    }
                >
                    <UpscaleList>
                        {upscaleOptionsList?.map((item, i) => {
                            return (
                                <UpscaleOption
                                    key={i}
                                    onClick={() => submitUpscale(item.scaleFactor)}
                                    disabled={!item.enabled}
                                >
                                    {item.label}
                                </UpscaleOption>
                            );
                        })}
                    </UpscaleList>
                </Tooltip>
            )}
        </UpscaleContainer>
    );
};

export default UpscaleToolButton;

const UpscaleContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
}));

const UpscaleList = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    top: '40px',
    left: 'calc(50% - 50px)',

    width: '94px',
    height: '98px',
    background: theme.palette.neutrals.main,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '10px',
    zIndex: 20,
}));

const UpscaleOption = styled(Box)<{ disabled: boolean }>(({ theme, disabled }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    alignItems: 'center',
    cursor: disabled ? 'default' : 'pointer',

    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '16px',
    color: disabled ? theme.palette.primary.inactive : theme.palette.neutrals.contrastText,
    pointerEvents: disabled ? 'none' : 'auto',

    ...(!disabled && {
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    }),

    height: '32px',

    ':last-child': {
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
    },

    ':first-of-type': {
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
    },
}));
