import { CurateTools } from './enums';

type CurateToolName = {
    [key in CurateTools]: string;
};

export const CurateToolsNames: CurateToolName = {
    [CurateTools.MixImages]: 'Mix',
    [CurateTools.Brush]: 'Brush',
    [CurateTools.Lasso]: 'Lasso',
    [CurateTools.BackgroundReplacement]: 'BG Change',
    [CurateTools.Sketch]: 'Sketch',
    [CurateTools.StyleDrive]: 'StyleDrive',
    [CurateTools.Eraser]: 'Eraser',
    [CurateTools.Upscale]: 'Upscale',
    [CurateTools.SceneShift]: 'SceneShift',
    [CurateTools.TextToImage]: 'TextToImage',
    [CurateTools.SceneMode]: '3D Mode',
};

export const CurateToolsFullNames: CurateToolName = {
    [CurateTools.MixImages]: 'mix images',
    [CurateTools.Brush]: 'brush',
    [CurateTools.Lasso]: 'lasso',
    [CurateTools.BackgroundReplacement]: 'background replacement',
    [CurateTools.Sketch]: 'sketch',
    [CurateTools.StyleDrive]: 'style drive',
    [CurateTools.Eraser]: 'eraser',
    [CurateTools.Upscale]: 'upscale',
    [CurateTools.SceneShift]: 'scene shift',
    [CurateTools.TextToImage]: 'text to image',
    [CurateTools.SceneMode]: '3d mode',
};
