import React from 'react';

const BorderRadiusFilter = () => {
    return (
        <svg
            width="0"
            height="0"
        >
            <defs>
                <filter id="BorderRadiusFilter">
                    <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation="8"
                        result="turbulence"
                    />
                    <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 100 -50"
                        result="mask"
                    />
                    <feComposite
                        in="SourceGraphic"
                        in2="mask"
                        operator="atop"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default BorderRadiusFilter;
