import React, { CSSProperties, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { SortableListRowProps } from './SortableListRow.types';

interface SortableListRowContextItems {
    attributes: Record<string, any>;
    listeners: DraggableSyntheticListeners;
    ref(node: HTMLElement | null): void;
}

export const SortableListRowContext = createContext<SortableListRowContextItems | null>(null);

const SortableListRow = ({ children, id }: PropsWithChildren<SortableListRowProps>): React.JSX.Element => {
    const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ id });

    const rowContext = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef,
        }),
        [attributes, listeners, setActivatorNodeRef]
    );

    const rowStyle: CSSProperties = {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <SortableListRowContext.Provider value={rowContext}>
            <div
                ref={setNodeRef}
                style={rowStyle}
            >
                {children}
            </div>
        </SortableListRowContext.Provider>
    );
};

export default SortableListRow;

export const useSortableListRow = (): SortableListRowContextItems => {
    const context = useContext(SortableListRowContext);

    if (context === null) {
        throw new Error('useSortableListRow cannot be used without its provider');
    }

    return context;
};
