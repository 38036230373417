import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PrevIcon = (props: SvgIconProps) => (
    <SvgIcon
        viewBox="0 0 32 32"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.7929 17.5858L5 11.7929L10.7929 6"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={'none'}
        />
        <path
            d="M10.7929 25.3098H21.4133C23.2057 25.3098 24.9248 24.5977 26.1922 23.3303C27.4596 22.0629 28.1717 20.3438 28.1717 18.5514V18.5514C28.1717 16.7589 27.4596 15.0399 26.1922 13.7725C24.9248 12.505 23.2057 11.793 21.4133 11.793H5"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={'none'}
        />
    </SvgIcon>
);
