import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SignOutIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 3.75C4.30964 3.75 3.75 4.30964 3.75 5V13C3.75 13.6904 4.30964 14.25 5 14.25H8C8.69036 14.25 9.25 13.6904 9.25 13C9.25 12.5858 9.58579 12.25 10 12.25C10.4142 12.25 10.75 12.5858 10.75 13C10.75 14.5188 9.51878 15.75 8 15.75H5C3.48122 15.75 2.25 14.5188 2.25 13V5C2.25 3.48122 3.48122 2.25 5 2.25H8C9.51878 2.25 10.75 3.48122 10.75 5V5.5C10.75 5.91421 10.4142 6.25 10 6.25C9.58579 6.25 9.25 5.91421 9.25 5.5V5C9.25 4.30964 8.69036 3.75 8 3.75H5Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.25 9C6.25 8.58579 6.58579 8.25 7 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H7C6.58579 9.75 6.25 9.41421 6.25 9Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9697 4.96967C12.2626 4.67678 12.7374 4.67678 13.0303 4.96967L17.0607 9L13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303C11.6768 12.7374 11.6768 12.2626 11.9697 11.9697L14.9393 9L11.9697 6.03033C11.6768 5.73744 11.6768 5.26256 11.9697 4.96967Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};
