export const getDate = (DateCreated) => {
    const timestamp = Date.parse(DateCreated);

    const date = new Date();
    date.setTime(timestamp);

    const getDay = date.getDate();
    const getMonth = date.getMonth() + 1;
    const year = date.getFullYear();

    const day = getDay > 9 ? getDay : `0${getDay}`;

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const month = months[getMonth - 1];

    return `${day} ${month} ${year}`;
};
