import React, { useState } from 'react';
import { Box, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';

import LensSlider from './LensSlider';
import RollSlider from './RollSlider';

const SceneModeFooter = (): React.JSX.Element => {
    enum ButtonsType {
        lens = 'lens',
        roll = 'roll',
    }

    const [selectedButton, setSelectedButton] = useState<string>(ButtonsType.lens);

    const handleButtons = (event: React.MouseEvent<HTMLElement>, buttonValue: string) => {
        setSelectedButton(buttonValue);
    };

    return (
        <Container>
            {selectedButton === ButtonsType.lens ? <LensSlider /> : <RollSlider />}
            <ButtonsContainer>
                <ToggleButtonGroup
                    exclusive
                    value={selectedButton}
                    fullWidth
                    onChange={handleButtons}
                >
                    <StyledButton value={ButtonsType.lens}>lens</StyledButton>
                    <StyledButton value={ButtonsType.roll}>roll</StyledButton>
                </ToggleButtonGroup>
            </ButtonsContainer>
        </Container>
    );
};

export default SceneModeFooter;

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    height: '226px',
}));

const ButtonsContainer = styled(Box)(() => ({
    display: 'flex',
    marginTop: 'auto',
    marginBottom: '20px',
}));

const StyledButton = styled(ToggleButton)(() => ({
    border: 'none',
    width: '125px',
}));
