import React from 'react';
import { Box, Button, styled } from '@mui/material';
import Search from '../../../../components/SearchField/Search';
import { useModal } from '../../../../context/ModalContext/useModal';

const ProjectListPageHeader = ({ value, setValue }) => {
    const { handleOpenModal } = useModal();

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Search
                value={value}
                setValue={setValue}
            />
            <Button
                variant="contained"
                onClick={handleOpenModal}
            >
                New project
            </Button>
        </Box>
    );
};

export default ProjectListPageHeader;
