import React, { useContext, createContext, ReactNode } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import { RequestErrorCode } from '@root/utils/constants/enums';
import { SupportedRequestError } from '@root/types/commonTypes';

type HandlerFunction = (response: AxiosResponse | AxiosError) => void;

export interface ErrorHandler {
    errorCode: RequestErrorCode | null;
    handler: HandlerFunction;
}

interface RequestConfigs extends AxiosRequestConfig {
    errorHandlers?: ErrorHandler[];
}

type Request = (url: string, configs?: RequestConfigs) => Promise<AxiosResponse | null>;

interface RequestContextItems {
    requestGet: Request;
    requestGetWithJson: Request;
    requestPost: Request;
    requestPostWithJson: Request;
    requestPostWithForm: Request;
    requestDelete: Request;
    requestPut: Request;
    requestPutWithJson: Request;
    requestPutWithForm: Request;
}

const RequestContext = createContext<RequestContextItems | null>(null);

interface RequestProviderProps {
    children: ReactNode;
}

export const RequestProvider = ({ children }: RequestProviderProps) => {
    const { createNotification, notificationMessages } = useNotifications();

    const defaultErrorHandling: HandlerFunction = (error: AxiosResponse | AxiosError): void => {
        createNotification(notificationMessages.request.error.title, notificationMessages.request.error.error || '');

        // TODO: instrument error
        console.error({
            status: error.status,
            data: error instanceof AxiosError ? error.message : error.data,
        });
    };

    const handleError = (error: AxiosResponse | AxiosError, handlers: ErrorHandler[] = []): { data: SupportedRequestError } => {
        const defaultHandler = handlers.find((handler) => handler.errorCode == null)?.handler ?? defaultErrorHandling;

        let handler: HandlerFunction = defaultHandler;
        if (!(error instanceof AxiosError)) {
            const errorCode = error.data?.errorCode;

            const matchedHandler = handlers.find((handler) => handler.errorCode == errorCode)?.handler;
            if (matchedHandler) {
                handler = matchedHandler;
            }
        }

        handler(error);

        if ((error as AxiosResponse)?.data?.errorCode in RequestErrorCode) {
            return {
                data: {
                    errorCode: (error as AxiosResponse).data.errorCode,
                    message: (error as AxiosResponse).data.message,
                },
            };
        }

        return {
            data: {
                errorCode: RequestErrorCode.UNKNOWN_ERROR,
                message: error instanceof AxiosError ? error?.message : error?.data?.message || RequestErrorCode.UNKNOWN_ERROR,
            },
        };
    };

    const request = (url: string, method: Method, configs?: RequestConfigs): Promise<AxiosResponse | any> => {
        return axios({
            url,
            method,
            ...configs,
        })
            .then((response) => {
                if (response.status >= 400) {
                    return handleError(response, configs?.errorHandlers);
                }
                return response;
            })
            .catch((error: AxiosError) => {
                return handleError(error, configs?.errorHandlers);
            });
    };

    const requestGet = (url: string, configs: RequestConfigs = {}) => {
        return request(url, 'get', configs);
    };

    const requestGetWithJson = (url: string, requestConfigs: RequestConfigs = {}) => {
        const configs: RequestConfigs = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'application/json',
            },
        };
        return request(url, 'get', configs);
    };

    const requestPost = (url: string, configs: RequestConfigs = {}) => {
        return request(url, 'post', configs);
    };

    const requestPostWithJson = (url: string, requestConfigs: RequestConfigs = {}) => {
        const configs: RequestConfigs = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'application/json',
            },
        };
        return request(url, 'post', configs);
    };

    const requestPostWithForm = (url: string, requestConfigs: RequestConfigs = {}) => {
        const configs: RequestConfigs = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'multipart/form-data',
            },
        };

        return request(url, 'post', configs);
    };

    const requestDelete = (url: string, configs: RequestConfigs = {}) => {
        return request(url, 'delete', configs);
    };

    const requestPut = (url: string, configs: RequestConfigs = {}) => {
        return request(url, 'put', configs);
    };

    const requestPutWithJson = (url: string, requestConfigs: RequestConfigs = {}) => {
        const configs: RequestConfigs = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'application/json',
            },
        };
        return request(url, 'put', configs);
    };

    const requestPutWithForm = (url: string, requestConfigs: RequestConfigs = {}) => {
        const configs: RequestConfigs = {
            ...requestConfigs,
            headers: {
                ...requestConfigs.headers,
                'Content-Type': 'multipart/form-data',
            },
        };

        return request(url, 'put', configs);
    };

    const value = {
        requestGet,
        requestGetWithJson,
        requestPost,
        requestPostWithJson,
        requestPostWithForm,
        requestDelete,
        requestPut,
        requestPutWithJson,
        requestPutWithForm,
    };

    return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
};

export const useRequest = () => {
    const context = useContext(RequestContext);
    if (!context) throw new Error('Can not use useRequest out of RequestContext. 😃');
    return context;
};
