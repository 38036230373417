import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, styled } from '@mui/material';

import SecondaryNavButtonListRow from './SecondaryNavButtonListRow';
import ViewAllButton from './ViewAllButton';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../context/ModalContext/useModal';
import { NavigationPaths } from '@root/utils/constants/enums';
import { ProjectConstants } from '@root/utils/constants';

const SecondaryNavWindow = ({ isProjects, info, navigationBase, fullPage }) => {
    const [list, setList] = useState(info);

    const { handleOpenModal } = useModal();
    const navigate = useNavigate();

    const moveToTrain = () => {
        navigate(`${NavigationPaths.Models}`);
    };

    useEffect(() => {
        setList(info);
    }, [info]);

    return (
        <Wrapper>
            <ButtonWrapper>
                <AddButton
                    className="opacity"
                    onClick={isProjects ? handleOpenModal : moveToTrain}
                    variant="contained"
                >
                    +
                </AddButton>
            </ButtonWrapper>
            <Content className="opacity">
                {isProjects &&
                    list
                        .concat([])
                        .reverse()
                        .map((el) => {
                            if (el.Name === ProjectConstants.DEFAULT_PROJECT_NAME) {
                                return (
                                    <SecondaryNavButtonListRow
                                        key={el.Id}
                                        el={el}
                                        navigationBase={navigationBase}
                                    />
                                );
                            }
                        })}
                {list.map((el) => {
                    if (!isProjects || el.Name !== ProjectConstants.DEFAULT_PROJECT_NAME) {
                        return (
                            <SecondaryNavButtonListRow
                                key={el.Id}
                                el={el}
                                navigationBase={navigationBase}
                            />
                        );
                    }
                })}
            </Content>
            <ViewAllButton fullPage={fullPage} />
        </Wrapper>
    );
};

export default SecondaryNavWindow;

const Wrapper = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    width: '211px',
    margin: '2px 6px 2px 7px',
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.6),
}));

const AddButton = styled(Button)(() => ({
    padding: 0,
    width: '30px ',
    minWidth: '30px',
    height: '28px',
}));

const Content = styled(Stack)(({ theme }) => ({
    width: '100%',
    maxHeight: '150px',
    overflow: 'auto',
    paddingRight: '10px',

    '& div:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.primary.light1}`,
    },

    '& div:hover': {
        color: theme.palette.primary.main,
    },
}));
