import React, { useState } from 'react';
import { Box, Tab, Tabs, styled } from '@mui/material';
import UsersList from './components/UsersList';
import LicenseInfoTable from './components/LicenseInfoTable';

const enum AdminPageTabs {
    Users = 1,
    License = 2,
}

const AdminPage = (): React.JSX.Element => {
    const [activeTab, setActiveTab] = useState<number>(AdminPageTabs.Users);

    const handleChange = (_: React.SyntheticEvent, value: number): void => {
        setActiveTab(value);
    };

    return (
        <PageContainer>
            <PageTabs
                value={activeTab}
                onChange={handleChange}
                centered
            >
                <PageTab
                    label="Users"
                    value={AdminPageTabs.Users}
                />
                <PageTab
                    label="License"
                    value={AdminPageTabs.License}
                />
            </PageTabs>

            <ContentContainer>
                {activeTab === AdminPageTabs.Users && <UsersList />}
                {activeTab === AdminPageTabs.License && <LicenseInfoTable />}
            </ContentContainer>
        </PageContainer>
    );
};

export default AdminPage;

const PageContainer = styled(Box)(() => ({
    marginTop: '30px',
    height: 'calc(100% - 90px)',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
}));

const PageTabs = styled(Tabs)(() => ({
    minHeight: '35px',
}));

const PageTab = styled(Tab)(({ theme }) => ({
    minHeight: '35px',
    padding: 0,
    borderRadius: '6px 6px 0px 0px',
    border: `1px solid ${theme.palette.primary.light1}`,
    borderBottom: 'none',
    textTransform: 'none',
    ':last-child': {
        marginLeft: '10px',
    },
}));

const ContentContainer = styled(Box)(() => ({
    height: '100%',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    overflowX: 'auto',
}));
