import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';
import { useQuickAction } from '@context/QuickActionContext/useQuickAction';
import { QuickActionProps } from './QuickAction.types';
import { QuickActionTypes } from '@root/utils/constants/enums';
import { useJobs } from '@root/hooks/jobs/UseJobs';
import QuickActionButton from './components';

const QuickAction = ({ label, actionWindow, disabled = false, className, actionType }: QuickActionProps): React.JSX.Element => {
    const { activeWindow, setActiveWindow } = useQuickAction();
    const { clearJobs } = useJobs();

    const quickActionContainerRef = useRef<HTMLDivElement>(null);

    const [isActive, setIsActive] = useState<boolean>(false);
    const [currentActiveWindow, setCurrentActiveWindow] = useState<RefObject<HTMLDivElement> | null>(null);

    const joinedClassName = clsx(isActive ? 'active' : '', className);

    const buttonAction = (): void => {
        if (!disabled) {
            setIsActive(!isActive);
        }
    };

    const onClose = async (): Promise<void> => {
        setCurrentActiveWindow(null);
        if (actionType === QuickActionTypes.JobsStatuses) {
            clearJobs();
        }
    };

    const handleClickOutside = (event: MouseEvent): void => {
        if (currentActiveWindow?.current && !currentActiveWindow?.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        if (isActive) {
            setCurrentActiveWindow(quickActionContainerRef);
        } else if (!isActive && currentActiveWindow) {
            onClose();
        }
    }, [isActive]);

    useEffect(() => {
        if (!activeWindow && currentActiveWindow) {
            setCurrentActiveWindow(null);
        }
    }, [activeWindow]);

    useEffect(() => {
        if (currentActiveWindow !== quickActionContainerRef) {
            setIsActive(false);
        }

        setActiveWindow(currentActiveWindow);
    }, [currentActiveWindow]);

    useEffect(() => {
        if (disabled && currentActiveWindow === quickActionContainerRef) {
            setIsActive(false);
            setCurrentActiveWindow(null);
        }
    }, [disabled]);

    useEffect(() => {
        currentActiveWindow
            ? document.addEventListener('mousedown', handleClickOutside)
            : document.removeEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [currentActiveWindow]);

    return (
        <Container
            ref={quickActionContainerRef}
            className={joinedClassName}
        >
            <QuickActionButton
                buttonAction={buttonAction}
                label={label}
                disabled={disabled}
            />
            <OpenedWindow>{actionWindow}</OpenedWindow>
        </Container>
    );
};

export default QuickAction;

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',

    '&.active  p': {
        color: theme.palette.primary.main,
    },

    '&.active>div': {
        width: 'auto',
        maxHeight: 'calc(100vh - 420px)',
        minHeight: '130px',

        borderBottomLeftRadius: '15px',
        padding: '18px 11px 18px 21px',
        border: `1px solid ${theme.palette.primary.light1}`,
    },

    transition: 'all 1.5s',
    marginBottom: '14px',
}));

const OpenedWindow = styled(Box)(({ theme }) => ({
    width: '0px',
    maxHeight: '116px',
    position: 'relative',
    zIndex: '1',

    overflow: 'hidden',
    background: theme.palette.neutrals.main,
    boxShadow: '-1px 6px 31px 0px rgba(0, 0, 0, 0.25)',
    transition: 'all 0.5s',
}));
