import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useStatistics } from '@hooks/statistics/UseStatistics';
import routesConfig from '@routes/routesConfig';
import type { Route } from '@routes/routesConfig';
import { useTabActive } from '@root/hooks/helpers/useTabActive';

function getPageNameFromPath(path: string): string | null {
    const findPageName = (routes: Route[], currentPath: string): string | null => {
        for (const route of routes) {
            const routePath = route.path ? `/${route.path}` : '';
            const fullPath = `${currentPath}${routePath}`;

            if (route.child) {
                const childPageName = findPageName(route.child, fullPath);
                if (childPageName) {
                    return childPageName;
                }
            }

            const regexPattern = fullPath.replace(/:[^/]+/g, '[^/]+').replace(/\//g, '\\/');

            const regex = new RegExp(`^${regexPattern}$`);

            if (regex.test(path)) {
                return route.pageName || null;
            }
        }
        return null;
    };

    return findPageName(routesConfig, '');
}

const RouteChangeListener: React.FC = () => {
    const location = useLocation();
    const { pageViewed, timeSpentOnPage } = useStatistics();
    const { isTabActive } = useTabActive();
    const [startTime, setStartTime] = useState(new Date().getTime());
    const [currentPage, setCurrentPage] = useState('');

    const getTimeDifference = (): number => {
        const endTime = new Date().getTime();
        return (endTime - startTime) / 1000;
    };

    useEffect(() => {
        const pageName = getPageNameFromPath(location.pathname);

        if (pageName) {
            const page = currentPage === '' ? pageName : currentPage;

            pageViewed(pageName);
            timeSpentOnPage(page, getTimeDifference());

            setStartTime(new Date().getTime());
            setCurrentPage(pageName);
        }
    }, [location]);

    useEffect(() => {
        if (isTabActive && currentPage) {
            pageViewed(currentPage);
            setStartTime(new Date().getTime());
        } else if (!isTabActive && currentPage) {
            timeSpentOnPage(currentPage, getTimeDifference());
        }
    }, [isTabActive]);

    return null;
};

export default RouteChangeListener;
