export enum SupportedModelTypes {
    OBJ = 1,
    FBX,
    GLTF,
    GLB,
    STL,
}

export const SupportedModelTypesInfo: { [key in SupportedModelTypes]: { name: string; extension: string } } = {
    [SupportedModelTypes.OBJ]: {
        name: 'obj',
        extension: '.obj',
    },
    [SupportedModelTypes.FBX]: {
        name: 'fbx',
        extension: '.fbx',
    },
    [SupportedModelTypes.GLTF]: {
        name: 'gltf',
        extension: '.gltf',
    },
    [SupportedModelTypes.GLB]: {
        name: 'glb',
        extension: '.glb',
    },
    [SupportedModelTypes.STL]: {
        name: 'stl',
        extension: '.stl',
    },
};
