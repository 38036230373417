export enum ImportWithOptStatus {
    Success = 1,
    Expired,
    NotFound,
}

type ImportWithOptInfo = {
    [key in ImportWithOptStatus]: string;
};

export const ImportWithOptStatusMessages: ImportWithOptInfo = {
    [ImportWithOptStatus.Success]: 'the model will be available in a few minutes',
    [ImportWithOptStatus.Expired]: 'this key has been expired',
    [ImportWithOptStatus.NotFound]: 'this key has not been found',
};
